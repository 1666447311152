import * as type from 'Store/types'
import {
  ActionType,
  ConsentsStateType,
  ConsentType,
  ErrorType,
  UnsubscribeResponse,
} from 'Interfaces'

const initialState = {
  data: {
    consents: [],
  },
  newsletterSubscription: null,
  loading: false,
  error: undefined,
}

const ConsentsReducer = (
  state: ConsentsStateType = initialState,
  action: ActionType<ErrorType | ConsentType[] | UnsubscribeResponse>
): ConsentsStateType => {
  switch (action.type) {
    case type.consents.getConsents.requested:
    case type.consents.changeConsentStatus.requested:
    case type.consents.unsubscribeNewsletter.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.consents.changeConsentStatus.succeeded:
    case type.consents.getConsents.succeeded:
      return {
        ...state,
        data: {...state.data, consents: action.payload as ConsentType[]},
        loading: false,
        error: undefined,
      }
    case type.consents.unsubscribeNewsletter.succeeded:
      return {
        ...state,
        loading: false,
        newsletterSubscription: action.payload as UnsubscribeResponse,
      }
    case type.consents.getConsents.failed:
    case type.consents.changeConsentStatus.failed:
    case type.consents.unsubscribeNewsletter.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default ConsentsReducer
