import React, {useEffect, useLayoutEffect, useState} from 'react'
import {DidomiSDK} from '@didomi/react'
import TagManager from 'react-gtm-module'
import {useHistory} from 'react-router-dom'

import {TimeoutModal} from 'Components/Modal'
import {AuthStateType, OpenedConversationType} from 'Interfaces'
import {getChannel} from 'Utils/CommonHelpers'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {Helmet} from 'react-helmet-async'

interface RootLayoutProps {
  auth: AuthStateType
  pingAuth: () => void
  children?: JSX.Element | JSX.Element[]
  openedConversation?: OpenedConversationType
}

export const RootLayout: React.FC<RootLayoutProps> = ({
  children,
  auth,
  pingAuth,
  openedConversation,
}) => {
  const channel = getChannel()
  const didomiApiKey = channel?.didomiApiKey
  const gtmId = channel?.gtmId
  const sessionTimeout = channel.sessionTimeout
  const [modalOpen, setModalOpen] = useState(false)
  const sessionWarnTime = 60000
  const history = useHistory()

  useLayoutEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({gtmId})
    }
  }, [])

  useEffect(() => {
    let loginInterval: NodeJS.Timeout
    if (auth?.data?.id) {
      loginInterval = setInterval(() => {
        const sessionTimestamp = localStorage.getItem('sessionTimestamp')
        const timeNow = new Date().getTime()
        const timeLeft = sessionTimestamp
          ? parseInt(sessionTimestamp) + sessionTimeout - timeNow
          : 0

        if (timeLeft < 1) {
          history.push(getRoute(AppRoute.Logout))
        } else if (
          timeLeft < (sessionTimeout * 3) / 4 &&
          openedConversation?.messages.length
        ) {
          const lastMessage = openedConversation?.messages[0]
          const lastMessageSentTime = new Date(lastMessage.dateSent).getTime()

          if (lastMessageSentTime > parseInt(sessionTimestamp || '0')) {
            pingAuth()
          }
        } else if (timeLeft < sessionWarnTime) {
          setModalOpen(true)
        }
      }, 58000)
    }

    return () => {
      if (loginInterval) clearInterval(loginInterval)
    }
  }, [auth, openedConversation])

  return (
    <>
      <main>
        {!window.grecaptcha && (
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${
                getChannel().googleRecaptchaKey
              }&onload=onloadCallback&render=explicit`}
              async
            />
          </Helmet>
        )}
        {didomiApiKey && (
          <DidomiSDK
            iabVersion={2}
            apiKey={didomiApiKey}
            gdprAppliesGlobally={true}
            onReady={(didomi: any) =>
              console.log('Didomi SDK is loaded and ready', didomi)
            }
            onConsentChanged={(cwtToken: any) =>
              console.log('A consent has been given/withdrawn', cwtToken)
            }
            onNoticeShown={() => console.log('Didomi Notice Shown')}
            onNoticeHidden={() => console.log('Didomi Notice Hidden')}
          />
        )}
        {modalOpen && (
          <TimeoutModal
            open={modalOpen}
            setOpen={setModalOpen}
            authPing={pingAuth}
          />
        )}
        <>{children}</>
      </main>
    </>
  )
}
