import {all, put, takeLatest, takeEvery, call, select} from 'redux-saga/effects'
import * as type from '../types'
import {
  SendRequestContactType,
  TestingInvitePayloadType,
  SearchControlFormikValuesType,
  BaseTaxonomyType,
  TaxonomyWithCategoryType,
  SavedSearchType,
  CandidateSearchFiltersType,
  SeekerEvaluationType,
} from 'Interfaces'
import {ActionType} from 'Interfaces'
import API from 'Services/API'
import {packSearchAPIQuery} from 'Store/Packing'
import {RootState} from '../Reducers'
import qs from 'qs'
import {getTranslation} from 'Services/I18n/Utils'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import {getChannel} from 'Utils/CommonHelpers'

const channel = getChannel()
const defaultPageSize = channel?.candidateSearchPageSize || 100

function* searchCandidates(action: ActionType<SearchControlFormikValuesType>) {
  const filtersType: CandidateSearchFiltersType = yield select(
    (state: RootState) => state.searchCandidates.filtersType
  )

  const searchParams = packSearchAPIQuery(
    action.payload as SearchControlFormikValuesType,
    filtersType
  )
  const {response, error} = yield call(
    API.get,
    `/employer/search-and-match${filtersType === 'admin' ? '-rjb' : ''}${
      searchParams ? `?${searchParams}&` : '?'
    }pageSize=${defaultPageSize}&startFrom=0`
  )

  if (response) {
    yield put({
      type: type.searchCandidates.search.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
        loadMore: `${
          searchParams ? `?${searchParams}&` : '?'
        }pageSize=${defaultPageSize}&startFrom=${defaultPageSize}`,
        facets: response.data.facets,
      },
    })
  } else {
    yield put({type: type.searchCandidates.search.failed, payload: error})
  }
}

function* loadMoreCandidates() {
  const filtersType: CandidateSearchFiltersType = yield select(
    (state: RootState) => state.searchCandidates.filtersType
  )
  const searchParams: string = yield select(
    (state: RootState) => state.searchCandidates.searchResults.loadMore
  )
  const loadMoreUrl = `/employer/search-and-match${
    filtersType === 'admin' ? '-rjb' : ''
  }${searchParams}`
  const {response, error} = yield call(API.get, loadMoreUrl)

  if (response) {
    const {startFrom} = qs.parse(loadMoreUrl)

    const newUrl = `${
      searchParams.split('pageSize')[0]
    }pageSize=${defaultPageSize}&startFrom=${
      defaultPageSize + parseInt(startFrom as string)
    }`
    yield put({
      type: type.searchCandidates.loadMore.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
        loadMore:
          defaultPageSize > response.data.items.length ? undefined : newUrl,
        facets: response.data.facets,
      },
    })
  } else {
    yield put({type: type.searchCandidates.loadMore.failed, payload: error})
  }
}

function* getSearchKeywords() {
  const {response, error} = yield call(API.get, '/taxonomy/search-keyword')

  if (response) {
    yield put({
      type: type.searchKeywords.succeeded,
      payload: [
        ...response.data.employers.map((e: BaseTaxonomyType) => ({
          ...e,
          category: getTranslation('Employer'),
        })),
        ...response.data.occupations.map((o: TaxonomyWithCategoryType) => ({
          ...o,
          category: getTranslation('Occupation'),
        })),
        ...response.data.skills.map((s: TaxonomyWithCategoryType) => ({
          ...s,
          category: getTranslation('Skill'),
        })),
      ],
    })
  } else {
    yield put({type: type.searchKeywords.failed, payload: error})
  }
}

function* inviteToTestingBulk(action: ActionType<TestingInvitePayloadType>) {
  // const {response, error} = yield call(API.get, '/invite-to-testing-bulk',action.payload.ids, action.payload.type)
  const error = 'Failed to fetch'
  const response =
    action.payload && action.payload.ids.length > 1
      ? 'Invitations sent'
      : 'Invitation sent'

  // TODO: Display message after successful submit

  if (response) {
    yield put({
      type: type.searchCandidates.inviteToTestingBulk.succeeded,
      payload: response,
    })
  } else {
    yield put({
      type: type.searchCandidates.inviteToTestingBulk.failed,
      payload: error,
    })
  }
}

function* sendContactRequestsBulk(action: ActionType<string[]>) {
  const {response, error} = yield call(
    API.post,
    '/company-seeker-journey/company-user/contact-request/batch',
    {ids: action.payload}
  )
  if (response) {
    yield put({
      type: type.searchCandidates.sendContactRequestsBulk.succeeded,
      payload: response.data.items,
    })
    yield put({
      type: type.app.snackbar.show,
      payload: {
        snackbars: [
          {message: getTranslation(pt.contactSentCandidate), duration: 1500},
        ],
      },
    })
  } else {
    yield put({
      type: type.searchCandidates.sendContactRequestsBulk.failed,
      payload: error,
    })
  }
}
function* requestContact(action: ActionType<SendRequestContactType>) {
  const {response, error} = yield call(
    API.post,
    '/company-seeker-journey/company-user/contact-request',
    {seekerUserId: action.payload?.id}
  )

  if (response) {
    yield put({
      type: type.candidateSearch.requestContact.save.succeeded,
      payload: {
        id: action.payload?.id,
        contactRequest: response.data,
      },
    })
    yield put({
      type: type.employer.contactCandidate.succeeded,
    })
    yield put({
      type: type.app.snackbar.show,
      payload: {
        snackbars: [
          {message: getTranslation(pt.contactSentCandidate), duration: 1500},
        ],
      },
    })
  } else {
    yield put({
      type: type.candidateSearch.requestContact.save.failed,
      payload: error,
    })
  }
}

function* addSeekerEvaluation(action: ActionType<SeekerEvaluationType>) {
  const {response, error} = yield call(API.post, `/user/seeker-evaluation`, {
    seekerUserId: action.payload?.seekerUserId,
    evaluation: action.payload?.evaluation,
  })
  if (response) {
    yield put({
      type: type.searchCandidates.seekerEvaluation.add.succeeded,
      payload: {
        seekerId: action.payload?.seekerUserId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.searchCandidates.seekerEvaluation.add.failed,
      error,
    })
  }
}

function* editSeekerEvaluation(action: ActionType<SeekerEvaluationType>) {
  const {response, error} = yield call(API.patch, `/user/seeker-evaluation`, {
    seekerUserId: action.payload?.seekerUserId,
    evaluation: action.payload?.evaluation,
  })
  if (response) {
    yield put({
      type: type.searchCandidates.seekerEvaluation.edit.succeeded,
      payload: {
        seekerId: action.payload?.seekerUserId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.searchCandidates.seekerEvaluation.edit.failed,
      error,
    })
  }
}

function* saveCandidateSearch(action: ActionType<SavedSearchType>) {
  const {response, error} = yield call(
    API.post,
    '/employer/saved-search',
    action.payload
  )
  if (response) {
    yield put({
      type: type.searchCandidates.saveSearch.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.searchCandidates.saveSearch.failed,
      payload: error,
    })
  }
}
function* updateSavedSearch(action: ActionType<SavedSearchType>) {
  const {dateCreated, dateUpdated, dateLastCSASent, ...rest} =
    action.payload as SavedSearchType
  const {response, error} = yield call(API.put, '/employer/saved-search', rest)
  if (response) {
    yield put({
      type: type.searchCandidates.updateSavedSearch.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.searchCandidates.updateSavedSearch.failed,
      payload: error,
    })
  }
}

function* getSavedSearch() {
  const {response, error} = yield call(API.get, '/employer/saved-search')
  if (response) {
    yield put({
      type: type.searchCandidates.getSavedSearch.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.searchCandidates.getSavedSearch.failed,
      payload: error,
    })
  }
}

function* getSavedSearchFavorites() {
  const {response, error} = yield call(
    API.get,
    '/employer/saved-search/favorites'
  )
  if (response) {
    yield put({
      type: type.searchCandidates.getSavedSearchFavorites.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.searchCandidates.getSavedSearchFavorites.failed,
      payload: error,
    })
  }
}

function* deleteSavedSearch(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/employer/saved-search/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.searchCandidates.deleteSavedSearch.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({
      type: type.searchCandidates.deleteSavedSearch.failed,
      payload: error,
    })
  }
}

function* getPopularCandidates() {
  const {response, error} = yield call(API.get, '/employer/popular-candidates')
  if (response) {
    yield put({
      type: type.searchCandidates.getPopularCandidates.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.searchCandidates.getPopularCandidates.failed,
      payload: error,
    })
  }
}

export default function* CandidateSearchSaga(): Generator {
  yield all([
    takeEvery(
      type.candidateSearch.requestContact.save.requested,
      requestContact
    ),
    takeLatest(type.searchCandidates.search.requested, searchCandidates),
    takeLatest(type.searchCandidates.loadMore.requested, loadMoreCandidates),
    takeLatest(
      type.searchCandidates.inviteToTestingBulk.requested,
      inviteToTestingBulk
    ),
    takeLatest(
      type.searchCandidates.sendContactRequestsBulk.requested,
      sendContactRequestsBulk
    ),
    takeLatest(
      type.searchCandidates.seekerEvaluation.add.requested,
      addSeekerEvaluation
    ),
    takeLatest(
      type.searchCandidates.seekerEvaluation.edit.requested,
      editSeekerEvaluation
    ),
    takeLatest(type.searchKeywords.requested, getSearchKeywords),
    takeLatest(type.searchCandidates.saveSearch.requested, saveCandidateSearch),
    takeLatest(type.searchCandidates.getSavedSearch.requested, getSavedSearch),
    takeLatest(
      type.searchCandidates.getSavedSearchFavorites.requested,
      getSavedSearchFavorites
    ),
    takeEvery(
      type.searchCandidates.updateSavedSearch.requested,
      updateSavedSearch
    ),
    takeLatest(
      type.searchCandidates.deleteSavedSearch.requested,
      deleteSavedSearch
    ),
    takeLatest(
      type.searchCandidates.getPopularCandidates.requested,
      getPopularCandidates
    ),
  ])
}
