// USER
export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED'
export const USER_FETCH_SUCCEEDED = 'USER_FETCH_SUCCEEDED'
export const USER_FETCH_FAILED = 'GET_USERS_FAILED'

// LOGIN
export const SEEKER_LOGIN_REQUESTED = 'SEEKER_LOGIN_REQUESTED'
export const EMPLOYER_LOGIN_REQUESTED = 'EMPLOYER_LOGIN_REQUESTED'
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'

// LOGOUT
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED'
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'

// Onboarding
export const ONBOARDING_FAILED = 'ONBOARDING_FAILED'
export const setStep = 'ONBOARDING_SET_STEP'

// UPLOAD CV
export const stepUploadCv = {
  requested: 'stepUploadCv_REQUESTED',
  succeeded: 'stepUploadCv_SUCCEEDED',
  failed: 'stepUploadCv_FAILED',
}

// SAVE JOB PREFERENCES
export const stepJobPreferences = {
  requested: 'stepJobPreferences_REQUESTED',
  succeeded: 'stepJobPreferences_SUCCEEDED',
  failed: 'stepJobPreferences_FAILED',
}

export const stepPartialJobPreferences = {
  requested: 'stepPartialJobPreferences_REQUESTED',
  succeeded: 'stepPartialJobPreferences_SUCCEEDED',
  failed: 'stepPartialJobPreferences_FAILED',
}

// SAVE WORK EXPERIENCE
export const stepWorkExperience = {
  requested: 'stepWorkExperience_REQUESTED',
  succeeded: 'stepWorkExperience_SUCCEEDED',
  failed: 'stepWorkExperience_FAILED',
}

export const stepPartialWorkExperience = {
  requested: 'stepPartialWorkExperience_REQUESTED',
  succeeded: 'stepPartialWorkExperience_SUCCEEDED',
  failed: 'stepPartialWorkExperience_FAILED',
}

// SAVE BENEFITS
export const stepBenefitsEducation = {
  requested: 'stepBenefitsEducation_REQUESTED',
  succeeded: 'stepBenefitsEducation_SUCCEEDED',
  failed: 'stepBenefitsEducation_FAILED',
}

export const stepPartialBenefitsEducation = {
  requested: 'stepPartialBenefitsEducation_REQUESTED',
  succeeded: 'stepPartialBenefitsEducation_SUCCEEDED',
  failed: 'stepPartialBenefitsEducation_FAILED',
}

export const CV_REQUESTED = 'CV_REQUESTED'
export const CV_SUCCEEDED = 'CV_SUCCEEDED'

//PROFILE
export const profile = {
  full: {
    requested: 'profileFull_REQUESTED',
    succeeded: 'profileFull_SUCCEEDED',
    failed: 'profileFull_FAILED',
  },
  public: {
    requested: 'profilePublic_REQUESTED',
    succeeded: 'profilePublic_SUCCEEDED',
    failed: 'profilePublic_FAILED',
  },
}

//CONTACT DATA
export const CONTACT_DATA = 'CONTACT_DATA'
export const CONTACT_DATA_SUCCEEDED = 'CONTACT_DATA_SUCCEEDED'
export const CONTACT_DATA_FAILED = 'CONTACT_DATA_FAILED'

//OCCUPATION DATA
export const occupations = {
  requested: 'occupations_REQUESTED',
  succeeded: 'occupations_SUCCEEDED',
  failed: 'occupations_FAILED',
}

//EDUCATION LEVELS DATA
export const educationLevels = {
  requested: 'educationLevels_REQUESTED',
  succeeded: 'educationLevels_SUCCEEDED',
  failed: 'educationLevels_FAILED',
}

//SKILL LEVELS DATA
export const skillLevels = {
  requested: 'skillLevels_REQUESTED',
  succeeded: 'skillLevels_SUCCEEDED',
  failed: 'skillLevels_FAILED',
}

//SKILLS
export const skills = {
  requested: 'skills_REQUESTED',
  succeeded: 'skills_SUCCEEDED',
  failed: 'skills_FAILED',
}

//COUNTRIES DATA
export const countries = {
  requested: 'countries_REQUESTED',
  succeeded: 'countries_SUCCEEDED',
  failed: 'countries_FAILED',
}

//TOWNS DATA
export const towns = {
  requested: 'towns_REQUESTED',
  succeeded: 'towns_SUCCEEDED',
  failed: 'towns_FAILED',
}

//EDUCATION INSTITUTIONS DATA
export const educationInstitutions = {
  requested: 'educationInstitutions_REQUESTED',
  succeeded: 'educationInstitutions_SUCCEEDED',
  failed: 'educationInstitutions_FAILED',
}

//TRAINING TITLES DATA
export const trainingTitles = {
  requested: 'trainingTitles_REQUESTED',
  succeeded: 'trainingTitles_SUCCEEDED',
  failed: 'trainingTitles_FAILED',
}
// Employment Type
export const employmentTypes = {
  requested: 'employmentTypes_REQUESTED',
  succeeded: 'employmentTypes_SUCCEEDED',
  failed: 'employmentTypes_FAILED',
}

// Job Category
export const jobCategory = {
  requested: 'jobCategory_REQUESTED',
  succeeded: 'jobCategory_SUCCEEDED',
  failed: 'jobCategory_FAILED',
}

// Employers
export const employers = {
  requested: 'employers_REQUESTED',
  succeeded: 'employers_SUCCEEDED',
  failed: 'employers_FAILED',
}

//WORK FROM HOME DATA
export const workFromHome = {
  requested: 'workFromHome_REQUESTED',
  succeeded: 'workFromHome_SUCCEEDED',
  failed: 'workFromHome_FAILED',
}

//DESIRED SALARY DATA
//WORK FROM HOME DATA
export const salaries = {
  requested: 'salaries_REQUESTED',
  succeeded: 'salaries_SUCCEEDED',
  failed: 'salaries_FAILED',
}
//DESIRED REGIONS DATA

//EDUCATION INSTITUTIONS DATA
export const regions = {
  requested: 'regions_REQUESTED',
  succeeded: 'regions_SUCCEEDED',
  failed: 'regions_FAILED',
}
export const numberOfRecruits = {
  requested: 'numberOfRecruits_REQUESTED',
  succeeded: 'numberOfRecruits_SUCCEEDED',
  failed: 'numberOfRecruits_FAILED',
}

//AVAILABILITY DATA
export const availabilities = {
  requested: 'availabilities_REQUESTED',
  succeeded: 'availabilities_SUCCEEDED',
  failed: 'availabilities_FAILED',
}

//ADDITIONAL BENEFITS
export const additionalBenefits = {
  requested: 'additionalBenefits_REQUESTED',
  succeeded: 'additionalBenefits_SUCCEEDED',
  failed: 'additionalBenefits_FAILED',
}

//FIELDS OF STUDY
export const fieldsOfStudy = {
  requested: 'fieldsOfStudy_REQUESTED',
  succeeded: 'fieldsOfStudy_SUCCEEDED',
  failed: 'fieldsOfStudy_FAILED',
}

//LANGUAGES
export const languages = {
  requested: 'languages_REQUESTED',
  succeeded: 'languages_SUCCEEDED',
  failed: 'languages_FAILED',
}

//SYSTEM LANGUAGE
export const systemLanguages = {
  requested: 'systemLanguages_REQUESTED',
  succeeded: 'systemLanguages_SUCCEEDED',
  failed: 'systemLanguages_FAILED',
}

export const systemLanguageUpdate = {
  requested: 'updateSystemLanguage_REQUESTED',
  succeeded: 'updateSystemLanguage_SUCCEEDED',
  failed: 'updateSystemLanguage_FAILED',
}

export const contentLanguageUpdate = {
  requested: 'updateContentLanguage_REQUESTED',
  succeeded: 'updateContentLanguage_SUCCEEDED',
  failed: 'updateContentLanguage_FAILED',
}

//LANGUAGE LEVELS
export const languageLevels = {
  requested: 'languageLevels_REQUESTED',
  succeeded: 'languageLevels_SUCCEEDED',
  failed: 'languageLevels_FAILED',
}

//CAREER LEVELS
export const careerLevels = {
  requested: 'careerLevels_REQUESTED',
  succeeded: 'careerLevels_SUCCEEDED',
  failed: 'careerLevels_FAILED',
}

//JOB SEARCH STATUS
export const jobSearchStatuses = {
  requested: 'jobSearchStatuses_REQUESTED',
  succeeded: 'jobSearchStatuses_SUCCEEDED',
  failed: 'jobSearchStatuses_FAILED',
}
export const searchKeywords = {
  requested: 'searchKeywords_REQUESTED',
  succeeded: 'searchKeywords_SUCCEEDED',
  failed: 'searchKeywords_FAILED',
}
export const drivingLicenseCategories = {
  requested: 'drivingLicenseCategories_REQUESTED',
  succeeded: 'drivingLicenseCategories_SUCCEEDED',
  failed: 'drivingLicenseCategories_FAILED',
}

//WORK EXPERIENCES
export const SEEKER_WORK_EXPERIENCE_ADD_REQUESTED =
  'SEEKER_WORK_EXPERIENCE_ADD_REQUESTED'
export const SEEKER_WORK_EXPERIENCE_ADD_SUCCEEDED =
  'SEEKER_WORK_EXPERIENCE_ADD_SUCCEEDED'
export const SEEKER_WORK_EXPERIENCE_ADD_FAILED =
  'SEEKER_WORK_EXPERIENCE_ADD_FAILED'
export const SEEKER_WORK_EXPERIENCE_EDIT_REQUESTED =
  'SEEKER_WORK_EXPERIENCE_EDIT_REQUESTED'
export const SEEKER_WORK_EXPERIENCE_EDIT_SUCCEEDED =
  'SEEKER_WORK_EXPERIENCE_EDIT_SUCCEEDED'
export const SEEKER_WORK_EXPERIENCE_EDIT_FAILED =
  'SEEKER_WORK_EXPERIENCE_EDIT_FAILED'
export const SEEKER_WORK_EXPERIENCE_DELETE_REQUESTED =
  'SEEKER_WORK_EXPERIENCE_DELETE_REQUESTED'
export const SEEKER_WORK_EXPERIENCE_DELETE_SUCCEEDED =
  'SEEKER_WORK_EXPERIENCE_DELETE_SUCCEEDED'
export const SEEKER_WORK_EXPERIENCE_DELETE_FAILED =
  'SEEKER_WORK_EXPERIENCE_DELETE_FAILED'

//PROJECTS

export const SEEKER_PROJECT_ADD_REQUESTED = 'SEEKER_PROJECT_ADD_REQUESTED'
export const SEEKER_PROJECT_ADD_SUCCEEDED = 'SEEKER_PROJECT_ADD_SUCCEEDED'
export const SEEKER_PROJECT_ADD_FAILED = 'SEEKER_PROJECT_ADD_FAILED'
export const SEEKER_PROJECT_EDIT_REQUESTED = 'SEEKER_PROJECT_EDIT_REQUESTED'
export const SEEKER_PROJECT_EDIT_SUCCEEDED = 'SEEKER_PROJECT_EDIT_SUCCEEDED'
export const SEEKER_PROJECT_EDIT_FAILED = 'SEEKER_PROJECT_EDIT_FAILED'
export const SEEKER_PROJECT_DELETE_REQUESTED = 'SEEKER_PROJECT_DELETE_REQUESTED'
export const SEEKER_PROJECT_DELETE_SUCCEEDED = 'SEEKER_PROJECT_DELETE_SUCCEEDED'
export const SEEKER_PROJECT_DELETE_FAILED = 'SEEKER_PROJECT_DELETE_FAILED'
//ATTACHMENTS
export const SEEKER_ATTACHMENTS_ADD_REQUESTED =
  'SEEKER_ATTACHMENTS_ADD_REQUESTED'
export const SEEKER_ATTACHMENTS_ADD_SUCCEEDED =
  'SEEKER_ATTACHMENTS_ADD_SUCCEEDED'
export const SEEKER_ATTACHMENTS_ADD_FAILED = 'SEEKER_ATTACHMENTS_ADD_FAILED'

export const SEEKER_ATTACHMENTS_DELETE_REQUESTED =
  'SEEKER_ATTACHMENTS_DELETE_REQUESTED'
export const SEEKER_ATTACHMENTS_DELETE_SUCCEEDED =
  'SEEKER_ATTACHMENTS_DELETE_SUCCEEDED'
export const SEEKER_ATTACHMENTS_DELETE_FAILED =
  'SEEKER_ATTACHMENTS_DELETE_FAILED'

export const SEEKER_ATTACHMENTS_EDIT_REQUESTED =
  'SEEKER_ATTACHMENTS_EDIT_REQUESTED'
export const SEEKER_ATTACHMENTS_EDIT_SUCCEEDED =
  'SEEKER_ATTACHMENTS_EDIT_SUCCEEDED'
export const SEEKER_ATTACHMENTS_EDIT_FAILED = 'SEEKER_ATTACHMENTS_EDIT_FAILED'

//AWARDS
export const SEEKER_AWARDS_ADD_REQUESTED = 'SEEKER_AWARDS_ADD_REQUESTED'
export const SEEKER_AWARDS_ADD_SUCCEEDED = 'SEEKER_AWARDS_ADD_SUCCEEDED'
export const SEEKER_AWARDS_ADD_FAILED = 'SEEKER_AWARDS_ADD_FAILED'
export const SEEKER_AWARDS_EDIT_REQUESTED = 'SEEKER_AWARDS_EDIT_REQUESTED'
export const SEEKER_AWARDS_EDIT_SUCCEEDED = 'SEEKER_AWARDS_EDIT_SUCCEEDED'
export const SEEKER_AWARDS_EDIT_FAILED = 'SEEKER_AWARDS_EDIT_FAILED'
export const SEEKER_AWARDS_DELETE_REQUESTED = 'SEEKER_AWARDS_DELETE_REQUESTED'
export const SEEKER_AWARDS_DELETE_SUCCEEDED = 'SEEKER_AWARDS_DELETE_SUCCEEDED'
export const SEEKER_AWARDS_DELETE_FAILED = 'SEEKER_AWARDS_DELETE_FAILED'

//EDUCATION INSTITUTIONS DATA
export const auth = {
  confirmProfile: {
    requested: 'confirmProfile_REQUESTED',
    succeeded: 'confirmProfile_SUCCEEDED',
    failed: 'confirmProfile_FAILED',
  },
  rjbProfileStatus: {
    update: 'rjbProfileStatus_UPDATE',
  },
  emailVerificationStatus: {
    update: 'emailVerificationStatus_UPDATE',
  },
  ping: {
    requested: 'authPing_REQUESTED',
    succeeded: 'authPing_SUCCEEDED',
    failed: 'authPing_FAILED',
  },
  ssoCallback: {
    requested: 'ssoCallback_REQUESTED',
    succeeded: 'ssoCallback_SUCCEEDED',
    failed: 'ssoCallback_FAILED',
  },
  welcomePageData: {
    requested: 'welcomePageData_REQUESTED',
    succeeded: 'welcomePageData_SUCCEEDED',
    failed: 'welcomePageData_FAILED',
  },
}
// LANGUAGES
export const SEEKER_LANGUAGES_ADD_REQUESTED = 'SEEKER_LANGUAGES_ADD_REQUESTED'
export const SEEKER_LANGUAGES_ADD_SUCCEEDED = 'SEEKER_LANGUAGES_ADD_SUCCEEDED'
export const SEEKER_LANGUAGES_ADD_FAILED = 'SEEKER_LANGUAGES_ADD_FAILED'

export const SEEKER_LANGUAGES_EDIT_REQUESTED = 'SEEKER_LANGUAGES_EDIT_REQUESTED'
export const SEEKER_LANGUAGES_EDIT_SUCCEEDED = 'SEEKER_LANGUAGES_EDIT_SUCCEEDED'
export const SEEKER_LANGUAGES_EDIT_FAILED = 'SEEKER_LANGUAGES_EDIT_FAILED'

export const SEEKER_LANGUAGES_DELETE_REQUESTED =
  'SEEKER_LANGUAGES_DELETE_REQUESTED'
export const SEEKER_LANGUAGES_DELETE_SUCCEEDED =
  'SEEKER_LANGUAGES_DELETE_SUCCEEDED'
export const SEEKER_LANGUAGES_DELETE_FAILED = 'SEEKER_LANGUAGES_DELETE_FAILED'

//ASSESSMENTS
export const assessments = {
  requested: 'assessments_REQUESTED',
  succeeded: 'assessments_SUCCEEDED',
  failed: 'assessments_FAILED',

  getSeekerAssessments: {
    requested: 'get_seeker_assessments_REQUESTED',
    succeeded: 'get_seeker_assessments_SUCCEEDED',
    failed: 'get_seeker_assessments_FAILED',
  },

  getSeekerLanguageTests: {
    requested: 'get_language_tests_REQUESTED',
    succeeded: 'get_language_tests_SUCCEEDED',
    failed: 'get_language_tests_FAILED',
  },

  getSeekerSkillsTests: {
    requested: 'get_skills_tests_REQUESTED',
    succeeded: 'get_skills_tests_SUCCEEDED',
    failed: 'get_skills_tests_FAILED',
  },

  getAssessments: {
    requested: 'get_assessments_REQUESTED',
    succeeded: 'get_assessments_SUCCEEDED',
    failed: 'get_assessments_FAILED',
  },
  startAssessment: {
    requested: 'start_assessment_REQUESTED',
    succeeded: 'start_assessment_SUCCEEDED',
    failed: 'start_assessment_FAILED',
  },
  getCognitiveTestResults: {
    requested: 'getCognitiveTestResults_REQUESTED',
    succeeded: 'getCognitiveTestResults_SUCCEEDED',
    failed: 'getCognitiveTestResults_FAILED',
  },
  getPsychometricTestResults: {
    requested: 'getPsychometricTestResults_REQUESTED',
    succeeded: 'getPsychometricTestResults_SUCCEEDED',
    failed: 'getPsychometricTestResults_FAILED',
  },
  getLanguageTests: {
    requested: 'getLanguageTests_REQUESTED',
    succeeded: 'getLanguageTests_SUCCEEDED',
    failed: 'getLanguageTests_FAILED',
  },
  getSkillTests: {
    requested: 'getSkillTests_REQUESTED',
    succeeded: 'getSkillTests_SUCCEEDED',
    failed: 'getSkillTests_FAILED',
  },
  addLanguageTest: {
    requested: 'addLanguageTest_REQUESTED',
    succeeded: 'addLanguageTest_SUCCEEDED',
    failed: 'addLanguageTest_FAILED',
  },
  editLanguageTest: {
    requested: 'editLanguageTest_REQUESTED',
    succeeded: 'editLanguageTest_SUCCEEDED',
    failed: 'editLanguageTest_FAILED',
  },
  deleteLanguageTest: {
    requested: 'deleteLanguageTest_REQUESTED',
    succeeded: 'deleteLanguageTest_SUCCEEDED',
    failed: 'deleteLanguageTest_FAILED',
  },
  addSkillTest: {
    requested: 'addSkillTest_REQUESTED',
    succeeded: 'addSkillTest_SUCCEEDED',
    failed: 'addSkillTest_FAILED',
  },
  editSkillTest: {
    requested: 'editSkillTest_REQUESTED',
    succeeded: 'editSkillTest_SUCCEEDED',
    failed: 'editSkillTest_FAILED',
  },
  deleteSkillTest: {
    requested: 'deleteSkillTest_REQUESTED',
    succeeded: 'deleteSkillTest_SUCCEEDED',
    failed: 'deleteSkillTest_FAILED',
  },
  changeCognitiveTestStatus: {
    requested: 'changeCognitiveTestStatus_REQUESTED',
  },
  getPsychometricResultsStatements: {
    requested: 'getPsychometricResultsStatements_REQUESTED',
    succeeded: 'getPsychometricResultsStatements_SUCCEEDED',
    failed: 'getPsychometricResultsStatements_FAILED',
  },
}
// ONBOARDING
export const onboarding = {
  requested: 'onboarding_REQUESTED',
  succeeded: 'onboarding_SUCCEEDED',
  clearErrors: 'onboarding_CLEAR_ERRORS',
  failed: 'onboarding_FAILED',
}

// JOB CATEGORIES
export const jobCategories = {
  requested: 'jobCategories_REQUESTED',
  succeeded: 'jobCategories_SUCCEEDED',
  failed: 'jobCategories_FAILED',
}

export const workExperiences = {
  requested: 'workExperiences_REQUESTED',
  succeeded: 'workExperiences_SUCCEEDED',
  failed: 'workExperiences_FAILED',
}

export const industry = {
  requested: 'industry_REQUESTED',
  succeeded: 'industry_SUCCEEDED',
  failed: 'industry_FAILED',
}

//CONSENTS
export const consents = {
  getConsents: {
    requested: 'consents_REQUESTED',
    succeeded: 'consents_SUCCEEDED',
    failed: 'consents_FAILED',
  },
  changeConsentStatus: {
    requested: 'changeStatus_REQUESTED',
    succeeded: 'changeStatus_SUCCEEDED',
    failed: 'changeStatus_FAILED',
  },
  changeShelbyMillsConsentStatus: {
    requested: 'changeShelbyMillsStatus_REQUESTED',
    succeeded: 'changeShelbyMillsStatus_SUCCEEDED',
    failed: 'changeShelbyMillsStatus_FAILED',
  },
  changeCognitiveConsentStatus: {
    requested: 'changeCognitiveConsentStatu_REQUESTED',
    succeeded: 'changeCognitiveConsentStatu_SUCCEEDED',
    failed: 'changeCognitiveConsentStatu_FAILED',
  },
  unsubscribeNewsletter: {
    requested: 'unsubscribeNewsletter_REQUESTED',
    succeeded: 'unsubscribeNewsletter_SUCCEEDED',
    failed: 'unsubscribeNewsletter_FAILED',
  },
}

//CERTIFICATIONS
export const SEEKER_CERTIFICATION_ADD_REQUESTED =
  'SEEKER_CERTIFICATION_ADD_REQUESTED'
export const SEEKER_CERTIFICATION_ADD_SUCCEEDED =
  'SEEKER_CERTIFICATION_ADD_SUCCEEDED'
export const SEEKER_CERTIFICATION_ADD_FAILED = 'SEEKER_CERTIFICATION_ADD_FAILED'
export const SEEKER_CERTIFICATION_EDIT_REQUESTED =
  'SEEKER_CERTIFICATION_EDIT_REQUESTED'
export const SEEKER_CERTIFICATION_EDIT_SUCCEEDED =
  'SEEKER_CERTIFICATION_EDIT_SUCCEEDED'
export const SEEKER_CERTIFICATION_EDIT_FAILED =
  'SEEKER_CERTIFICATION_EDIT_FAILED'
export const SEEKER_CERTIFICATION_DELETE_REQUESTED =
  'SEEKER_CERTIFICATION_DELETE_REQUESTED'
export const SEEKER_CERTIFICATION_DELETE_SUCCEEDED =
  'SEEKER_CERTIFICATION_DELETE_SUCCEEDED'
export const SEEKER_CERTIFICATION_DELETE_FAILED =
  'SEEKER_CERTIFICATION_DELETE_FAILED'

//CONSENTS

export const seeker = {
  register: {
    requested: 'seekerRegister_REQUESTED',
    succeeded: 'seekerRegister_SUCCEEDED',
    failed: 'seekerRegister_FAILED',
  },
  getSeekerLoginConsents: {
    requested: 'getSeekerLoginConsents_REQUESTED',
    succeeded: 'getSeekerLoginConsents_SUCCEEDED',
    failed: 'getSeekerLoginConsents_FAILED',
  },
  acceptSeeekerLoginConsents: {
    requested: 'acceptSeeekerLoginConsents_REQUESTED',
    succeeded: 'acceptSeeekerLoginConsents_SUCCEEDED',
    failed: 'acceptSeeekerLoginConsents_FAILED',
  },
  education: {
    add: {
      requested: 'seekerEducationAdd_REQUESTED',
      succeeded: 'seekerEducationAdd_SUCCEEDED',
      failed: 'seekerEducationAdd_FAILED',
    },
    update: {
      requested: 'seekerEducationEdit_REQUESTED',
      succeeded: 'seekerEducationEdit_SUCCEEDED',
      failed: 'seekerEducationEdit_FAILED',
    },
    delete: {
      requested: 'seekerEducationDelete_REQUESTED',
      succeeded: 'seekerEducationDelete_SUCCEEDED',
      failed: 'seekerEducationDelete_FAILED',
    },
  },
  updateJobPreferences: {
    public: {
      requested: 'updatePublicJobPreferences_REQUESTED',
      succeeded: 'updatePublicJobPreferences_SUCCEEDED',
      failed: 'updatePublicJobPreferences_FAILED',
    },
    full: {
      requested: 'updateJobPreferences_REQUESTED',
      succeeded: 'updateJobPreferences_SUCCEEDED',
      failed: 'updateJobPreferences_FAILED',
    },
  },
  addDrivingLicence: {
    requested: 'addDrivingLicence_REQUESTED',
    succeeded: 'addDrivingLicence_SUCCEEDED',
    failed: 'addDrivingLicence_FAILED',
  },
  updateBioData: {
    public: {
      requested: 'updatePublicBioData_REQUESTED',
      succeeded: 'updatePublicBioData_SUCCEEDED',
      failed: 'updatePublicBioData_FAILED',
    },
    full: {
      requested: 'updateFullBioData_REQUESTED',
      succeeded: 'updateFullBioData_SUCCEEDED',
      failed: 'updateFullBioData_FAILED',
    },
  },
  workExperience: {
    add: {
      public: {
        requested: 'addPublicWorkExperience_REQUESTED',
        succeeded: 'addPublicWorkExperience_SUCCEEDED',
        failed: 'addPublicWorkExperience_FAILED',
      },
    },
    update: {
      public: {
        requested: 'updatePublicWorkExperience_REQUESTED',
        succeeded: 'updatePublicWorkExperience_SUCCEEDED',
        failed: 'updatePublicWorkExperience_FAILED',
      },
    },
  },
  skills: {
    add: {
      full: {
        requested: 'addFullProfileSkills_REQUESTED',
        succeeded: 'addFullProfileSkills_SUCCEEDED',
        failed: 'addFullProfileSkills_FAILED',
      },
      public: {
        requested: 'addPublicProfileSkills_REQUESTED',
        succeeded: 'addPublicProfileSkills_SUCCEEDED',
        failed: 'addPublicProfileSkills_FAILED',
      },
    },
    update: {
      full: {
        requested: 'updateFullProfileSkills_REQUESTED',
        succeeded: 'updateFullProfileSkills_SUCCEEDED',
        failed: 'updateFullProfileSkills_FAILED',
      },
      public: {
        requested: 'updatePublicProfileSkills_REQUESTED',
        succeeded: 'updatePublicProfileSkills_SUCCEEDED',
        failed: 'updatePublicProfileSkills_FAILED',
      },
    },
    delete: {
      full: {
        requested: 'deleteFullProfileSkills_REQUESTED',
        succeeded: 'deleteFullProfileSkills_SUCCEEDED',
        failed: 'deleteFullProfileSkills_FAILED',
      },
      public: {
        requested: 'deletePublicProfileSkills_REQUESTED',
        succeeded: 'deletePublicProfileSkills_SUCCEEDED',
        failed: 'deletePublicProfileSkills_FAILED',
      },
    },
  },
  editDrivingLicence: {
    requested: 'editDrivingLicence_REQUESTED',
    succeeded: 'editDrivingLicence_SUCCEEDED',
    failed: 'editDrivingLicence_FAILED',
  },

  personalData: {
    get: {
      requested: 'getPersonalData_REQUESTED',
      succeeded: 'getPersonalData_SUCCEEDED',
      failed: 'getPersonalData_FAILED',
    },
    save: {
      requested: 'savePersonalData_REQUESTED',
      succeeded: 'savePersonalData_SUCCEEDED',
      failed: 'savePersonalData_FAILED',
    },
  },
  reparseCv: {
    requested: 'reparseCv_REQUESTED',
    succeeded: 'reparseCv_SUCCEEDED',
    failed: 'reparseCv_FAILED',
  },
}

// EMPLOYER

export const employer = {
  getLoginConsents: {
    requested: 'getLoginConsents_REQUESTED',
    succeeded: 'getLoginConsents_SUCCEEDED',
    failed: 'getLoginConsents_FAILED',
  },
  acceptLoginConsents: {
    requested: 'acceptLoginConsents_REQUESTED',
    succeeded: 'acceptLoginConsents_SUCCEEDED',
    failed: 'acceptLoginConsents_FAILED',
  },
  activeRequests: {
    requested: 'activeRequests_REQUESTED',
    succeeded: 'activeRequests_SUCCEEDED',
    failed: 'activeRequests_FAILED',
  },
  reviewData: {
    get: {
      requested: 'reviewData_REQUESTED',
      succeeded: 'reviewData_SUCCEEDED',
      failed: 'reviewData_FAILED',
    },
  },
  reviewCandidate: {
    add: {
      save: {
        requested: 'reviewCandidate_REQUESTED',
        succeeded: 'reviewCandidate_SUCCEEDED',
        failed: 'reviewCandidate_FAILED',
      },
    },
    edit: {
      save: {
        requested: 'reviewCandidate_REQUESTED',
        succeeded: 'reviewCandidate_SUCCEEDED',
        failed: 'reviewCandidate_FAILED',
      },
    },
  },

  personalData: {
    get: {
      requested: 'getEmployerPersonalData_REQUESTED',
      succeeded: 'getEmployerPersonalData_SUCCEEDED',
      failed: 'getEmployerPersonalData_FAILED',
    },
    save: {
      requested: 'saveEmployerPersonalData_REQUESTED',
      succeeded: 'saveEmployerPersonalData_SUCCEEDED',
      failed: 'saveEmployerPersonalData_FAILED',
    },
  },

  allRequests: {
    latest: {
      requested: 'allRequestsLatest_REQUESTED',
      succeeded: 'allRequestsLatest_SUCCEEDED',
      failed: 'allRequestsLatest_FAILED',
    },

    contactRequests: {
      requested: 'allRequestsContactRequests_REQUESTED',
      succeeded: 'allRequestsContactRequests_SUCCEEDED',
      failed: 'allRequestsContactRequests_FAILED',
      loadmore: {
        requested: 'allRequestsContactRequests_loadmore_REQUESTED',
        succeeded: 'allRequestsContactRequests_loadmore_SUCCEEDED',
        failed: 'allRequestsContactRequests_loadmore_FAILED',
      },
    },
    interviewInvitations: {
      requested: 'allRequestsInterviewInvitations_REQUESTED',
      succeeded: 'allRequestsInterviewInvitations_SUCCEEDED',
      failed: 'allRequestsInterviewInvitations_FAILED',

      loadmore: {
        requested: 'allRequestsInterviewInvitations_loadmore_REQUESTED',
        succeeded: 'allRequestsInterviewInvitations_loadmore_SUCCEEDED',
        failed: 'allRequestsInterviewInvitations_loadmore_FAILED',
      },

      interviewInvitationsAccept: {
        requested: 'interviewInvitationsAccept_REQUESTED',
        succeeded: 'interviewInvitationsAccept_SUCCEEDED',
        failed: 'interviewInvitationsAccept_FAILED',
      },
      interviewInvitationsReject: {
        requested: 'interviewInvitationsReject_REQUESTED',
        succeeded: 'interviewInvitationsReject_SUCCEEDED',
        failed: 'interviewInvitationsReject_FAILED',
      },
      interviewInvitationsCancel: {
        requested: 'interviewInvitationsCancel_REQUESTED',
        succeeded: 'interviewInvitationsCancel_SUCCEEDED',
        failed: 'interviewInvitationsCancel_FAILED',
      },
    },
    offers: {
      requested: 'allRequestsOffers_REQUESTED',
      succeeded: 'allRequestsOffers_SUCCEEDED',
      failed: 'allRequestsOffers_FAILED',

      sendOffer: {
        requested: 'allRequestsOffersSendOffer_REQUESTED',
        succeeded: 'allRequestsOffersSendOffer_SUCCEEDED',
        failed: 'allRequestsOffersSendOffer_FAILED',
      },

      loadmore: {
        requested: 'allRequestsOffers_loadmore_REQUESTED',
        succeeded: 'allRequestsOffers_loadmore_SUCCEEDED',
        failed: 'allRequestsOffers_loadmore_FAILED',
      },
    },
    placementConfirmedOffers: {
      requested: 'placementConfirmedOffers_REQUESTED',
      succeeded: 'placementConfirmedOffers_SUCCEEDED',
      failed: 'placementConfirmedOffers_FAILED',
    },
    placements: {
      requested: 'allRequestsPlacements_REQUESTED',
      succeeded: 'allRequestsPlacements_SUCCEEDED',
      failed: 'allRequestsPlacements_FAILED',
      loadmore: {
        requested: 'allRequestsPlacements_loadmore_REQUESTED',
        succeeded: 'allRequestsPlacements_loadmore_SUCCEEDED',
        failed: 'allRequestsPlacements_loadmore_FAILED',
      },

      confirmed: {
        requested: 'allRequestsPlacements_Confirm_REQUESTED',
        succeeded: 'allRequestsPlacements_Confirm_SUCCEEDED',
        failed: 'allRequestsPlacements_Confirm_FAILED',
      },
      rejected: {
        requested: 'allRequestsPlacements_Reject_REQUESTED',
        succeeded: 'allRequestsPlacements_Reject_SUCCEEDED',
        failed: 'allRequestsPlacements_Reject_FAILED',
      },
    },
    sendMessage: {
      requested: 'allRequestsSendMessage_REQUESTED',
      succeeded: 'allRequestsSendMessage_SUCCEEDED',
      failed: 'allRequestsSendMessage_FAILED',
    },
    rateCandidate: {
      add: {
        requested: 'allRequestsRateCandidateAdd_REQUESTED',
        succeeded: 'allRequestsRateCandidateAdd_SUCCEEDED',
        failed: 'allRequestsRateCandidateAdd_FAILED',
      },
      edit: {
        requested: 'allRequestsRateCandidateEdit_REQUESTED',
        succeeded: 'allRequestsRateCandidateEdit_SUCCEEDED',
        failed: 'allRequestsRateCandidateEditr_FAILED',
      },
    },
  },
  seekersFrom: {
    contactRequests: {
      requested: 'seekersFromContactRequests_REQUESTED',
      succeeded: 'seekersFromContactRequests_SUCCEEDED',
      failed: 'seekersFromContactRequests_FAILED',
    },
    interviewInvitations: {
      requested: 'seekersFromInterviewInvitations_REQUESTED',
      succeeded: 'seekersFromInterviewInvitations_SUCCEEDED',
      failed: 'seekersFromInterviewInvitations_FAILED',
    },
    offers: {
      requested: 'seekersFromOffers_REQUESTED',
      succeeded: 'seekersFromOffers_SUCCEEDED',
      failed: 'seekersFromOffers_FAILED',
    },
    placements: {
      requested: 'seekersFromPlacements_REQUESTED',
      succeeded: 'seekersFromPlacements_SUCCEEDED',
      failed: 'seekersFromPlacements_FAILED',
      confirmed: {
        requested: 'allRequestsPlacementConfirmed_REQUESTED',
        succeeded: 'allRequestsPlacementConfirmed_SUCCEEDED',
        failed: 'allRequestsPlacementConfirmed_FAILED',
      },
      rejected: {
        requested: 'allRequestsPlacementRejected_REQUESTED',
        succeeded: 'allRequestsPlacementRejected_SUCCEEDED',
        failed: 'allRequestsPlacementRejected_FAILED',
      },
    },
  },
  getCandidateProfile: {
    requested: 'companyGetCandidateProfile_REQUESTED',
    succeeded: 'companyGetCandidateProfile_SUCCEEDED',
    failed: 'companyGetCandidateProfiler_FAILED',
  },
  contactCandidate: {
    requested: 'contactCandidate_REQUESTED',
    succeeded: 'contactCandidate_SUCCEEDED',
    failed: 'contactCandidate_FAILED',
  },
  clearCandidateProfile: {
    requested: 'clearCandidateProfile_REQUESTED',
  },
  clearCandidateRequests: {
    requested: 'clearCandidateRequests_REQUESTED',
  },
  authConnectingEmployer: {
    requested: 'authConnectingEmployer_REQUESTED',
    succeeded: 'authConnectingEmployer_SUCCEEDED',
    failed: 'authConnectingEmployer_FAILED',
  },
}
// IMPRESSIONS

export const IMPRESSIONS_IMAGE_ADD_REQUESTED = 'IMPRESSIONS_IMAGE_ADD_REQUESTED'
export const IMPRESSIONS_IMAGE_ADD_SUCCEEDED = 'IMPRESSIONS_IMAGE_ADD_SUCCEEDED'
export const IMPRESSIONS_IMAGE_ADD_FAILED = 'IMPRESSIONS_IMAGE_ADD_FAILED'

export const IMPRESSIONS_IMAGE_EDIT_REQUESTED =
  'IMPRESSIONS_IMAGE_EDIT_REQUESTED'
export const IMPRESSIONS_IMAGE_EDIT_SUCCEEDED =
  'IMPRESSIONS_IMAGE_EDIT_SUCCEEDED'
export const IMPRESSIONS_IMAGE_EDIT_FAILED = 'IMPRESSIONS_IMAGE_EDIT_FAILED'

export const impressions = {
  requested: 'impressions_REQUESTED',
  succeeded: 'impressions_SUCCEEDED',
  failed: 'impressions_FAILED',
}

export const company = {
  data: {
    requested: 'companyData_REQUESTED',
    succeeded: 'companyData_SUCCEEDED',
    failed: 'companyData_FAILED',
  },
  header: {
    edit: {
      requested: 'companyHeaderEdit_REQUESTED',
      succeeded: 'companyHeaderEdit_SUCCEEDED',
      failed: 'companyHeaderEdit_FAILED',
    },
    add: {
      requested: 'companyHeaderAdd_REQUESTED',
      succeeded: 'companyHeaderAdd_SUCCEEDED',
      failed: 'companyHeaderAdd_FAILED',
    },
  },
  logotype: {
    save: {
      requested: 'companyLogoSave_REQUESTED',
      succeeded: 'companyLogoSave_SUCCEEDED',
      failed: 'companyLogoSave_FAILED',
    },
  },
  coverImage: {
    save: {
      requested: 'companyCoverSave_REQUESTED',
      succeeded: 'companyCoverSave_SUCCEEDED',
      failed: 'companyCoverSave_FAILED',
    },
  },
  welcomeMessage: {
    edit: {
      requested: 'companyWelcomeMessageEdit_REQUESTED',
      succeeded: 'companyWelcomeMessageEdit_SUCCEEDED',
      failed: 'companyWelcomeMessageEdit_FAILED',
    },
    add: {
      requested: 'companyWelcomeMessageAdd_REQUESTED',
      succeeded: 'companyWelcomeMessageAdd_SUCCEEDED',
      failed: 'companyWelcomeMessageAdd_FAILED',
    },
  },
  portfolio: {
    save: {
      requested: 'savePortfolio_REQUESTED',
      succeeded: 'savePortfolio_SUCCEEDED',
      failed: 'savePortfolio_FAILED',
    },
  },
  benefits: {
    save: {
      requested: 'saveBenefits_REQUESTED',
      succeeded: 'saveBenefits_SUCCEEDED',
      failed: 'saveBenefits_FAILED',
    },
  },

  companyDescription: {
    save: {
      requested: 'companyDescriptionAdd_REQUESTED',
      succeeded: 'companyDescriptionAdd_SUCCEEDED',
      failed: 'companyDescriptionAdd_FAILED',
    },
    biziSi: {
      requested: 'companyDescriptionBiziSi_REQUESTED',
      succeeded: 'companyDescriptionBiziSi_SUCCEEDED',
      failed: 'companyDescriptionBiziSi_FAILED',
    },
  },
  employmentInformation: {
    save: {
      requested: 'employmentInformation_REQUESTED',
      succeeded: 'employmentInformation_SUCCEEDED',
      failed: 'employmentInformation_FAILED',
    },
  },
  questions: {
    save: {
      requested: 'saveQuestions_REQUESTED',
      succeeded: 'saveQuestions_SUCCEEDED',
      failed: 'saveQuestions_FAILED',
    },
  },
  impressions: {
    add: {
      requested: 'addImpressions_REQUESTED',
      succeeded: 'addImpressions_SUCCEEDED',
      failed: 'addImpressions_FAILED',
    },
    update: {
      requested: 'updateImpressions_REQUESTED',
      succeeded: 'updateImpressions_SUCCEEDED',
      failed: 'updateImpressions_FAILED',
    },
  },
  videos: {
    add: {
      requested: 'addVideos_REQUESTED',
      succeeded: 'addVideos_SUCCEEDED',
      failed: 'addVideos_FAILED',
    },
    update: {
      requested: 'updateVideos_REQUESTED',
      succeeded: 'updateVideos_SUCCEEDED',
      failed: 'updateVideos_FAILED',
    },
  },
}

export const homepageHelpFaq = {
  contactUs: {
    send: {
      requested: 'CONTACT_US_SEND_REQUESTED',
      succeeded: 'CONTACT_US_SEND_SUCCEEDED',
      failed: 'CONTACT_US_SEND__FAILED',
    },
  },
}

export const homepage = {
  inviteFriend: {
    requested: 'homepageInviteFriend_REQUESTED',
    succeeded: 'homepageInviteFriend_SUCCEEDED',
    failed: 'homepageInviteFriend__FAILED',
  },
  testimonials: {
    requested: 'homepageTestimonials_REQUESTED',
    succeeded: 'homepageTestimonials_SUCCEEDED',
    failed: 'homepageTestimonials__FAILED',
  },
  topCompanies: {
    requested: 'homepageTopCompanies_REQUESTED',
    succeeded: 'homepageTopCompanies_SUCCEEDED',
    failed: 'homepageTopCompanies__FAILED',
  },
  consents: {
    requested: 'homepageGetConsents_REQUESTED',
    succeeded: 'homepageGetConsents_SUCCEEDED',
    failed: 'homepageGetConsents__FAILED',
  },
  updateConsents: {
    requested: 'homepageUpdateConsents_REQUESTED',
    succeeded: 'homepageUpdateConsents_SUCCEEDED',
    failed: 'homepageUpdateConsents_FAILED',
  },
  logConsentReason: {
    requested: 'homepageLogConsentReason_REQUESTED',
    succeeded: 'homepageLogConsentReason_SUCCEEDED',
    failed: 'homepageLogConsentReason_FAILED',
  },
}

export const searchCandidates = {
  search: {
    requested: 'searchCandidates_REQUESTED',
    succeeded: 'searchCandidates_SUCCEEDED',
    failed: 'searchCandidates_FAILED',
  },
  loadMore: {
    requested: 'loadMoreCandidates_REQUESTED',
    succeeded: 'loadMoreCandidates_SUCCEEDED',
    failed: 'loadMoreCandidates_FAILED',
  },
  inviteToTestingBulk: {
    requested: 'inviteToTestingBulk_REQUESTED',
    succeeded: 'inviteToTestingBulk_SUCCEEDED',
    failed: 'inviteToTestingBulk_FAILED',
  },
  sendContactRequestsBulk: {
    requested: 'sendContactRequestsBulk_REQUESTED',
    succeeded: 'sendContactRequestsBulk_SUCCEEDED',
    failed: 'sendContactRequestsBulk_FAILED',
  },
  seekerEvaluation: {
    add: {
      requested: 'addSeekerEvaluation_REQUESTED',
      succeeded: 'addSeekerEvaluation_SUCCEEDED',
      failed: 'addSeekerEvaluation_FAILED',
    },
    edit: {
      requested: 'editSeekerEvaluation_REQUESTED',
      succeeded: 'editSeekerEvaluation_SUCCEEDED',
      failed: 'editSeekerEvaluation_FAILED',
    },
  },
  sortResults: {
    requested: 'searchCandidatesSortResults_REQUESTED',
  },
  getSavedSearch: {
    requested: 'getSavedSearch_REQUESTED',
    succeeded: 'getSavedSearch_SUCCEEDED',
    failed: 'getSavedSearch_FAILED',
  },
  getSavedSearchFavorites: {
    requested: 'getSavedSearchFavorites_REQUESTED',
    succeeded: 'getSavedSearchFavorites_SUCCEEDED',
    failed: 'getSavedSearchFavorites_FAILED',
  },
  clearSavedSearch: {
    requested: 'clearSavedSearch_REQUESTED',
  },
  clearSearch: {
    requested: 'clearSearch_REQUESTED',
  },
  deleteSavedSearch: {
    requested: 'deleteSavedSearch_REQUESTED',
    succeeded: 'deleteSavedSearch_SUCCEEDED',
    failed: 'deleteSavedSearch_FAILED',
  },
  saveSearch: {
    requested: 'saveCandidateSearch_REQUESTED',
    succeeded: 'saveCandidateSearch_SUCCEEDED',
    failed: 'saveCandidateSearch_FAILED',
  },
  updateSavedSearch: {
    requested: 'updateSavedSearch_REQUESTED',
    succeeded: 'updateSavedSearch_SUCCEEDED',
    failed: 'updateSavedSearch_FAILED',
  },
  editSearch: {
    requested: 'editCandidateSearch_REQUESTED',
    succeeded: 'editCandidateSearch_SUCCEEDED',
    failed: 'editCandidateSearch_FAILED',
  },
  setFiltersType: {
    requested: 'setFiltersType_REQUESTED',
  },
  getPopularCandidates: {
    requested: 'getPopularCandidates_REQUESTED',
    succeeded: 'getPopularCandidates_SUCCEEDED',
    failed: 'getPopularCandidates_FAILED',
  },
}

export const candidateSearch = {
  requestContact: {
    save: {
      requested: 'saveRequestContact_REQUESTED',
      succeeded: 'saveRequestContact_SUCCEEDED',
      failed: 'saveRequestContact_FAILED',
    },
  },
  CandidateToProject: {
    add: {
      requested: 'addCandidateToProject_REQUESTED',
      succeeded: 'addCandidateToProject_SUCCEEDED',
      failed: 'addCandidateToProjectt_FAILED',
    },
    remove: {
      requested: 'removeCandidateFromProject_REQUESTED',
      succeeded: 'removeCandidateFromProject_SUCCEEDED',
      failed: 'removeCandidateFromProject_FAILED',
    },
    addProjectForCandidate: {
      requested: 'addProjectForCandidate_REQUESTED',
      succeeded: 'addProjectForCandidate_SUCCEEDED',
      failed: 'addProjectForCandidate_FAILED',
    },
    getSeekerProjects: {
      requested: 'getSeekerProjects_REQUESTED',
      succeeded: 'getSeekerProjects_SUCCEEDED',
      failed: 'getSeekerProjects_FAILED',
    },
  },
}
export const app = {
  snackbar: {
    show: 'snackbar_show',
    hide: 'snackbar_hide',
    clean: 'snackbar_clean',
  },
}

export const myProjects = {
  widget: {
    get: {
      requested: 'getMyWidgetProjects_REQUESTED',
      succeeded: 'getMyWidgetProjects_SUCCEEDED',
      failed: 'getMyWidgetProjects_FAILED',
    },
  },
  all: {
    get: {
      requested: 'getMyAllProjects_REQUESTED',
      succeeded: 'getMyAllProjects_SUCCEEDED',
      failed: 'getMyAllProjects_FAILED',
    },
  },
  project: {
    get: {
      requested: 'getMyProject_REQUESTED',
      succeeded: 'getMyProject_SUCCEEDED',
      failed: 'getMyProject_FAILED',
    },
  },
  projectCandidates: {
    get: {
      requested: 'getMyProjectCandidates_REQUESTED',
      succeeded: 'getMyProjectCandidates_SUCCEEDED',
      failed: 'getMyProjectCandidates_FAILED',
    },
    loadMore: {
      requested: 'loadMoreProjectCandidates_REQUSTED',
      succeeded: 'loadMoreProjectCandidates_SUCCEEDED',
      failed: 'loadMoreProjectCandidates_FAILED',
    },
  },
  addNewProject: {
    requested: 'addNewProject_REQUESTED',
    succeeded: 'addNewProject_SUCCEEDED',
    failed: 'addNewProject_FAILED',
  },
  editProject: {
    requested: 'editProject_REQUESTED',
    succeeded: 'editProject_SUCCEEDED',
    failed: 'editProject_FAILED',
  },
  addToNewProject: {
    requested: 'addCandidateToNewProject_REQUESTED',
    succeeded: 'addCandidateToNewProject_SUCCEEDED',
    failed: 'addCandidateToNewProject_FAILED',
  },
  addToProject: {
    requested: 'addCandidateToProject_REQUESTED',
    succeeded: 'addCandidateToProject_SUCCEEDED',
    failed: 'addCandidateToProject_FAILED',
  },
  removeFromProjects: {
    requested: 'removeCandidateFromProjects_REQUESTED',
    succeeded: 'removeCandidateFromProjects_SUCCEEDED',
    failed: 'removeCandidateFromProjects_FAILED',
  },
  removeFromAllProjects: {
    requested: 'removeCandidateFromAllProjects_REQUESTED',
    succeeded: 'removeCandidateFromAllProjects_SUCCEEDED',
    failed: 'removeCandidateFromAllProjects_FAILED',
  },
  archive: {
    requested: 'archiveProject_REQUESTED',
    succeeded: 'archiveProject_SUCCEEDED',
    failed: 'archiveProject_FAILED',
  },
  delete: {
    requested: 'deleteProject_REQUESTED',
    succeeded: 'deleteProject_SUCCEEDED',
    failed: 'deleteProject_FAILED',
  },
  rateCandidate: {
    add: {
      requested: 'myProjectsRateCandidateAdd_REQUESTED',
      succeeded: 'myProjectsRateCandidateAdd_SUCCEEDED',
      failed: 'myProjectsRateCandidateAdd_FAILED',
    },
    edit: {
      requested: 'myProjectsRateCandidateEdit_REQUESTED',
      succeeded: 'myProjectsRateCandidateEdit_SUCCEEDED',
      failed: 'myProjectsRateCandidateEdit_FAILED',
    },
    delete: {
      requested: 'myProjectsRateCandidateDelete_REQUESTED',
      succeeded: 'myProjectsRateCandidateDelete_SUCCEEDED',
      failed: 'myProjectsRateCandidateDelete_FAILED',
    },
  },
  reviewCandidate: {
    edit: {
      requested: 'myProjectsReviewCandidateEdit_REQUESTED',
      succeeded: 'myProjectsReviewCandidateEdit_SUCCEEDED',
      failed: 'myProjectsReviewCandidateEdit_FAILED',
    },
  },
  loadMore: {
    requested: 'loadMoreProjects_REQUSTED',
    succeeded: 'loadMoreProjects_SUCCEEDED',
    failed: 'loadMoreProjects_FAILED',
  },
  searchSavedCandidates: {
    requested: 'searchSavedCandidates_REQUSTED',
    succeeded: 'searchSavedCandidates_SUCCEEDED',
    failed: 'searchSavedCandidates_FAILED',
  },
  search: {
    requested: 'searchMyProjects_REQUESTED',
    succeeded: 'searchMyProjects_SUCCEEDED',
    failed: 'searchMyProjects_FAILED',
    clear: 'searchMyProjects_CLEAR',
  },
  loadMoreSearch: {
    requested: 'loadMoreSearchMyProjects_REQUESTED',
    succeeded: 'loadMoreSearchMyProjects_SUCCEEDED',
    failed: 'loadMoreSearchMyProjects_FAILED',
  },
}

export const widgetBar = {
  sidebarClosed: {
    setStatus: 'SET_SIDEBAR_CLOSED',
  },
  helpfulHintClosed: {
    setStatus: {
      requested: 'set_helpful_hint_closed_REQUESTED',
      succeeded: 'set_helpful_hint_closed_SUCCEEDED',
      failed: 'set_helpful_hint_closed_FAILED',
    },
    getStatus: {
      requested: 'get_helpful_hint_closed_REQUESTED',
      succeeded: 'get_helpful_hint_closed_SUCCEEDED',
      failed: 'get_helpful_hint_closed_FAILED',
    },
  },
  candidateBasicData: {
    setCandidateBasicData: 'SET_CANDIDATE_BASIC_DATA',
  },
  selectedRequest: {
    setSelectedRequest: 'setSelectedRequestWdiget',
  },
  comments: {
    get: {
      requested: 'getComments_REQUESTED',
      succeeded: 'getComments_SUCCEEDED',
      failed: 'getComments_FAILED',
    },
    save: {
      requested: 'saveComments_REQUESTED',
      succeeded: 'saveComments_SUCCEEDED',
      failed: 'saveComments_FAILED',
    },
  },
  messages: {
    get: {
      requested: 'getMessages_REQUESTED',
      succeeded: 'getMessages_SUCCEEDED',
      failed: 'getMessages_FAILED',
    },
  },
  history: {
    get: {
      requested: 'getHistory_REQUESTED',
      succeeded: 'getHistory_SUCCEEDED',
      failed: 'getHistory_FAILED',
    },
  },
}

export const blacklist = {
  getDropdownItems: {
    requested: 'blacklistDropdownItemsGet_REQUESTED',
    succeeded: 'blacklistDropdownItemsGet_SUCCEEDED',
    failed: 'blacklistDropdownItemsGet_FAILED',
  },
  get: {
    requested: 'blacklistGet_REQUESTED',
    succeeded: 'blacklistGet_SUCCEEDED',
    failed: 'blacklistGet_FAILED',
  },
  create: {
    requested: 'blacklistCreate_REQUESTED',
    succeeded: 'blacklistCreate_SUCCEEDED',
    failed: 'blacklistCreate_FAILED',
  },
  delete: {
    requested: 'blacklistDelete_REQUESTED',
    succeeded: 'blacklistDelete_SUCCEEDED',
    failed: 'blacklistDelete_FAILED',
  },
}

export const specto = {
  token: {
    requested: 'spectoToken_REQUESTED',
    succeeded: 'spectoToken_SUCCEEDED',
    failed: 'spectoToken_FAILED',
  },
  candidateId: {
    set: 'candidateId_SET',
  },
  proposeNewTimeId: {
    set: 'proposeNewTimeId_SET',
  },
  candidateSpectoId: {
    requested: 'candidateSpectoId_REQUESTED',
    succeeded: 'candidateSpectoId_SUCCEEDED',
    failed: 'candidateSpectoId_FAILED',
  },
}

export const candidate = {
  fullCandidateData: {
    get: {
      requested: 'candidateData_REQUESTED',
      succeeded: 'candidateData_SUCCEEDED',
      failed: 'candidateData_FAILED',
    },
  },
  minifeeds: {
    get: {
      requested: 'minifeeds_REQUESTED',
      succeeded: 'minifeeds_SUCCEEDED',
      failed: 'minifeeds_FAILED',
    },
  },
  proposeNewTime: {
    requested: 'proposeNewTime_REQUESTED',
    succeeded: 'proposeNewTime_SUCCEEDED',
    failed: 'proposeNewTime_FAILED',
  },
  approveInterviewInvitation: {
    requested: 'approveInterviewInvitation_REQUESTED',
    succeeded: 'approveInterviewInvitation_SUCCEEDED',
    failed: 'approveInterviewInvitation_FAILED',
  },
  rejectInterviewInvitation: {
    requested: 'rejectInterviewInvitation_REQUESTED',
    succeeded: 'rejectInterviewInvitation_SUCCEEDED',
    failed: 'rejectInterviewInvitation_FAILED',
  },
  approveContactRequest: {
    requested: 'approveContactRequest_REQUESTED',
    succeeded: 'approveContactRequest_SUCCEEDED',
    failed: 'approveContactRequest_FAILED',
  },
  rejectContactRequest: {
    requested: 'rejectContactRequest_REQUESTED',
    succeeded: 'rejectContactRequest_SUCCEEDED',
    failed: 'rejectContactRequest_FAILED',
  },
  approveOfferRequest: {
    requested: 'approveOfferRequest_REQUESTED',
    succeeded: 'approveOfferRequest_SUCCEEDED',
    failed: 'approveOfferRequest_FAILED',
  },
  rejectOfferRequest: {
    requested: 'rejectOfferRequest_REQUESTED',
    succeeded: 'rejectOfferRequest_SUCCEEDED',
    failed: 'rejectOfferRequest_FAILED',
  },
  confirmPlacement: {
    requested: 'confirmPlacement_REQUESTED',
    succeeded: 'confirmPlacement_SUCCEEDED',
    failed: 'confirmPlacement_FAILED',
  },
  rejectPlacement: {
    requested: 'rejectPlacement_REQUESTED',
    succeeded: 'rejectPlacement_SUCCEEDED',
    failed: 'rejectPlacement_FAILED',
  },
  completeness: {
    requested: 'completeness_REQUESTED',
    succeeded: 'completeness_SUCCEEDED',
    failed: 'completeness_FAILED',
  },
}

export const incomingRequests = {
  dashboard: {
    requested: 'dashboardIncomingRequests_REQUESTED',
    succeeded: 'dashboardIncomingRequests_SUCCEEDED',
    failed: 'dashboardIncomingRequests_FAILED',
  },
  journey: {
    requested: 'incomingRequests_journey_REQUESTED',
    succeeded: 'incomingRequests_journey_SUCCEEDED',
    failed: 'incomingRequests_journey_FAILED',
  },
  search: {
    requested: 'incomingRequests_Search_REQUESTED',
    succeeded: 'incomingRequests_Search_SUCCEEDED',
    failed: 'incomingRequests_Search_FAILED',
  },
  loadMore: {
    requested: 'incomingRequests_LoadMore_REQUESTED',
    succeeded: 'incomingRequests_LoadMore_SUCCEEDED',
    failed: 'incomingRequests_LoadMore_FAILED',
  },
  getCompanies: {
    requested: 'incomingRequests_getCompanies_REQUESTED',
    succeeded: 'incomingRequests_getCompanies_SUCCEEDED',
    failed: 'incomingRequests_getCompanies_FAILED',
  },
  update: {
    proposeNewTime: {
      requested: 'incomingRequestsEdit_proposeNewTime_REQUESTED',
      succeeded: 'incomingRequestsEdit_proposeNewTime_SUCCEEDED',
      failed: 'incomingRequestsEdit_proposeNewTime_FAILED',
    },
    approveInterviewInvitation: {
      requested: 'incomingRequestsEdit_approveInterviewInvitation_REQUESTED',
      succeeded: 'incomingRequestsEdit_approveInterviewInvitation_SUCCEEDED',
      failed: 'incomingRequestsEdit_approveInterviewInvitation_FAILED',
    },
    rejectInterviewInvitation: {
      requested: 'incomingRequestsEdit_rejectInterviewInvitation_REQUESTED',
      succeeded: 'incomingRequestsEdit_rejectInterviewInvitation_SUCCEEDED',
      failed: 'incomingRequestsEdit_rejectInterviewInvitation_FAILED',
    },
    cancelInterviewInvitation: {
      requested: 'incomingRequestsEdit_cancelInterviewInvitation_REQUESTED',
      succeeded: 'incomingRequestsEdit_cancelInterviewInvitation_SUCCEEDED',
      failed: 'incomingRequestsEdit_cancelInterviewInvitation_FAILED',
    },
    approveContactRequest: {
      requested: 'incomingRequestsEdit_approveContactRequest_REQUESTED',
      succeeded: 'incomingRequestsEdit_approveContactRequest_SUCCEEDED',
      failed: 'incomingRequestsEdit_approveContactRequest_FAILED',
    },
    rejectContactRequest: {
      requested: 'incomingRequestsEdit_rejectContactRequest_REQUESTED',
      succeeded: 'incomingRequestsEdit_rejectContactRequest_SUCCEEDED',
      failed: 'incomingRequestsEdit_rejectContactRequest_FAILED',
    },
    approveOfferRequest: {
      requested: 'incomingRequestsEdit_approveOfferRequest_REQUESTED',
      succeeded: 'incomingRequestsEdit_approveOfferRequest_SUCCEEDED',
      failed: 'incomingRequestsEdit_approveOfferRequest_FAILED',
    },
    rejectOfferRequest: {
      requested: 'incomingRequestsEdit_rejectOfferRequest_REQUESTED',
      succeeded: 'incomingRequestsEdit_rejectOfferRequest_SUCCEEDED',
      failed: 'incomingRequestsEdit_rejectOfferRequest_FAILED',
    },
    confirmPlacement: {
      requested: 'incomingRequestsEdit_confirmPlacement_REQUESTED',
      succeeded: 'incomingRequestsEdit_confirmPlacement_SUCCEEDED',
      failed: 'incomingRequestsEdit_confirmPlacement_FAILED',
    },
    rejectPlacement: {
      requested: 'incomingRequestsEdit_rejectPlacement_REQUESTED',
      succeeded: 'incomingRequestsEdit_rejectPlacement_SUCCEEDED',
      failed: 'incomingRequestsEdit_rejectPlacement_FAILED',
    },
  },
}

export const employerOnboarding = {
  getEmployerOnboarding: {
    requested: 'employerOnboarding_REQUESTED',
    succeeded: 'employerOnboarding_SUCCEEDED',
    failed: 'employerOnboarding_FAILED',
  },
  stepCompanyContactInfo: {
    requested: 'stepCompanyContactInfo_REQUESTED',
    succeeded: 'stepCompanyContactInfo_SUCCEEDED',
    failed: 'stepCompanyContactInfo_FAILED',
  },
  stepHiringPlan: {
    requested: 'stepHiringPlan_REQUESTED',
    succeeded: 'stepHiringPlan_SUCCEEDED',
    failed: 'stepHiringPlan_FAILED',
  },
  stepPartialHiringPlan: {
    requested: 'stepPartialHiringPlan_REQUESTED',
    succeeded: 'stepPartialHiringPlan_SUCCEEDED',
    failed: 'stepPartialHiringPlan_FAILED',
  },
  setEmployerStep: {
    requested: 'setEmployerStep_REQUESTED',
    succeeded: 'setEmployerStep_SUCCEEDED',
    failed: 'setEmployerStep_FAILED',
  },
}

export const messageCenter = {
  search: {
    requested: 'messageCenter_Search_REQUESTED',
    succeeded: 'messageCenter_Search_SUCCEEDED',
    failed: 'messageCenter_Search_FAILED',
  },
  selectConversation: {
    requested: 'messageCenter_GetMessagesFromSpecificUser_REQUESTED',
    succeeded: 'messageCenter_GetMessagesFromSpecificUser_SUCCEEDED',
    failed: 'messageCenter_GetMessagesFromSpecificUser_FAILED',
  },
  getUnreadConversationsCount: {
    requested: 'messageCenter_getUnreadConversationsCount_REQUESTED',
    succeeded: 'messageCenter_getUnreadConversationsCount_SUCCEEDED',
    failed: 'messageCenter_getUnreadConversationsCount_FAILED',
  },
  getSpecificParticipiantUnreadConversationsCount: {
    requested:
      'messageCenter_getSpecificParticipiantUnreadConversationsCount_REQUESTED',
    succeeded:
      'messageCenter_getSpecificParticipiantUnreadConversationsCount_SUCCEEDED',
    failed:
      'messageCenter_getSpecificParticipiantUnreadConversationsCount_FAILED',
  },
  loadMoreMessages: {
    requested: 'messageCenter_LoadMoreMessages_REQUESTED',
    succeeded: 'messageCenter_LoadMoreMessages_SUCCEEDED',
    failed: 'messageCenter_LoadMoreMessages_FAILED',
  },
  loadMoreConversations: {
    requested: 'messageCenter_LoadMoreConversations_REQUESTED',
    succeeded: 'messageCenter_LoadMoreConversations_SUCCEEDED',
    failed: 'messageCenter_LoadMoreConversations_FAILED',
  },
  createConversation: {
    requested: 'messageCenter_CreateConversation_REQUESTED',
    succeeded: 'messageCenter_CreateConversation_SUCCEEDED',
    failed: 'messageCenter_CreateConversation_FAILED',
  },
  createMessage: {
    requested: 'messageCenter_createMessage_REQUESTED',
    succeeded: 'messageCenter_createMessage_SUCCEEDED',
    failed: 'messageCenter_createMessage_FAILED',
  },
  resetMessageType: {
    requested: 'messageCenter_ResetMessageType_REQUESTED',
    succeeded: 'messageCenter_ResetMessageType_SUCCEEDED',
    failed: 'messageCenter_ResetMessageType_FAILED',
  },
  deleteConversations: {
    requested: 'messageCenter_DeleteConversations_REQUESTED',
    succeeded: 'messageCenter_DeleteConversations_SUCCEEDED',
    failed: 'messageCenter_DeleteConversations_FAILED',
  },
  archiveConversation: {
    requested: 'messageCenter_ArchiveConversation_REQUESTED',
    succeeded: 'messageCenter_ArchiveConversation_SUCCEEDED',
    failed: 'messageCenter_ArchiveConversation_FAILED',
  },
  getMessageRecipients: {
    requested: 'messageCenter_getMessageRecipients_REQUESTED',
    succeeded: 'messageCenter_getMessageRecipients_SUCCEEDED',
    failed: 'messageCenter_getMessageRecipients_FAILED',
  },
  conversationRecieved: {
    requested: 'messageCenter_conversationRecieved_REQUESTED',
    succeeded: 'messageCenter_conversationRecieved_SUCCEEDED',
    failed: 'messageCenter_conversationRecieved_FAILED',
  },
  getConversationByUserIds: {
    requested: 'messageCenter_getConversationByUserIds_REQUESTED',
    succeeded: 'messageCenter_getConversationByUserIds_SUCCEEDED',
    failed: 'messageCenter_getConversationByUserIds_FAILED',
  },
  resetMessageCenterData: 'messageCenter_resetMessageCenterData',
  socketIO: {
    startMessageChannel: 'messageCenter_socket_START_MESSAGE_CHANNEL',
    setSocket: 'messageCenter_socket_SET_SOCKET',
    events: {
      errorOccured: 'messageCenter_socketIO_event_errorOccured',
      conversationsJoined: 'messageCenter_socketIO_event_conversationsJoined',
      messageReceived: 'messageCenter_socketIO_event_messageReceived',
      messageCreated: 'messageCenter_socketIO_event_messageCreated',
      conversationCreated: 'messageCenter_socketIO_event_conversationCreated',
      createMessage: 'messageCenter_socketIO_event_createMessage',
      createConversation: 'messageCenter_socketIO_event_createConversation',
      unreadConversationsCount:
        'messageCenter_socketIO_event_unreadConversationsCount',
      unreadConversationsCountCompanyUsers:
        'messageCenter_socketIO_event_unreadConversationsCountCompanyUsers',
      unreadConversationsCountSeekers:
        'messageCenter_socketIO_event_unreadConversationsCountSeekers',
      unreadConversationsCountTSA:
        'messageCenter_socketIO_event_unreadConversationsCountTSA',
      unreadConversation: 'messageCenter_socketIO_event_unreadConversation',
      readConversation: 'messageCenter_socketIO_event_readConversation',
      conversationsRead: 'messageCenter_socketIO_event_conversationsRead',
      conversationsUnread: 'messageCenter_socketIO_event_conversationsUnread',
      addedToConversation: 'messageCenter_socketIO_event_addedToConversation',
    },
  },
}

export const dsaActionTypes = {
  createReport: {
    requested: 'createReport_REQUESTED',
    succeeded: 'createReport_SUCCEEDED',
    failed: 'createReport_FAILED',
  },
  removeReportSuccess: 'dsa_removeReportSuccess',
  removeReportError: 'dsa_removeReportError',
  createAppeal: {
    requested: 'createAppeal_REQUESTED',
    succeeded: 'createAppeal_SUCCEEDED',
    failed: 'createAppeal_FAILED',
  },
  removeAppealSuccess: 'dsa_removeAppealSuccess',
}
