export enum ConsentsTranslations {
  title = 'consents.title',
  labelTitle = 'consents.labelTitle',
  labelStatus = 'consents.labelStatus',
  labelDate = 'consents.labelDate',
  labelContent = 'consents.labelContent',
  labelAction = 'consents.labelAction',
  consentGrant = 'consents.consentGrant',
  consentRevoke = 'consents.consentRevoke',
  consentGranted = 'consents.consentGranted',
  consentAccept = 'consents.consentAccept',
  consentRecall = 'consents.consentRecall',
  consentRevoked = 'consents.consentRevoked',
  termsOfUse = 'consents.termsOfUse',
  privacyPolicyPsychometric = 'consents.privacyPolicyPsychometric',
  privacyPolicy = 'consents.privacyPolicy',
  cookiesPolicy = 'consents.cookiesPolicy',
  consentsExpired = 'consents.consentsExpired',
  consentsUpToDate = 'consents.consentsUpToDate',
  consentsUpToDateFullAdvantage = 'consents.consentsUpToDateFullAdvantage',
  consentsContent = 'consents.consentsContent',
  consentsDownload = 'consents.consentsDownload',
  consentsPrint = 'consents.consentsPrint',
  modalTermsOfUSeAgree = 'consents.modalTermsOfUSeAgree',
  modalPersonalData = 'consents.modalPersonalData',
  infoModalPersonalData = 'consents.infoModalPersonalData',
  infoModalPsychometric = 'consents.infoModalPsychometric',
  infoModalCognitive = 'consents.infoModalCognitive',
  infoModalNewsletter = 'consents.infoModalNewsletter',
  infoModalNewsletter2 = 'consents.infoModalNewsletter2',
  modalMarketingText = 'consents.modalMarketingText',
  modalCantBeRevoked = 'consents.modalCantBeRevoked',
  modalWithdrawPrivacyOrCookies = 'consents.modalWithdrawPrivacyOrCookies',
  statementOfConsent = 'consents.statementOfConsent',
  withdrawConsent = 'consents.withdrawConsent',
  modalWithdrawTestsConsents = 'consents.modalWithdrawTestsConsents',
  basicConsents = 'consents.basicConsents',
  consentsForNotifications = 'consents.consentsForNotifications',
  consentsForTests = 'consents.consentsForTests',
  granted = 'consents.granted',
  revoked = 'consents.revoked',
  checkmarkText = 'consents.checkmarkText',
  privacyPolicyModalTitle = 'consents.privacyPolicyModalTitle',
  checkboxNewsletter = 'consents.checkboxNewsletter',
  checkboxMarketing = 'consents.checkboxMarketing',
  thirdPartyMarketing = 'consents.thirdPartyMarketing',
  mandatoryConsentsText = 'consents.mandatoryConsentsText',
  mandatoryConsentsTitle = 'consents.mandatoryConsentsTitle',
  mandatoryConsentsDescription = 'consents.mandatoryConsentsDescription',
  reviewNotificationConsents = 'consents.reviewNotificationConsents',
  successfullyUnsubscribed = 'consents.successfullyUnsubscribed',
}
