export const reportInformation = {
  'reportInformation.reportInformationHtml': `<div>
  <p class="termsAndConditionsContainerDownText termsAndConditionsFirstText">
    Informacije v skladu z 2. odstavkom 24. člena Uredbe EU 2022/2065 (Akt o digitalnih storitvah):
  </p>
  <p class="termsAndConditionsContainerDownText">
    Število povprečnih mesečnih aktivnih prejemnikov storitev bolha.com v Evropski uniji, izračunih kot povprečje v obdobju zadnjih šestih mesecev je 1.274.408.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Podatek se preverja in posodablja vsakih 6 mesecev.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Informacije o kontaktnih točkah
  </p>
  <p class="termsAndConditionsContainerDownText">
    a) 11. člen Uredbe (EU) 2022/2065 (Akt o digitalnih storitvah) Kontaktna točka za organe držav članic EU, Komisijo in Odbor  
    Naslednji elektronski naslov je določen kot enotna kontaktna točka za organe držav članic EU, Komisijo in Odbor za vse zadeve, ki se nanašajo na Akt o digitalnih storitvah: podpora@bolha.com   
    Komunikacija je možna v slovenskem ali angleškem jeziku. Prosimo, da ob kontaktu navedete ime državnega organa ali institucije, v imenu katere kontaktirate z nami, in elektronski naslov, na katerega ste dosegljivi v primeru dodatnih vprašanj, v kolikor ta ni na voljo v vašem elektronskem podpisu. 
  </p>
  <p class="termsAndConditionsContainerDownText">
    b) 12. člen Uredbe (EU) 2022/2065 (Akt o digitalnih storitvah) Kontaktna točka za prejemnike storitev 
    Naslednji elektronski naslov je določen kot enotna kontaktna točka za vse zadeve prejemnikov storitev: podpora@bolha.com 
  </p>
  <p class="termsAndConditionsContainerDownText">
    c) V skladu z 21. členom Uredbe (EU) 2022/2065 (Akt o digitalnih storitvah) lahko prejemniki storitve, vključno s posamezniki ali subjekti, ki so predložili prijave, na katere so naslovljene odločitve iz člena 20(1) DSA, izberejo kateri koli organ za izvensodno reševanje sporov, ki je bil certificiran za reševanje sporov v zvezi z navedenimi odločitvami, vključno s pritožbami, ki niso bile rešene z uporabo notranjega sistema za obravnavo pritožb iz navedenega člena. Ker takšen organ v Sloveniji še ni bil vzpostavljen, vas trenutno ne moremo obvestiti, na kateri organ se lahko obrnete za izvensodno reševanje sporov. 
  </p>
  <p class="termsAndConditionsContainerDownText">
    d) Informacije o kontaktni točki na podlagi 15. člena Uredbe (EU) 2021/784 o obravnavanju razširjanja terorističnih spletnih vsebin 
    Kontaktna točka za elektronsko sprejemanje odredb o odstranitvi je podpora@bolha.com Komunikacija je možna v slovenskem ali angleškem jeziku.
  </p>
`,
}

//  <a href="https://www.mojedelo.com" target="_blank">www.mojedelo.com</a>
