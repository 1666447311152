import {FC, useEffect} from 'react'
import styles from './WelcomePage.module.scss'
import Logo from 'Assets/Images/preferenca-logo.svg'
import Title from 'Components/Title'
import {Avatar} from '@mui/material'
import {AccountCircleOutlined} from '@mui/icons-material'
import YellowButton from 'Components/YellowButton'
import {
  AuthStateType,
  EmployerRegistrationData,
  EmployerRegistrationType,
  RegistrationType,
  SeekerRegistrationData,
  SeekerStateType,
  UserWelcomeDataType,
} from 'Interfaces'
import {IdentityProviderLogin as ipLogin} from 'Services/I18n/Constants/IdentityProvider/Login'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'
import TextError from 'Components/Error/TextError'
import {useFormik} from 'formik'
import Validation from './Validation'
import {generateRedirectService} from 'Services/generateRedirectService'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {getChannel} from 'Utils/CommonHelpers'

interface WelcomePageProps {
  user?: UserWelcomeDataType | null
  registerSeeker: (data: RegistrationType) => void
  authConnectingEmployer: (data: EmployerRegistrationType) => void
  auth: AuthStateType
  seeker: SeekerStateType
}

export const WelcomePage: FC<WelcomePageProps> = ({
  user,
  registerSeeker,
  authConnectingEmployer,
  auth,
  seeker,
}) => {
  const translation = useTranslation()
  const isCandidate = location.pathname === getRoute(AppRoute.CandidateWelcome)
  const channel = getChannel()
  const channelName = channel?.name

  const formik = useFormik({
    initialValues: {
      consent1B: false,
    },
    validationSchema: Validation,
    onSubmit: () => {
      if (user?.type === 'seeker') {
        const seekerRegistrationData: SeekerRegistrationData = {
          firstName: user?.firstName,
          lastName: user?.lastName || '',
          email: user?.email,
          profilePictureUrl: user?.profilePictureUrl || '',
          identityId: user?.identityId,
          // TODO to be defined
          consent1B: true,
          consent2: true,
          consent3B: true,
        }
        registerSeeker(seekerRegistrationData)
      } else {
        const employerRegistrationData: EmployerRegistrationData = {
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          profilePictureUrl: user?.profilePictureUrl || '',
          identityId: user?.identityId || '',
          // TODO to be defined
          consent2: true,
          consent3A: true,
          phone: user?.phone,
        }
        authConnectingEmployer(employerRegistrationData)
      }
    },
  })

  const handleLoginOrRegisterClick = (
    userType: string,
    forceLogin?: boolean
  ) => {
    generateRedirectService({
      userType: userType,
      url: window.location.href,
      ...(forceLogin && {forceLogin}),
    })
  }

  useEffect(() => {
    if (!user && !auth.data) {
      handleLoginOrRegisterClick(isCandidate ? 'seeker' : 'company')
    }
  }, [])

  return (
    <div
      className={classNames(styles.welcomePageContainer, {
        [styles.notAuthorizedWelcomePageContainer]: !user?.authorized,
      })}
    >
      <Link
        to={
          isCandidate
            ? getRoute(AppRoute.HomePageCandidate)
            : getRoute(AppRoute.HomePageEmployer)
        }
      >
        <div className={styles.logo}>
          <img src={Logo} alt={channelName} />
        </div>
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <Title
          text={translation[ipLogin.welcomeMessage]}
          className={styles.title}
        />
        <p className={styles.description}>
          <FormattedMessage id={ipLogin.description} />
        </p>
        <div className={styles.userCardContainer}>
          <div className={styles.userImage}>
            {user?.profilePictureUrl ? (
              <Avatar src={user.profilePictureUrl} className={styles.avatar} />
            ) : (
              <AccountCircleOutlined />
            )}
          </div>
          <p className={styles.userName}>
            {user?.firstName} {user?.lastName}
          </p>
          <p className={styles.userEmail}>{user?.email}</p>
          <button
            className={styles.changeUserButton}
            onClick={() => {
              handleLoginOrRegisterClick(
                user?.type === 'seeker' ? 'seeker' : 'company',
                true
              )
            }}
          >
            <FormattedMessage id={ipLogin.changeUser} />
          </button>
        </div>
        {user?.authorized ? (
          <>
            <p className={styles.consentDescription}>
              <FormattedMessage id={ipLogin.privacyNoticePartOne} />
              <Link
                className={styles.link}
                to={getRoute(AppRoute.TermsAndConditions)}
              >
                <FormattedMessage id={ipLogin.termsAndConditions} />
              </Link>
              <FormattedMessage id={ipLogin.privacyNoticePartTwo} /> (
              <Link
                className={styles.link}
                to={getRoute(AppRoute.PrivacyPolicy)}
              >
                <FormattedMessage id={ipLogin.privacyPolicy} />
              </Link>
              ).
            </p>
            {(auth.error || seeker.error) && (
              <div className={styles.errorWrapper}>
                <TextError
                  text={auth.error?.message || seeker.error?.message || ''}
                />
              </div>
            )}
            <YellowButton
              text={translation[ipLogin.next]}
              theme={'darkYellow'}
              className={styles.button}
              disabled={seeker.loading}
            />
          </>
        ) : (
          <>
            <TextError text={ipLogin.unauthorizedError} />
            <div className={styles.buttonWrapper}>
              <YellowButton
                theme={'darkYellow'}
                className={styles.button}
                text="Login or register with email from invite"
                onClick={() => {
                  handleLoginOrRegisterClick(
                    isCandidate ? 'seeker' : 'company',
                    true
                  )
                }}
              />
            </div>
            <div className={styles.loginNowContainer}>
              <p>
                <FormattedMessage id={ipLogin.alreadyHaveAccount} />
              </p>
              <button
                onClick={() => {
                  handleLoginOrRegisterClick(
                    isCandidate ? 'seeker' : 'company',
                    true
                  )
                }}
              >
                <FormattedMessage id={ipLogin.loginNow} />
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  )
}
