import * as type from 'Store/types'
import {ActionType, ErrorType} from 'Interfaces'
import {DSAReportResponse, DSAStateType} from 'Interfaces/DSAType'

const initialState: DSAStateType = {
  data: {
    report: undefined,
    reportSuccess: false,
    appeal: undefined,
    appealSuccess: false,
  },
  loading: false,
  error: undefined,
}

export const DSAReducer = (
  state: DSAStateType = initialState,
  action: ActionType<DSAReportResponse | ErrorType | string | undefined>
): DSAStateType => {
  switch (action.type) {
    case type.dsaActionTypes.createReport.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.dsaActionTypes.removeReportError:
      return {
        ...state,
        error: undefined,
      }
    case type.dsaActionTypes.createReport.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          report: action.payload as DSAReportResponse,
          reportSuccess: true,
        },
        loading: false,
        error: undefined,
      }
    case type.dsaActionTypes.createReport.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.dsaActionTypes.removeReportSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          report: undefined,
          reportSuccess: false,
        },
      }
    case type.dsaActionTypes.createAppeal.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.dsaActionTypes.createAppeal.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          appeal: action.payload as DSAReportResponse,
          appealSuccess: true,
        },
        loading: false,
        error: undefined,
      }
    case type.dsaActionTypes.createAppeal.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.dsaActionTypes.removeAppealSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          appeal: undefined,
          appealSuccess: false,
        },
      }
    default:
      return state
  }
}
