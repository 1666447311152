import {Warning} from '@mui/icons-material'
import Modal, {SubmitFooter} from 'Components/Modal'
import styles from './AppealSuccessModal.module.scss'
import Title from 'Components/Title'
import {Box} from '@mui/material'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {removeAppealSuccess} from 'Store/Actions'

interface ReportModalProps {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}

// DSA - appeal success modal content
export const AppealSuccessModal = ({isOpen, setIsOpen}: ReportModalProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(removeAppealSuccess())
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        setIsOpen(false)
      }}
      title="Appeal form"
      icon={Warning}
      ariaHideApp={false}
      className={styles.reportModal}
      Footer={
        <SubmitFooter
          onSubmit={() => setIsOpen(false)}
          submitText="I understand"
          theme="modalProfileSubmit"
        />
      }
    >
      <Box className={styles.reportModalBox}>
        <Title text="We received your appeal." />
      </Box>
    </Modal>
  )
}
