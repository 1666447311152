import {CircularProgress} from '@mui/material'
import AlertComponent from 'Components/AlertComponent/AlertComponent'
import qs from 'qs'
import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute, ConsentsTranslations as ct} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import Logo from 'Assets/Images/preferenca-logo.svg'
import {Link} from 'react-router-dom'
import {UnsubscribeResponse} from 'Interfaces'
import styles from './NewsletterUnsubscribeSuccess.module.scss'
import {useTranslate} from 'Hooks'
import {generateRedirectService} from 'Services/generateRedirectService'

interface NewsletterUnsubscribeSuccessProps {
  unsubscribeNewsletter: (payload: any) => void
  newsletterSubscription: UnsubscribeResponse | null
  loading?: boolean
  error?: string
}

export const NewsletterUnsubscribeSuccess: FC<
  NewsletterUnsubscribeSuccessProps
> = ({unsubscribeNewsletter, newsletterSubscription, loading, error}) => {
  const translate = useTranslate()
  const location = useLocation()
  const {contactId, consentId, hash} = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  useEffect(() => {
    if (contactId && consentId && hash) {
      unsubscribeNewsletter({
        params: {contactId, consentId, hash},
      })
    }
  }, [contactId, consentId, hash])

  const text = `${translate(ct.successfullyUnsubscribed)} ${
    newsletterSubscription?.consent?.name
  }`

  const handleLoginOrRegisterClick = (
    userType: string,
    forceLogin?: boolean
  ) => {
    generateRedirectService({
      userType: userType,
      ...(forceLogin && {forceLogin}),
    })
  }

  return (
    <div className={styles.newsletterUnsubscribeSuccessContainer}>
      <Link to={getRoute(AppRoute.HomePageCandidate)}>
        <div className={styles.logo}>
          <img src={Logo} alt="Preferenca logo" />
        </div>
      </Link>
      <div className={styles.contentContainer}>
        {error ? (
          <div className={styles.error}>
            <AlertComponent iconName="PriorityHigh" text={error} />
          </div>
        ) : loading ? (
          <div className={styles.loaderContainer}>
            <CircularProgress className={styles.loader} size={60} />
          </div>
        ) : (
          <>
            {newsletterSubscription && (
              <>
                <AlertComponent iconName="Check" text={text} />
                {newsletterSubscription?.isSeeker && (
                  <p className={styles.description}>
                    <FormattedMessage id={ct.reviewNotificationConsents} />
                  </p>
                )}

                {newsletterSubscription?.isSeeker ? (
                  newsletterSubscription?.isAllowed ? (
                    <Link
                      to={getRoute(AppRoute.Consents)}
                      className={styles.link}
                    >
                      <FormattedMessage id={ct.title} />
                    </Link>
                  ) : (
                    <button
                      className={styles.link}
                      onClick={() => {
                        handleLoginOrRegisterClick('seeker', true)
                      }}
                    >
                      <FormattedMessage id={ct.title} />
                    </button>
                  )
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
