import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {
  AuthStateType,
  DataType,
  LanguageSettingFormikType,
  SystemLanguageType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect, useMemo} from 'react'
import styles from './LanguageSettings.module.scss'
import Dropdown from 'Components/Inputs/Dropdown'
import {useFormik} from 'formik'
import YellowButton from 'Components/YellowButton'
import {useHistory} from 'react-router-dom'
import Breadcrumbs from 'Components/Breadcrumbs'
import {FormattedMessage} from 'react-intl'
import {
  LanguageSettingsTranslations as lst,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {AppRoute} from 'Services/I18n/Constants'
import {getLang, getRoute} from 'Services/I18n/Utils'
import {SidebarLink} from 'Components/SidebarLink'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {getChannel, getIdentityProviderUrl} from 'Utils/CommonHelpers'

type LanguageSettingsProps = {
  auth: AuthStateType
  languageId?: string
  contentLanguageId?: string
  systemLanguages?: DataType<SystemLanguageType>
  getTaxonomies: () => void
  updateSystemLanguage: (id: string) => void
  updateContentLanguage: (id: string) => void
}

export const LanguageSettings: FC<LanguageSettingsProps> = ({
  auth,
  languageId,
  contentLanguageId,
  systemLanguages,
  getTaxonomies,
  updateSystemLanguage,
  updateContentLanguage,
}) => {
  const channel = getChannel()
  const identityProviderUrls = channel?.identityProviderUrls
  const lang = getLang()
  const history = useHistory()

  useEffect(() => {
    getTaxonomies()
  }, [])

  const defaultValues: LanguageSettingFormikType = {
    languageId: languageId,
    contentLanguageId: contentLanguageId,
  }

  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      if (languageId !== values.languageId) {
        updateSystemLanguage(values.languageId as string)
      }
      if (contentLanguageId !== values.contentLanguageId) {
        updateContentLanguage(values.contentLanguageId as string)
      }
    },
  })

  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const mappedLanguages = useMemo(
    () =>
      systemLanguages?.data.map((sl) => ({
        id: sl.id,
        translation: sl.name,
      })),
    [systemLanguages]
  )
  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: lst.title},
  ]

  return (
    <MainLayout
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={
            <>
              <div className={styles.linksContainer}>
                <SidebarLink
                  routeName={
                    companyUserRole
                      ? AppRoute.EmployerMyAccount
                      : AppRoute.MyAccount
                  }
                  text={st.editPersonalData}
                />
                <SidebarLink
                  text={st.changeEmailAddress}
                  route={
                    identityProviderUrls
                      ? getIdentityProviderUrl(identityProviderUrls, lang)
                      : ''
                  }
                />
                <SidebarLink
                  route={
                    identityProviderUrls
                      ? getIdentityProviderUrl(identityProviderUrls, lang)
                      : ''
                  }
                  text={st.changePassword}
                />
              </div>
            </>
          }
        />
      }
    >
      <div className={styles.pageWrap}>
        <div className={styles.mainTitle}>
          <FormattedMessage id={lst.title} />
        </div>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        </div>
        <Hr className={styles.title} />

        <Title text={lst.titleLanguage} className={styles.title} />
        <div className={styles.cardItemWrap}>
          <p className={styles.description}>
            <FormattedMessage id={lst.descriptionLanguage} />
          </p>
          <Hr />
          <Dropdown
            name="languageId"
            items={mappedLanguages || []}
            setValue={formik.setFieldValue}
            value={formik.values.languageId}
            className={styles.dropdown}
          />
        </div>

        <Title text={lst.titleContentLanguage} className={styles.title} />
        <div className={styles.cardItemWrap}>
          <p className={styles.description}>
            <FormattedMessage id={lst.descriptionContentLanguage} />
          </p>
          <Hr />
          <Dropdown
            name="contentLanguageId"
            items={mappedLanguages || []}
            setValue={formik.setFieldValue}
            value={formik.values.contentLanguageId}
            className={styles.dropdown}
          />
        </div>

        <div className={styles.ctasWrap}>
          <YellowButton
            text="Save changes"
            onClick={() => formik.submitForm()}
            className={styles.saveBtn}
          />
          <button className={styles.cancelBtn} onClick={() => history.goBack()}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </div>
    </MainLayout>
  )
}
