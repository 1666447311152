import {all} from 'redux-saga/effects'
import AuthSaga from './AuthSaga'
import OnboardingSaga from './OnboardingSaga'
import ContactDataSaga from './ContactDataSaga'
import TaxonomiesSaga from './TaxonomiesSaga'
import AssessmentsSaga from './AssessmentsSaga'
import ConsentsSaga from './ConsentsSaga'
import CompanySaga from './CompanySaga'
import CandidateSearchSaga from './CandidateSearchSaga'
import MyProjectsSaga from './MyProjectsSaga'
import EmployerSaga from './EmployerSaga'
import SeekerSaga from './SeekerSaga'
import WidgetBarSaga from './WidgetBarSaga'
import BlacklistSaga from './BlacklistSaga'
import HomepageSaga from './HomepageSaga'
import SpectoSata from './SpectoSaga'
import HomepageHelpFaqSaga from './HomepageHelpFaqSaga'
import CandidateSaga from './CandidateSaga'
import IncomingRequestsSaga from './IncomingRequestsSaga'
import MessageCenterSaga from './MessageCenterSaga'
import CandidateProfileCompletenessSaga from './CandidateProfileCompletenessSaga'
import EmployerOnboardingSaga from './EmployerOnboardingSaga'
import DSASaga from './DSASaga'

export default function* RootSaga(): Generator {
  yield all([
    AuthSaga(),
    EmployerSaga(),
    SeekerSaga(),
    OnboardingSaga(),
    EmployerOnboardingSaga(),
    ContactDataSaga(),
    DSASaga(),
    TaxonomiesSaga(),
    AssessmentsSaga(),
    ConsentsSaga(),
    CompanySaga(),
    CandidateSearchSaga(),
    MyProjectsSaga(),
    WidgetBarSaga(),
    BlacklistSaga(),
    HomepageHelpFaqSaga(),
    HomepageSaga(),
    SpectoSata(),
    CandidateSaga(),
    IncomingRequestsSaga(),
    MessageCenterSaga(),
    CandidateProfileCompletenessSaga(),
  ])
}
