import * as type from '../types'
import {
  ActionType,
  CancelInterviewType,
  DashboardSeekerEvaluationType,
  EmployerRegistrationType,
  EmployerRequestsSearchParamsType,
  ModalSendMessageEmpType,
  PersonalDataSaveType,
  ReviewCandidateType,
  sendOfferModalEmpType,
} from 'Interfaces'

export const getCanidateReviewData = (id: string): ActionType<string> => {
  return {
    type: type.employer.reviewData.get.requested,
    payload: id,
  }
}

export const addCandidateReview = (
  values: ReviewCandidateType
): ActionType<ReviewCandidateType> => {
  return {
    type: type.employer.reviewCandidate.add.save.requested,
    payload: values,
  }
}

export const editCandidateReview = (
  values: ReviewCandidateType
): ActionType<ReviewCandidateType> => {
  return {
    type: type.employer.reviewCandidate.edit.save.requested,
    payload: values,
  }
}

export const getEmployerActiveRequests = (): ActionType<undefined> => {
  return {
    type: type.employer.activeRequests.requested,
  }
}

export const getLatestEmployerRequests = () => {
  return {
    type: type.employer.allRequests.latest.requested,
  }
}

// CONTACT REQUESTS:
export const getEmployerContactRequests = (
  params?: EmployerRequestsSearchParamsType
): ActionType<EmployerRequestsSearchParamsType> => {
  return {
    type: type.employer.allRequests.contactRequests.requested,
    payload: params,
  }
}

export const loadMoreEmployerContactRequests = (
  url: string
): ActionType<string> => {
  return {
    type: type.employer.allRequests.contactRequests.loadmore.requested,
    payload: url,
  }
}

export const getSeekersFromContactRequests = (
  activeTab?: string
): ActionType<string> => {
  return {
    type: type.employer.seekersFrom.contactRequests.requested,
    payload: activeTab,
  }
}

export const getEmployerInterviews = (
  params?: EmployerRequestsSearchParamsType
): ActionType<EmployerRequestsSearchParamsType> => {
  return {
    type: type.employer.allRequests.interviewInvitations.requested,
    payload: params,
  }
}

export const loadMoreEmployerInterviews = (url: string): ActionType<string> => {
  return {
    type: type.employer.allRequests.interviewInvitations.loadmore.requested,
    payload: url,
  }
}

export const getSeekersFromInterviews = (
  activeTab?: string
): ActionType<string> => {
  return {
    type: type.employer.seekersFrom.interviewInvitations.requested,
    payload: activeTab,
  }
}

export const getEmployerPlacements = (
  params?: EmployerRequestsSearchParamsType
): ActionType<EmployerRequestsSearchParamsType> => {
  return {
    type: type.employer.allRequests.placements.requested,
    payload: params,
  }
}

export const loadMoreEmployerPlacements = (url: string): ActionType<string> => {
  return {
    type: type.employer.allRequests.placements.loadmore.requested,
    payload: url,
  }
}

export const getSeekersFromPlacements = (
  activeTab?: string
): ActionType<string> => {
  return {
    type: type.employer.seekersFrom.placements.requested,
    payload: activeTab,
  }
}

export const getEmployerOffers = (
  params?: EmployerRequestsSearchParamsType
): ActionType<EmployerRequestsSearchParamsType> => {
  return {
    type: type.employer.allRequests.offers.requested,
    payload: params,
  }
}

export const loadMoreEmployerOffers = (url: string): ActionType<string> => {
  return {
    type: type.employer.allRequests.offers.loadmore.requested,
    payload: url,
  }
}

export const getSeekersFromOffers = (
  activeTab?: string
): ActionType<string> => {
  return {
    type: type.employer.seekersFrom.offers.requested,
    payload: activeTab,
  }
}

export const addDashboardSeekerEvaluation = (
  seekerEvaluation: DashboardSeekerEvaluationType
): ActionType<DashboardSeekerEvaluationType> => {
  return {
    type: type.employer.allRequests.rateCandidate.add.requested,
    payload: seekerEvaluation,
  }
}

export const editDashboardSeekerEvaluation = (
  seekerEvaluation: DashboardSeekerEvaluationType
): ActionType<DashboardSeekerEvaluationType> => {
  return {
    type: type.employer.allRequests.rateCandidate.edit.requested,
    payload: seekerEvaluation,
  }
}

export const interviewInvitationsReject = (
  payload: string
): ActionType<string> => {
  return {
    type: type.employer.allRequests.interviewInvitations
      .interviewInvitationsReject.requested,
    payload: payload,
  }
}
export const interviewInvitationsApprove = (
  payload: string
): ActionType<string> => {
  return {
    type: type.employer.allRequests.interviewInvitations
      .interviewInvitationsAccept.requested,
    payload: payload,
  }
}

export const interviewInvitationsCancel = (
  payload: CancelInterviewType
): ActionType<CancelInterviewType> => {
  return {
    type: type.employer.allRequests.interviewInvitations
      .interviewInvitationsCancel.requested,
    payload,
  }
}

export const confirmPlacementEmp = (payload: string): ActionType<string> => {
  return {
    type: type.employer.allRequests.placements.confirmed.requested,
    payload: payload,
  }
}

export const rejectPlacementEmp = (payload: string): ActionType<string> => {
  return {
    type: type.employer.allRequests.placements.rejected.requested,
    payload: payload,
  }
}

export const getEmployerPlacementConfirmedOffers = () => {
  return {
    type: type.employer.allRequests.placementConfirmedOffers.requested,
  }
}

export const sendMessageEmp = (
  payload: ModalSendMessageEmpType
): ActionType<ModalSendMessageEmpType> => {
  return {
    type: type.employer.allRequests.sendMessage.requested,
    payload: payload,
  }
}

export const sendOfferEmp = (
  payload: sendOfferModalEmpType
): ActionType<sendOfferModalEmpType> => {
  return {
    type: type.employer.allRequests.offers.sendOffer.requested,
    payload: payload,
  }
}

export const getEmployerPersonalData = (): ActionType<undefined> => {
  return {
    type: type.employer.personalData.get.requested,
  }
}

export const saveEmployerPersonalData = (
  value: PersonalDataSaveType
): ActionType<PersonalDataSaveType> => {
  return {
    type: type.employer.personalData.save.requested,
    payload: value,
  }
}

export const clearCandidateRequests = (): ActionType<undefined> => {
  return {
    type: type.employer.clearCandidateRequests.requested,
  }
}

export const authConnectingEmployer = (
  user: EmployerRegistrationType
): ActionType<EmployerRegistrationType> => {
  return {
    type: type.employer.authConnectingEmployer.requested,
    payload: {
      ...user,
    },
  }
}
