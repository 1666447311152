import {GenerateRedirectServiceType, RedirectServiceResponse} from 'Interfaces'
import {instance} from './API/API'
import {getRoute} from './I18n/Utils'
import {AppRoute} from './I18n/Constants'
import {history} from 'Store/history'

export const generateRedirectService = ({
  userType,
  url,
  forceLogin,
}: GenerateRedirectServiceType) => {
  return instance
    .post<RedirectServiceResponse>(
      `/auth/rjb/${userType}/generate-redirect-url`,
      {
        url,
        ...(forceLogin && {forceLogin}),
      }
    )
    .then((response) => {
      const redirectUrl = response.data.data?.redirectUrl
      window.location.href = redirectUrl
    })
    .catch(() => {
      history.push(getRoute(AppRoute.Error))
    })
}
