import {Warning} from '@mui/icons-material'
import Hr from 'Components/Hr'
import TextField from 'Components/Inputs/TextField'
import Modal, {SubmitFooter} from 'Components/Modal'
import {useFormik} from 'formik'
import styles from './ReportModal.module.scss'
import Title from 'Components/Title'
import Dropdown from 'Components/Inputs/Dropdown'
import {reportReasonsData} from './reportReasons'
import Checkbox from 'Components/Inputs/Checkbox'
import {FormattedMessage} from 'react-intl'
import TextArea from 'Components/Inputs/TextArea'
import {OnChangeProps} from 'Utils/FormikProps'
import {Box} from '@mui/material'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {
  DSAReportLocationsType,
  DSAReportPayload,
  DSAReportReasonsType,
  DSAStateType,
  ReportLocationId,
} from 'Interfaces/DSAType'
import * as Yup from 'yup'
import clsx from 'clsx'
import {DSA_REPORT_LOCATIONS_MESSAGES} from 'Interfaces/DSAType'
import {useEffect, useState} from 'react'
import {Translation} from 'Components/Translation'
import {executeRecaptcha, getChannel} from 'Utils/CommonHelpers'
import {ReportSuccessModal} from 'Components/ReportSuccessModal/ReportSuccessModal'
import TextError from 'Components/Error/TextError'
import {useDynamicTranslation} from 'Hooks'

const Validation = Yup.object().shape({
  reportReason: Yup.string().required('Report reason is required'),
  reportReasonOther: Yup.string().when('reportReason', {
    is: 'other',
    then: Yup.string().required('Please provide additional information'),
    otherwise: Yup.string(),
  }),
  reporter: Yup.string().required('Reporter is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
})

interface ReportFormType {
  reportReason: DSAReportReasonsType
  reportReasonOther: string
  reporter: string
  email: string
  agreeWithTerms: false
}

interface ReportModalProps {
  location: DSAReportLocationsType
  setIsOpen: (value: boolean) => void
  isOpen: boolean
  dsa: DSAStateType
  createReport: (payload: DSAReportPayload) => void
  id: string
  screenShotPictureId: string
  removeReportError: () => void
}

// DSA - report modal content
export const ReportModal = ({
  isOpen,
  setIsOpen,
  createReport,
  location,
  dsa,
  id,
  screenShotPictureId,
  removeReportError,
}: ReportModalProps) => {
  const translate = useDynamicTranslation()

  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const [reportModalSuccess, setReportModalSuccess] = useState(false)

  const getLocationId = () => {
    let reportLocation: ReportLocationId = {} as ReportLocationId

    switch (location) {
      case DSA_REPORT_LOCATIONS_MESSAGES.JOB_AD:
        reportLocation.jobId = id
        break
      case DSA_REPORT_LOCATIONS_MESSAGES.SEEKER_PROFILE:
        reportLocation.seekerId = id
        break
      case DSA_REPORT_LOCATIONS_MESSAGES.COMPANY_PROFILE:
        reportLocation.companyId = id
        break
      case DSA_REPORT_LOCATIONS_MESSAGES.JOB_OFFER:
        reportLocation.companySeekerJourneyOfferRequestId = id
        break
      case DSA_REPORT_LOCATIONS_MESSAGES.INTERVIEW_INVITATION:
      case DSA_REPORT_LOCATIONS_MESSAGES.INTERVIEW_CANCELATION:
        reportLocation.companySeekerJourneyContactRequestId = id
        break
    }
    return reportLocation
  }

  const formik = useFormik<ReportFormType>({
    initialValues: {
      reportReason: '',
      reportReasonOther: '',
      reporter: '',
      email: '',
      agreeWithTerms: false,
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      const {agreeWithTerms, ...data} = values

      const payload: DSAReportPayload = {
        ...data,
        reportLocation: {
          location,
          ...getLocationId(),
        },
        screenShotPictureId,
      }
      createReport(payload)
      formik.resetForm()
    },
  })

  useEffect(() => {
    if (!!dsa.data.reportSuccess) {
      setIsOpen(false)
      setReportModalSuccess(true)
    }
  }, [dsa.data.reportSuccess])

  useEffect(() => {
    const loadRecaptchaToken = () => {
      if (typeof window !== 'undefined' && window.grecaptcha) {
        executeRecaptcha()
          .then((token) => {
            setRecaptchaToken(token) // Store the token in the state
          })
          .catch((error) => {
            console.error('Error getting reCAPTCHA token:', error)
          })
      } else {
        const intervalId = setInterval(() => {
          if (window.grecaptcha) {
            clearInterval(intervalId)
            executeRecaptcha()
              .then((token) => {
                setRecaptchaToken(token) // Store the token in the state
              })
              .catch((error) => {
                console.error('Error getting reCAPTCHA token:', error)
              })
          }
        }, 1000)
      }
    }

    loadRecaptchaToken()
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterClose={() => {
          removeReportError()
          setIsOpen(false)
        }}
        title="Report"
        icon={Warning}
        ariaHideApp={false}
        className={styles.reportModal}
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setIsOpen(false)
            }}
            disabled={!recaptchaToken}
            submitText="Report content"
            theme="modalProfileSubmit"
            bottomText={
              <p className={styles.reportBottomText}>
                <Translation
                  text={translate(
                    'The data you provide will be processed by {platformName} for the purpose of processing your application under Article 6 (1)c (Digital Services Act). You can read more about the processing of personal data',
                    {platformName: getChannel().formalName || ''}
                  )}
                />
                &nbsp;
                <Link
                  to={getRoute(AppRoute.PrivacyPolicy)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  here
                </Link>
                .
              </p>
            }
          />
        }
      >
        <Box className={styles.reportModalBox}>
          <Title text="Reason for reporting the content" />
          <Dropdown
            emptyValueLabel="Enter reason for reporting the content."
            name="reportReason"
            value={formik.values.reportReason}
            items={reportReasonsData}
            className={styles.radiusInput}
            setValue={formik.setFieldValue}
            theme="white"
            error={
              formik.touched.reportReason && formik.errors.reportReason
                ? formik.errors?.reportReason
                : undefined
            }
          />

          {reportReasonsData.map(
            (item) =>
              item.id === formik.values.reportReason && (
                <p key={item.id} className={styles.descriptionText}>
                  {item.description}
                </p>
              )
          )}

          {formik.values.reportReason === 'other' && (
            <TextArea
              name="reportReasonOther"
              placeholderText="Enter reason for reporting the content."
              {...OnChangeProps(formik, 'reportReasonOther')}
              theme="white"
              className={clsx(styles.textArea, {
                [styles.error]:
                  formik.submitCount > 0 && formik.errors?.reportReasonOther,
              })}
              error={
                formik.touched.reportReasonOther &&
                formik.errors.reportReasonOther
                  ? formik.errors?.reportReasonOther
                  : undefined
              }
              rows={4}
            />
          )}

          <Hr theme="modal" />
          <Title text="FULL NAME OR COMPANY NAME" />
          <TextField
            name="reporter"
            placeholder="Enter your full name or company name"
            value={formik.values.reporter}
            onChange={formik.handleChange}
            error={
              formik.touched.reporter && formik.errors.reporter
                ? formik.errors.reporter
                : undefined
            }
          />

          <Hr theme="modal" />
          <Title text="EMAIL" />
          <TextField
            name="email"
            placeholder="Enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : undefined
            }
          />
          <Hr theme="modal" />
          <Checkbox
            name="agreeWithTerms"
            value={formik.values.agreeWithTerms}
            onChange={() => {
              formik.setFieldValue(
                'agreeWithTerms',
                !formik.values.agreeWithTerms
              )
            }}
            error={
              formik.submitCount > 0 && !formik.values.agreeWithTerms
                ? 'You must confirm'
                : undefined
            }
          >
            <FormattedMessage
              id={
                'I confirm that I am making the report voluntarily and that the information and statements contained in the report are accurate and complete.'
              }
            />
          </Checkbox>
          {dsa.error && (
            <TextError text={dsa.error.message || 'Something went wrong'} />
          )}
        </Box>
      </Modal>
      {dsa.data.report?.id && (
        <ReportSuccessModal
          isOpen={reportModalSuccess}
          setIsOpen={(value: boolean) => setReportModalSuccess(value)}
          dsaReportId={dsa.data.report.id}
        />
      )}
    </>
  )
}
