import {CHANNELS, IChannel} from 'ChannelConfig'
import qs from 'qs'
import {reduce, isEqual} from 'lodash'
import {
  CompanyUserRole,
  IdentityProviderUrlType,
  File as FileType,
} from 'Interfaces'
import {getRouteLanguageId} from 'Services/I18n/Utils'
import axios from 'axios'
import {baseURL} from 'Services/API/API'
import html2canvas from 'html2canvas'

export const getChannel = (): IChannel => {
  return (
    CHANNELS.find((channel) => channel.host === window.location.hostname) ||
    CHANNELS[5]
  )
}

export const generateURL = (
  baseApiUrl: string,
  params: Record<string, any> | undefined | {},
  startFrom: number = 0,
  pageSize: number = 20
) => baseApiUrl + '?' + qs.stringify({pageSize, startFrom, ...params})

export const diffProps = (a: any, b: any) => {
  return reduce(
    a,
    function (result, value, key) {
      //@ts-ignore
      return isEqual(value, b[key]) ? result : result.concat(key)
    },
    []
  )
}

export const isEmployerUser = (type: string): boolean => {
  return Object.values(CompanyUserRole).includes(type as CompanyUserRole)
}
export const isSeekerUser = (type: string): boolean => {
  return (
    type === 'seeker' || (type as CompanyUserRole) === CompanyUserRole.RECRUITER
  )
}
export const webSiteUrlFormatter = (website: string) => {
  return website.indexOf('http') === 0 ? website : `http://${website}`
}

export const getIdentityProviderUrl = (
  urls: IdentityProviderUrlType[],
  langShortcode: string
) => {
  const result = urls.find((item) => item.langShortcode === langShortcode)
  return result ? result.url : ''
}

export const takeScreenshot = async (
  refElement: HTMLDivElement,
  fileName: string = 'report-screenshot.png'
) => {
  return html2canvas(refElement).then((canvas) => {
    return new Promise<string>((resolve, reject) => {
      canvas.toBlob(async (blob) => {
        if (blob) {
          const fileOfBlob = new File([blob], fileName)

          try {
            const response: {data: FileType} = (await uploadFile(
              fileOfBlob
            )) as {
              data: FileType
            }
            if (response?.data?.id) {
              resolve(response.data.id)
            } else {
              resolve('')
            }
          } catch (error) {
            reject(error)
          }
        } else {
          resolve('')
        }
      }, 'image/png')
    })
  })
}

export const uploadFile = async (file: Blob) => {
  const languageId =
    getRouteLanguageId() || getChannel().defaultLanguage?.id || ''
  const userId = localStorage.getItem('userId') || ''

  const formData = new FormData()
  formData.append('file', file as unknown as string)

  try {
    const response = await axios.post('/upload-file/picture', formData, {
      headers: {
        'content-type': 'multipart/form-data',
        tenantId: getChannel().tenantId as string,
        channelId: getChannel().channelId as string,
        languageId,
        userId,
      },
      baseURL: baseURL,
      withCredentials: true,
    })
    return response.data // Ensure the data is returned
  } catch (error) {
    return error // Throw the error so it can be handled in the calling function
  }
}

export const executeRecaptcha = (): Promise<string | any> => {
  const channel = getChannel()
  const recaptchaSiteKey = channel.googleRecaptchaKey

  if (!recaptchaSiteKey) {
    console.error('RECAPTCHA_SITE_KEY is not defined in the environment.')
    return Promise.reject(
      'RECAPTCHA_SITE_KEY is not defined in the environment.'
    )
  }

  return new Promise((resolve, reject) => {
    if (typeof window !== 'undefined' && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaSiteKey, {action: 'submit'})
          .then((token: string) => {
            resolve(token) // Resolve the promise with the token
          })
          .catch((error: any) => {
            reject(error) // Reject the promise if an error occurs
          })
      })
    } else {
      reject('grecaptcha not loaded')
    }
  })
}
