import {FC, MouseEventHandler, ReactNode, useMemo} from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import classnames from 'classnames'

import styles from './SidebarLink.module.scss'
import {getRoute, useActiveRoute} from 'Services/I18n/Utils'
import {useTranslation} from '../../Hooks'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {NavHashLink} from 'react-router-hash-link'

interface iSidebarLinkProps {
  routeName?: string
  onClick?: MouseEventHandler
  text: string
  icon?: ReactNode
  hash?: boolean
  route?: string
  withMarginBottom?: boolean
}

interface ContentType {
  icon: ReactNode
  text: string
}

const Content: FC<ContentType> = ({icon, text}) => (
  <div className={styles.contentWrapper}>
    <div className={styles.iconTextWrapper}>
      {icon}
      <p>
        <FormattedMessage id={text} />
      </p>
    </div>
    <ArrowForwardIcon className={styles.arrowIcon} />
  </div>
)

export const SidebarLink = ({
  routeName,
  text,
  onClick,
  icon,
  hash,
  route,
  withMarginBottom = true,
}: iSidebarLinkProps) => {
  const isExternalUrl = useMemo(() => {
    const currentRoute = route || getRoute(routeName || '#')
    return currentRoute.startsWith('http') || currentRoute.startsWith('https')
  }, [route, routeName])

  const translation = useTranslation()
  const {isActive} = useActiveRoute(route ? route : getRoute(routeName || '#'))

  const ContentWrapper = useMemo(() => {
    if (isExternalUrl) {
      return ({children}: {children: ReactNode}) => (
        <a
          href={route}
          className={classnames(styles.sidebarLink, {
            [styles.withMarginBottom]: withMarginBottom,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      )
    }

    if (routeName || route) {
      return !hash
        ? ({children}: {children: ReactNode}) => (
            <Link
              to={route ? route : getRoute(routeName || '#')}
              className={classnames(styles.sidebarLink, {
                [styles.active]: isActive,
                [styles.withMarginBottom]: withMarginBottom,
              })}
            >
              {children}
            </Link>
          )
        : ({children}: {children: ReactNode}) => (
            <NavHashLink
              to={route ? route : ''}
              className={classnames(styles.sidebarLink, {
                [styles.active]: isActive,
                [styles.withMarginBottom]: withMarginBottom,
              })}
              smooth
            >
              {children}
            </NavHashLink>
          )
    }

    return null
  }, [routeName, route, hash, isActive, withMarginBottom])

  return (
    <>
      {ContentWrapper && (
        <ContentWrapper>
          <Content text={text} icon={icon} />
        </ContentWrapper>
      )}
      {onClick && (
        <button
          type={'button'}
          onClick={onClick}
          className={classnames(styles.sidebarLink, {
            [styles.withMarginBottom]: withMarginBottom,
          })}
        >
          <Content icon={icon} text={text} />
        </button>
      )}
      {!routeName && !route && !onClick && (
        <div className={styles.sidebarLink}>
          <div className={styles.linkWrap}>
            <span className={styles.linkText}>{translation[text] || text}</span>
            <ArrowForwardIcon className={styles.arrowIcon} />
          </div>
        </div>
      )}
    </>
  )
}
