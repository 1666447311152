import {
  FC,
  ReactNode,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import classnames from 'classnames'
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material'

import styles from './ShowMoreContent.module.scss'
import Hr from 'Components/Hr'
import {useWindowResize} from 'Hooks'
import {CompanyFaqTranslations as cft} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

interface ShowMoreContentProps {
  height?: number
  mobileHeight?: number
  maxHeight?: number
  className?: string
  showAll?: boolean
  childRef?: MutableRefObject<any>
  HiddenComponent?: ReactNode
  theme?: string
  horizontalLine?: boolean
  noMargin?: boolean
  forceChildHeight?: boolean
  withMargins?: boolean
}

export const ShowMoreContent: FC<ShowMoreContentProps> = ({
  children,
  height = 88,
  maxHeight = 99999,
  mobileHeight = 220,
  className,
  showAll = false,
  childRef = {},
  HiddenComponent = null,
  theme = 'white',
  horizontalLine = true,
  noMargin = false,
  forceChildHeight = false,
  withMargins = false,
}) => {
  const [showMore, setShowMore] = useState(showAll)
  const ref = useRef<HTMLDivElement>(null)

  const getChildHeight = (current: any) => {
    if (!current) return 0
    return (
      Math.floor(
        withMargins
          ? parseInt(
              window.getComputedStyle(current).getPropertyValue('margin-top')
            ) +
              parseInt(
                window
                  .getComputedStyle(current)
                  .getPropertyValue('margin-bottom')
              ) +
              current.offsetHeight
          : current.clientHeight
      ) || 0
    )
  }

  const calculateLocalHeight = (clientWidth: number): number => {
    if (childRef.current) {
      const childRealHeight = getChildHeight(childRef.current)
      if (forceChildHeight) {
        return childRealHeight
      }
      return clientWidth < 768
        ? (mobileHeight || height) < childRealHeight
          ? mobileHeight || height
          : childRealHeight
        : height < childRealHeight
        ? height
        : childRealHeight
    }
    return clientWidth < 768 ? mobileHeight || height : height
  }

  const [localHeight, setLocalHeight] = useState(
    calculateLocalHeight(document.body.clientWidth)
  )
  const [contentHeight, setContentHeight] = useState(
    getChildHeight(ref.current) || 0
  )

  const changeHeight = (clientWidth?: number) => {
    setLocalHeight(calculateLocalHeight(clientWidth || 0))
  }

  useEffect(() => {
    changeHeight(document.body.clientWidth)
  }, [childRef])

  useWindowResize(changeHeight)

  useEffect(() => {
    setContentHeight(ref.current?.clientHeight || 0)
  }, [children, ref.current?.clientHeight])

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setContentHeight(getChildHeight(ref.current) || 0)
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return (
    <div
      className={classnames(
        styles.container,
        className,
        styles[theme] ? styles[theme] : ''
      )}
    >
      <div
        className={classnames(
          styles.children,
          styles[theme] ? styles[theme] : ''
        )}
        style={{
          maxHeight: showMore
            ? HiddenComponent
              ? maxHeight
              : contentHeight
            : localHeight,
        }}
      >
        <div ref={ref}>{children}</div>
        {HiddenComponent}
      </div>
      {!showAll && (contentHeight > localHeight || HiddenComponent) ? (
        <>
          {horizontalLine && (
            <Hr
              className={classnames(
                styles.hr,
                styles[theme] ? styles[theme] : ''
              )}
            />
          )}

          <button
            className={classnames(styles.button, {[styles.noMargin]: noMargin})}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                <ArrowDropUp />
                <span
                  className={classnames(styles.span, {
                    [styles.noMargin]: noMargin,
                  })}
                >
                  <FormattedMessage id={cft.lessText} />
                  {theme === 'orange' && ' ...'}
                </span>
              </>
            ) : (
              <>
                <ArrowDropDown />
                <span
                  className={classnames(styles.span, {
                    [styles.noMargin]: noMargin,
                  })}
                >
                  <FormattedMessage id={cft.moreText} />
                  {theme === 'orange' && ' ...'}
                </span>
              </>
            )}
          </button>
        </>
      ) : null}
    </div>
  )
}
