import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {createReport, removeReportError} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {DSAReportPayload} from 'Interfaces/DSAType'
import {ReportModal} from './ReportModal'

const mapStateToProps = (state: RootState) => ({
  dsa: state.dsa,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createReport: (payload: DSAReportPayload) => dispatch(createReport(payload)),
  removeReportError: () => dispatch(removeReportError()),
})

export const ReportModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportModal)
