import {call, put, takeLatest, all, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import API from 'Services/API'
import {AppRoute} from 'Services/I18n/Constants'
import * as type from 'Store/types'
import {
  ActionType,
  CompanyContactInfoSaveType,
  HiringPlanSaveType,
  SavePartialHiringPlanType,
  EmployerOnboardingResponseType,
  EmployerOnboardingType,
  MeType,
  EmployerOnboardingStatus,
} from 'Interfaces'
import {getRoute} from 'Services/I18n/Utils'
import {fetchTaxonomy} from './TaxonomiesSaga'

const getEmployerOnboardingStep = (
  onboardingStatus: EmployerOnboardingStatus
) => {
  if (onboardingStatus === EmployerOnboardingStatus.COMPANYANDCONTACTINFO) {
    return 1
  } else if (
    onboardingStatus === EmployerOnboardingStatus.TWELVEMONTHHIRINGPLAN
  ) {
    return 2
  } else if (onboardingStatus === EmployerOnboardingStatus.DONE) {
    return 4
  } else return 0
}

const repackResponse = (
  response: EmployerOnboardingResponseType
): EmployerOnboardingType => {
  const {
    companyHiringPlanJobCategories,
    companyHiringPlanRegions,
    companyHiringPlanCandidatesEducation,
    companyHiringPlanYearsOfExperiencePerPosition,
    ...rest
  } = response
  return {
    ...rest,
    educationOfCandidates: companyHiringPlanCandidatesEducation,
    yearsOfExperiencePerPosition: companyHiringPlanYearsOfExperiencePerPosition,
    regions: companyHiringPlanRegions
      ? companyHiringPlanRegions.map((r) => r.region)
      : [],
    jobCategories: companyHiringPlanJobCategories
      ? companyHiringPlanJobCategories.map((j) => j.jobCategory)
      : [],
  }
}

function* getEmployerOnboarding(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company/onboarding/${action.payload}`
  )
  if (response) {
    if (response.data.accountStatus === 'inactive') {
      yield put({
        type: type.employerOnboarding.getEmployerOnboarding.succeeded,
        payload: {
          ...repackResponse(response.data),
          onboardingStep: getEmployerOnboardingStep(
            response.data.onboardingStatus
          ),
        },
      })
    } else {
      yield put(push(getRoute(AppRoute.EmployerDashboard)))
    }
  } else
    yield put({
      type: type.employerOnboarding.getEmployerOnboarding.failed,
      payload: error,
    })
}
function* saveStepCompanyContactInfo(
  action: ActionType<CompanyContactInfoSaveType>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/onboarding/save-step-company-and-contact-info/${companyId}`,
    {...action.payload}
  )
  if (response) {
    yield put({
      type: type.employerOnboarding.stepCompanyContactInfo.succeeded,
      payload: {...repackResponse(response.data), onboardingStep: 2},
    })
    yield put({
      type: type.towns.requested,
    })
  } else
    yield put({
      type: type.employerOnboarding.stepCompanyContactInfo.failed,
      payload: error,
    })
}

function* saveStepHiringPlan(
  action: ActionType<{values: HiringPlanSaveType; step: number}>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/onboarding/save-step-twelve-month-hiring-plan/${companyId}`,
    {...action.payload?.values}
  )
  if (response) {
    yield put({
      type: type.employerOnboarding.stepHiringPlan.succeeded,
      payload: {
        ...repackResponse(response.data),
      },
    })
    yield call(fetchTaxonomy, {type: type.occupations.requested})
    yield put(push(getRoute(AppRoute.EmployerThankYou)))
  } else
    yield put({
      type: type.employerOnboarding.stepHiringPlan.failed,
      payload: error,
    })
}

function* saveStepPartialHiringPlan(
  action: ActionType<SavePartialHiringPlanType>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/onboarding/save-partial-step-twelve-month-hiring-plan/${companyId}`,
    {...action.payload}
  )
  if (response) {
    yield put({
      type: type.employerOnboarding.stepPartialHiringPlan.succeeded,
      payload: {...repackResponse(response.data), onboardingStep: 1},
    })
    yield call(fetchTaxonomy, {type: type.occupations.requested})
  } else
    yield put({
      type: type.employerOnboarding.stepPartialHiringPlan.failed,
      payload: error,
    })
}

export default function* EmployerOnboardingSaga(): Generator {
  yield all([
    takeLatest(
      type.employerOnboarding.getEmployerOnboarding.requested,
      getEmployerOnboarding
    ),
    takeLatest(
      type.employerOnboarding.stepCompanyContactInfo.requested,
      saveStepCompanyContactInfo
    ),
    takeLatest(
      type.employerOnboarding.stepHiringPlan.requested,
      saveStepHiringPlan
    ),
    takeLatest(
      type.employerOnboarding.stepPartialHiringPlan.requested,
      saveStepPartialHiringPlan
    ),
  ])
}
