import {FC, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import classnames from 'classnames'

import {
  AuthStateType,
  Candidate,
  EmployerCandidateProfileStateType,
  IncomingRequestSeekerType,
  PublicSeekerProfileType,
  RjbProfileStatus,
  SeekerProfileType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import publicStyles from 'Pages/Seeker/PublicProfile/PublicProfile.module.scss'
import {PublicProfileBioSection} from 'Components/Profile/PublicProfileBioSection'
import {PublicJobPreferences} from 'Components/Profile/PublicJobPreferences'
import {PublicWorkExperiences} from 'Components/Profile/PublicWorkExperiences'
import {Skills} from 'Components/Profile/Skills'
import MainLayout from 'Layouts/MainLayout'
import privateStyles from 'Pages/Seeker/Profile/Profile.module.scss'
import {AboutMe} from 'Components/Profile/AboutMe'
import {JobPreferences} from 'Components/Profile/JobPreferences'
import {WorkExperiences} from 'Components/Profile/WorkExperiences'
import {EducationEndDate} from 'Components/Profile/Education'
import {Languages} from 'Components/Profile/Languages'
import {DrivingLicences} from 'Components/Profile/DrivingLicences'
import {CertificationsEndDate} from 'Components/Profile/Certifications'
import {TrainingTitles} from 'Components/Profile/TrainingTitles/TrainingTitles'
import {ProjectsEndDate} from 'Components/Profile/Projects/ProjectsEndDate'
import {AwardsEndDate} from 'Components/Profile/Awards/AwardsEndDate'
import {Attachments} from 'Components/Profile/Attachments'
import Hr from 'Components/Hr'
import YellowButton from 'Components/YellowButton'
import {ProfileTranslations as pt} from 'Services/I18n/Constants/Seeker/ProfileTranslations'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import employerStyles from './EmployerProfile.module.scss'
import styles from './EmployerProfile.module.scss'
import {useIsEmployer} from 'Hooks/useIsEmployer'
import CandidateSearchSidebarContentContainer from 'Components/CandidateSearch/CandidateSearchSidebarContent'
import {takeScreenshot} from 'Utils/CommonHelpers'
import {ReportModalContainer} from 'Components/ReportModal'
import {DSA_REPORT_LOCATIONS_MESSAGES} from 'Interfaces/DSAType'

interface SeekerProfileProps {
  candidate: EmployerCandidateProfileStateType
  getCandidateProfile: (id: string) => void
  taxonomies: TaxonomiesStateType
  contactCandidate: (id: string) => void
  auth: AuthStateType
  clearCandidateProfile: () => void
  setCandidateBasicData: (
    candidate: SeekerProfileType | PublicSeekerProfileType | null
  ) => void
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  setSidebarClosed: (sidebarClosed: boolean) => void
  getDrivingLicenseCategories: () => void
}

export const EmployerCandidateProfile: FC<SeekerProfileProps> = ({
  candidate,
  getCandidateProfile,
  taxonomies,
  contactCandidate,
  auth,
  clearCandidateProfile,
  setCandidateBasicData,
  candidateBasicData,
  setSidebarClosed,
  getDrivingLicenseCategories,
}) => {
  useEffect(() => {
    setCandidateBasicData(null)
    setSidebarClosed(true)
  }, [])
  useEffect(() => {
    if (candidate.data.profile) {
      setCandidateBasicData(candidate.data.profile)
    }
  }, [candidate.data.profile])
  const noLongerActiveCandidate =
    !candidate?.data?.profile?.hasFinishedPlacementWithCurrentEmployer &&
    candidate?.data?.profile?.rjbProfileStatus === RjbProfileStatus.CLOSED

  let profile = candidate?.data?.profile
  let imageOrInitials =
    profile?.profilePicture === null
      ? `${profile?.firstName?.charAt(0)} ${profile?.lastName?.charAt(0)}`
      : profile?.profilePicture

  let {id} = useParams<{id: string}>()
  useEffect(() => {
    getCandidateProfile(id)
  }, [getCandidateProfile, id])

  const history = useHistory()
  const publicRoute = getRoute(AppRoute.EmployerCandidateProfilePublic).replace(
    /\/\:id/,
    ''
  )
  const fullRoute = getRoute(AppRoute.EmployerCandidateProfileFull).replace(
    /\/\:id/,
    ''
  )

  useEffect(() => {
    if (
      candidate.data.type === 'public' &&
      history.location.pathname.startsWith(fullRoute)
    ) {
      history.push(
        `${publicRoute}${history.location.pathname.replace(fullRoute, '')}`
      )
    }
    if (
      candidate.data.type === 'full' &&
      history.location.pathname.startsWith(publicRoute)
    ) {
      history.push(
        `${fullRoute}${history.location.pathname.replace(publicRoute, '')}`
      )
    }
  }, [candidate.data.type])

  useEffect(() => {
    return () => {
      clearCandidateProfile()
    }
  }, [])

  const isPublic =
    candidate.data.type === 'public' &&
    history.location.pathname.startsWith(publicRoute)
  const isFull =
    candidate.data.type === 'full' &&
    history.location.pathname.startsWith(fullRoute)
  const isEmployer = useIsEmployer(auth)

  const captureRef = useRef<HTMLDivElement>(null)

  const [reportModal, setReportModal] = useState(false)
  const [screenShotPictureId, setScreenShotPictureId] = useState('')

  const getScreenshot = async () => {
    if (captureRef.current) {
      try {
        const response = await takeScreenshot(
          captureRef.current,
          'report-candidate-screenshot.png'
        )
        if (!response) {
          return
        }
        setScreenShotPictureId(response)
        setReportModal(true)
      } catch (error) {
        console.error('Error taking screenshot:', error)
      }
    }
  }

  const setSidebarContent = () => {
    if (isFull) {
      return (
        <>
          <CandidateSearchSidebarContentContainer
            isFull
            isFromCandidateProfile
            hasContactRequest={
              candidate.data.contactRequestExists ||
              candidate.data.type === 'full'
            }
            onReportHandler={getScreenshot}
          />
        </>
      )
    }
    return (
      <div className={styles.sidebarContent}>
        <CandidateSearchSidebarContentContainer
          isFromCandidateProfile
          hasContactRequest={
            candidate.data.contactRequestExists ||
            candidate.data.type === 'full'
          }
          onReportHandler={getScreenshot}
        />
      </div>
    )
  }

  return (
    <MainLayout
      fixedRight={false}
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={candidateBasicData && setSidebarContent()}
        />
      }
      theme="logoFixedRight"
    >
      <div ref={captureRef}>
        {isPublic ? (
          <div
            className={classnames(
              publicStyles.profileContainer,
              privateStyles.profileContainer
            )}
          >
            {candidate.data.profile && (
              <div>
                <PublicProfileBioSection
                  getTaxonomies={() => {}}
                  user={candidate.data.profile as PublicSeekerProfileType}
                  profile={candidate as SeekerStateType}
                  taxonomies={taxonomies}
                  updatePublicBioData={() => {}}
                />
                <PublicJobPreferences
                  profile={candidate.data.profile as PublicSeekerProfileType}
                  seeker={candidate as SeekerStateType}
                  taxonomies={taxonomies}
                  getTaxonomies={() => {}}
                  updatePublicJobPreferences={() => {}}
                />
                <PublicWorkExperiences
                  profile={candidate as SeekerStateType}
                  workExperiences={
                    (candidate.data.profile as PublicSeekerProfileType)
                      .workExperiences
                  }
                  totalWorkExperience={
                    (candidate.data.profile as PublicSeekerProfileType)
                      .totalWorkExperience
                  }
                  taxonomies={taxonomies}
                  updatePublicWorkExperience={() => {}}
                  addPublicWorkExperience={() => {}}
                  getTaxonomies={() => {}}
                />
                <Skills
                  profile={candidate as SeekerStateType}
                  skills={
                    (candidate.data.profile as PublicSeekerProfileType).skills
                  }
                  taxonomies={taxonomies}
                  addSkill={() => {}}
                  editSkill={() => {}}
                  deleteSkill={() => {}}
                  getTaxonomies={() => {}}
                />
                <div className={employerStyles.confirmWrap}>
                  <div className={privateStyles.button}>
                    <YellowButton
                      text={
                        candidate.data.contactRequestExists
                          ? pt.contactSentCandidate
                          : pt.contactCandidate
                      }
                      disabled={
                        candidate.data.contactRequestExists ||
                        noLongerActiveCandidate
                      }
                      onClick={() => {
                        contactCandidate(id)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : isFull ? (
          <div className={privateStyles.profileContainer}>
            <AboutMe
              profile={candidate as SeekerStateType}
              user={candidate.data.profile as SeekerProfileType}
              taxonomies={taxonomies}
              getJobStatuses={() => {}}
              updateBioData={() => {}}
              imageOrInitials={imageOrInitials}
              isEmployer={isEmployer}
            />
            <JobPreferences
              seeker={candidate as SeekerStateType}
              profile={candidate.data.profile as SeekerProfileType}
              taxonomies={taxonomies}
              getTaxonomies={() => {}}
              updateJobPreferences={() => {}}
            />
            <WorkExperiences
              profile={candidate as SeekerStateType}
              workExperiences={
                (candidate.data.profile as SeekerProfileType).workExperiences
              }
              taxonomies={taxonomies}
              getTaxonomies={() => {}}
              addWorkExperience={() => {}}
              editWorkExperience={() => {}}
              deleteWorkExperience={() => {}}
            />
            <EducationEndDate
              profile={candidate as SeekerStateType}
              educations={
                (candidate.data.profile as SeekerProfileType).educations || []
              }
              taxonomies={taxonomies}
              getTaxonomies={() => {}}
              addEducation={() => {}}
              editEducation={() => {}}
              deleteEducation={() => {}}
            />
            <Languages
              profile={candidate as SeekerStateType}
              languages={
                (candidate.data.profile as SeekerProfileType).languages || []
              }
              taxonomies={taxonomies}
              getTaxonomies={() => {}}
              addLanguage={() => {}}
              editLanguage={() => {}}
              deleteLanguage={() => {}}
            />
            <DrivingLicences
              user={{
                data: {full: candidate.data.profile as SeekerProfileType},
                loading: candidate.loading,
                error: candidate.error,
              }}
              addDrivingLicence={() => {}}
              editDrivingLicence={() => {}}
              drivingLicenseCategories={taxonomies.drivingLicenseCategories}
              getDrivingLicenseCategories={getDrivingLicenseCategories}
            />
            <Skills
              profile={candidate as SeekerStateType}
              skills={(candidate.data.profile as SeekerProfileType).skills}
              taxonomies={taxonomies}
              addSkill={() => {}}
              editSkill={() => {}}
              deleteSkill={() => {}}
              getTaxonomies={() => {}}
            />
            <CertificationsEndDate
              profile={candidate as SeekerStateType}
              certifications={
                (candidate.data.profile as SeekerProfileType).trainings
              }
              taxonomies={taxonomies}
              addCertification={() => {}}
              editCertification={() => {}}
              deleteCertification={() => {}}
              getTaxonomies={() => {}}
            />
            <TrainingTitles
              trainingTitles={
                (candidate.data.profile as SeekerProfileType)
                  ?.seekerTrainingTitles || []
              }
            />
            <ProjectsEndDate
              profile={candidate as SeekerStateType}
              projects={(candidate.data.profile as SeekerProfileType).projects}
              addProject={() => {}}
              editProject={() => {}}
              deleteProject={() => {}}
            />
            <AwardsEndDate
              profile={candidate as SeekerStateType}
              awards={(candidate.data.profile as SeekerProfileType).awards}
              addAwards={() => {}}
              editAwards={() => {}}
              deleteAward={() => {}}
            />
            <Attachments
              profile={candidate as SeekerStateType}
              attachments={
                (candidate.data.profile as SeekerProfileType).attachments
              }
              addAttachment={() => {}}
              editAttachment={() => {}}
              removeAttachment={() => {}}
            />
            <Hr className={privateStyles.hr} />
          </div>
        ) : (
          <div />
        )}
      </div>
      <ReportModalContainer
        isOpen={reportModal}
        setIsOpen={(value: boolean) => setReportModal(value)}
        location={DSA_REPORT_LOCATIONS_MESSAGES.SEEKER_PROFILE}
        id={id}
        screenShotPictureId={screenShotPictureId}
      />
    </MainLayout>
  )
}
