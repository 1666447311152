import {dataProtectionAgreement} from './DataProtectionAgreement/en'
import {error} from './Error/en'
import {privacyPolicy} from './PrivacyPolicy/en'
import {reportInformation} from './ReportInformation/en'
import {support} from './Support/en'
import {termsAndConditions} from './TermsAndConditions/en'

export const publicPages = {
  ...dataProtectionAgreement,
  ...error,
  ...privacyPolicy,
  ...reportInformation,
  ...support,
  ...termsAndConditions,
}
