import { FC } from "react";
import styles from "./Maintenance.module.scss";
export const Maintenance: FC = () => {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          Platform is currently down for maintenance.
        </div>
        <div className={styles.txt}>
          We expect to be back shortly. Thank you for your patience.
        </div>
      </div>
    </>
  );
};
