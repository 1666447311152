import {FC, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {QuestionsContainer} from 'Components/CompanyProfile/Questions/QuestionsContainer/QuestionsContainer'
import Portfolio from 'Components/CompanyProfile/Portfolio'
import EmploymentInformation from 'Components/CompanyProfile/EmploymentInformation'
import ProfileHeaderContainer from 'Components/CompanyProfile/ProfileHeader'
import {WelcomeMessageContainer} from 'Components/CompanyProfile/WelcomeMessage'
import {
  CompanyDataType,
  StateType,
  EmploymentInformationItem,
  CompanyDescriptionItem,
  TaxonomiesStateType,
  CompanyBenefitsSaveType,
  ImpressionsAddType,
  ImpressionsUpdateType,
  VideoType,
} from 'Interfaces'
import {CompanyDescription} from 'Components/CompanyProfile/CompanyDescription/CompanyDescription'
import Benefits from 'Components/CompanyProfile/Benefits'
import Impressions from 'Components/CompanyProfile/Impressions'
import {Video} from 'Components/CompanyProfile/Video/Video'
import MainLayout from 'Layouts/MainLayout'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import styles from './CompanyProfile.module.scss'
import {SidebarLink} from 'Components/SidebarLink'
import {CompanyProfileTranslations as cpt} from 'Services/I18n/Constants/Employer/CompanyProfileTranslations'
import {AppRoute} from 'Services/I18n/Constants'
import {ReportModalContainer} from 'Components/ReportModal/ReportModalContainer'
import {DSA_REPORT_LOCATIONS_MESSAGES} from 'Interfaces/DSAType'
import {takeScreenshot} from 'Utils/CommonHelpers'

interface CompanyProfileProps {
  taxonomies: TaxonomiesStateType
  company: StateType<CompanyDataType>
  getCompanyData: (id: string) => void
  savePortfolio: (portfolio: string) => void
  saveBenefits: (benefits: CompanyBenefitsSaveType) => void
  getTaxonomies: () => void
  saveCompanyDescription: (value: CompanyDescriptionItem) => void
  getCompanyDescriptionBiziSi: (value: string) => void
  saveEmploymentInformation: (value: EmploymentInformationItem) => void
  addImpressions: (value: ImpressionsAddType[]) => void
  updateImpressions: (value: ImpressionsUpdateType[]) => void
  addVideos: (value: VideoType[]) => void
  updateVideos: (value: VideoType[]) => void
}

export const CompanyProfile: FC<CompanyProfileProps> = ({
  taxonomies,
  company,
  getCompanyData,
  savePortfolio,
  saveBenefits,
  getTaxonomies,
  saveCompanyDescription,
  getCompanyDescriptionBiziSi,
  saveEmploymentInformation,
  addImpressions,
  updateImpressions,
  addVideos,
  updateVideos,
}) => {
  let {id} = useParams<{id: string}>()

  const captureRef = useRef<HTMLDivElement>(null)

  const [reportModal, setReportModal] = useState(false)
  const [screenShotPictureId, setScreenShotPictureId] = useState('')

  const getScreenshot = async () => {
    if (captureRef.current) {
      try {
        const response = await takeScreenshot(
          captureRef.current,
          'report-company-screenshot.png'
        )
        if (!response) {
          return
        }
        setScreenShotPictureId(response)
        setReportModal(true)
      } catch (error) {
        console.error('Error taking screenshot:', error)
      }
    }
  }

  useEffect(() => {
    getCompanyData(id)
  }, [getCompanyData, id])

  const canChange = !id
  const setSidebarContent = () => (
    <div className={styles.sideContentWrap}>
      <SidebarLink
        text={cpt.searchCandidates}
        routeName={AppRoute.CandidateSearch}
      />
      <SidebarLink
        text={cpt.goToDashboard}
        routeName={AppRoute.EmployerDashboard}
      />

      <SidebarLink
        text={cpt.messageCenter}
        routeName={AppRoute.CandidateMessages}
      />

      {id && <SidebarLink text="Report" onClick={() => getScreenshot()} />}
    </div>
  )

  return (
    <div className={styles.contentContainer} ref={captureRef}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={
          <WidgetBarContainer sidebarContent={setSidebarContent()} />
        }
      >
        <ProfileHeaderContainer canChange={canChange} />
        <WelcomeMessageContainer canChange={canChange} />
        <CompanyDescription
          company={company}
          saveCompanyDescription={saveCompanyDescription}
          getCompanyDescriptionBiziSi={getCompanyDescriptionBiziSi}
          canChange={canChange}
        />
        <Benefits
          company={company}
          saveBenefits={saveBenefits}
          taxonomies={taxonomies}
          getTaxonomies={getTaxonomies}
          canChange={canChange}
        />
        <Impressions
          canChange={canChange}
          company={company}
          addImpressions={addImpressions}
          updateImpressions={updateImpressions}
        />
        <EmploymentInformation
          company={company}
          saveEmploymentInformation={saveEmploymentInformation}
          canChange={canChange}
        />
        <Portfolio
          company={company}
          savePortfolio={savePortfolio}
          canChange={canChange}
        />
        <Video
          company={company}
          canChange={canChange}
          addVideos={addVideos}
          updateVideos={updateVideos}
        />
        <div>
          <QuestionsContainer canChange={canChange} />
        </div>
        {/* currently need to be commented, leaving here as a comment as we might need this for later  */}
        {/*!canChange && company.data.id && (
          <div className={styles.companyProfileButtonContainer}>
            <div className={styles.buttonWrapper}>
              <Link
                to={getRoute(AppRoute.HomePageEmployer)}
                className={styles.button}
              >
                <YellowButton
                  className={styles.yellowButton}
                  text={'Contact company'}
                />
              </Link>
            </div>
          </div>
        )*/}
      </MainLayout>
      <ReportModalContainer
        isOpen={reportModal}
        setIsOpen={(value: boolean) => setReportModal(value)}
        location={DSA_REPORT_LOCATIONS_MESSAGES.COMPANY_PROFILE}
        id={id}
        screenShotPictureId={screenShotPictureId}
      />
    </div>
  )
}
