export enum AppRoute {
  // SEEKER
  SeekerLogin = 'routes.seekerlogin',
  SeekerLoginConsents = 'routes.seekerLoginConsents',
  Registration = 'routes.registration',
  Onboarding = 'routes.onboarding',
  ContactData = 'routes.contactdata',
  CognitiveTest = 'routes.cognitivetest',
  PsychometricTest = 'routes.psychometrictest',
  PsychometricTestResults = 'routes.psychometrictestresults',
  SeekerPsychometricTestResults = 'routes.seekerpsychometrictestresults',
  StartCognitiveTest = 'routes.startCognitiveTest',
  Profile = 'routes.profile',
  PublicProfile = 'routes.publicprofile',
  Assessments = 'routes.assessments',
  AssessmentsSuccess = 'routes.assessmentssuccess',
  Consents = 'routes.consents',
  LanguageSettings = 'routes.languagesettings',
  MyAccount = 'routes.myAccount',
  EmployerBlacklist = 'routes.employerblacklist',
  NewsletterUnsubscribe = 'routes.newsletterunsubscribe',
  NewsletterUnsubscribeSingle = 'routes.newsletterunsubscribesingle',
  NewsletterUnsubscribeReason = 'routes.newsletterunsubscribereason',
  CandidateMessageCenter = 'routes.candidateMessages',
  CandidateCalendar = 'routes.candidateCalendar',
  EmployerMessages = 'routes.employerMessages',

  // EMPLOYER
  EmployerLogin = 'routes.employerlogin',
  EmployerLoginConsents = 'routes.employerLoginConsents',
  EmployerOnboarding = 'routes.employeronboarding',
  PublicAssessments = 'routes.publicassessments',
  CompanyProfile = 'routes.companyprofile',
  CandidateSearch = 'routes.candidatesearch',
  CandidateDashboard = 'routes.candidatedashboard',
  AllIncomingRequests = 'routes.allincomingrequests',
  EmployerDashboard = 'routes.employerdashboard',
  EmployerMyAccount = 'routes.employermyaccount',
  EmployerLanguageSettings = 'routes.employerlanguagesettings',
  EmployerConsents = 'routes.employerconsents',
  InviteCalendar = 'routes.invitecalendar',
  ProposeNewTimeCalendar = 'routes.proposenewtimecalendar',
  ImpersonateEmployer = 'routes.impersonateEmployer',
  ReviewCandidate = 'routes.reviewcandidate',
  EmployerContactRequests = 'routes.employercontactrequests',
  EmployerContactRequestsAll = 'routes.employercontactrequestsAll',
  EmployerContactRequestsPending = 'routes.employercontactrequestsPending',
  EmployerContactRequestsApproved = 'routes.employercontactrequestsApproved',
  EmployerInterviewInvitations = 'routes.employerinterviewinvitations',
  EmployerInterviewInvitationsAll = 'routes.employerinterviewinvitationsAll',
  EmployerInterviewInvitationsPending = 'routes.employerinterviewinvitationsPending',
  EmployerInterviewInvitationsAccepted = 'routes.employerinterviewinvitationsAccepted',
  EmployerInterviewInvitationsCompleted = 'routes.employerinterviewinvitationsCompleted',
  EmployerOffers = 'routes.employeroffers',
  EmployerOffersAll = 'routes.employeroffersAll',
  EmployerOffersPending = 'routes.employeroffersPending',
  EmployerOffersAccepted = 'routes.employeroffersAccepted',
  EmployerPlacements = 'routes.employerplacements',
  EmployerPlacementsAll = 'routes.employerplacementsAll',
  EmployerPlacementsPending = 'routes.employerplacementsPending',
  EmployerPlacementsConfirmed = 'routes.employerplacementsConfirmed',
  EmployerThankYou = 'routes.employerthankyou',
  MyProjects = 'routes.myprojects',
  MyProjectCandidates = 'routes.myprojectcandidates',
  EmployerCalendar = 'routes.employerCalendar',
  EmployerCandidateProfilePublic = 'routes.employerCandidateProfilePublic',
  EmployerCandidateProfileFull = 'routes.employerCandidateProfileFull',
  CandidateMessages = 'routes.candidateMessages',
  EmployerMessagesTSA = 'routes.employerMessagesTSA',

  // PUBLIC - UNIVERSAL
  PrivacyPolicy = 'routes.privacypolicy',
  TermsAndConditions = 'routes.termsandconditions',
  GeneralTermsAndConditions = 'routes.generalTermsAndConditions',
  ReportInformation = 'routes.reportInformation',
  HomePageEmployer = 'routes.homePageEmployer',
  HomePageCandidate = 'routes.homePageCandidate',
  EmployerInfoLanding = 'routes.employerinfolanding',
  HelpSupportContactUs = 'routes.helpsupportcontactus',
  HelpSupport = 'routes.helpsupport',
  FrequentlyAskedQuestions = 'routes.frequentlyAskedQuestions',
  Logout = 'routes.logout',
  EmployerFAQ = 'routes.employerFAQ',
  CandidateInfo = 'routes.candidateInfo',
  CandidateFAQ = 'routes.candidateFAQ',
  ImpersonateCandidate = 'routes.impersonateCandidate',
  AboutUs = 'routes.aboutus',
  PublicCompanyProfile = 'routes.publiccompanyprofile',
  Page404 = 'routes.page404',
  CandidateAdvice = 'routes.candidateAdvice',
  EmployerAdvice = 'routes.employerAdvice',
  CandidateCompanies = 'routes.candidateCompanies',
  DataProtectionAgreement = 'routes.dataProtectionAgreement',
  SavedSearch = 'routes.savedSearch',
  Auth = 'routes.auth',
  CandidateWelcome = 'routes.candidateWelcome',
  EmployerWelcome = 'routes.employerWelcome',
  Error = 'routes.error',
  Maintenance = 'routes.maintenance',
  NewsletterUnsubscribeSuccess = 'routes.newsletterUnsubscribeSuccess',
}

export const AppRouteTitles = new Map([
  [AppRoute.SeekerLogin, 'seekerlogin.title'],
  [AppRoute.EmployerLogin, 'employerlogin.title'],
  [AppRoute.Registration, 'registration.title'],
  [AppRoute.Onboarding, 'onboarding.title'],
  [AppRoute.EmployerOnboarding, 'employeronboarding.title'],
  [AppRoute.ContactData, 'uploadtest.contactdata'],
  [AppRoute.PrivacyPolicy, 'privacypolicy.title'],
  [AppRoute.CognitiveTest, 'cognitivetest.title'],
  [AppRoute.PsychometricTest, 'psychometrictest.title'],
  [AppRoute.PsychometricTestResults, 'psychometrictestresults.title'],
  [AppRoute.TermsAndConditions, 'termsandconditions.title'],
  [AppRoute.Profile, 'profile.title'],
  [AppRoute.PublicProfile, 'publicprofile.title'],
  [AppRoute.Assessments, 'assessments.title'],
  [AppRoute.PublicAssessments, 'publicassessments.title'],
  [AppRoute.AssessmentsSuccess, 'assessmentssuccess.title'],
  [AppRoute.Consents, 'consents.title'],
  [AppRoute.HomePageEmployer, 'homePageEmployer.title'],
  [AppRoute.HomePageCandidate, 'homePageCandidate.title'],
  [AppRoute.EmployerInfoLanding, 'employerInfoLanding.title'],
  [AppRoute.CompanyProfile, 'companyprofile.title'],
  [AppRoute.CandidateSearch, 'candidatesearch.title'],
  [AppRoute.CandidateDashboard, 'candidatedashboard.title'],
  [AppRoute.AllIncomingRequests, 'allincomingrequests.title'],
  [AppRoute.EmployerDashboard, 'employerdashboard.title'],
  [AppRoute.HelpSupportContactUs, 'helpsupportcontactus.title'],
  [AppRoute.HelpSupport, 'helpsupport.title'],
  [AppRoute.LanguageSettings, 'languagesettings.title'],
  [AppRoute.FrequentlyAskedQuestions, 'frequentlyAskedQuestions.title'],
  [AppRoute.MyAccount, 'myAccount.title'],
  [AppRoute.Logout, 'logout.title'],
  [AppRoute.EmployerFAQ, 'employerFAQ.title'],
  [AppRoute.InviteCalendar, 'invitecalendar.title'],
  [AppRoute.CandidateInfo, 'candidateInfo.title'],
  [AppRoute.NewsletterUnsubscribe, 'newsletterunsubscribe.title'],
  [AppRoute.NewsletterUnsubscribeReason, 'newsletterunsubscribereason.title'],
  [AppRoute.CandidateFAQ, 'candidateFAQ.title'],
  [AppRoute.ReviewCandidate, 'reviewcandidate.title'],
  [AppRoute.EmployerContactRequests, 'employercontactrequests.title'],
  [AppRoute.EmployerInterviewInvitations, 'employerinterviewinvitations.title'],
  [AppRoute.EmployerOffers, 'employeroffers.title'],
  [AppRoute.EmployerPlacements, 'employerplacements.title'],
  [AppRoute.EmployerThankYou, 'employerthankyou.title'],
  [AppRoute.CandidateCalendar, 'candidateCalendar.title'],
  [AppRoute.EmployerCalendar, 'employerCalendar.title'],
  [AppRoute.CandidateAdvice, 'candidateAdvice.title'],
  [AppRoute.EmployerAdvice, 'employerAdvice.title'],
  [AppRoute.CandidateCompanies, 'candidateCompanies.title'],
  [AppRoute.PublicCompanyProfile, 'publiccompanyprofile.title'],
  [AppRoute.NewsletterUnsubscribeSuccess, 'newsletterUnsubscribeSuccess.title'],
])
