export const settingsdropdown = {
  'dropdownsettings.seeYourProfile': 'See your public profile',
  'dropdownsettings.myAccount': 'My account',
  'dropdownsettings.myConsents': 'My consents',
  'dropdownsettings.languageSettings': 'Language settings',
  'dropdownsettings.employerBlacklist': 'Employer blacklist',
  'dropdownsettings.helpAndSupport': 'Help & Support',
  'dropdownsettings.logout': 'Logout',
  'myAccount.account': ' account',
}
