import axios from "axios";

import {
  disconnectSocket,
  getRoute,
  getRouteLanguageId,
  isEmployerRoute,
  isRouteWithoutRedirect,
} from "Services/I18n/Utils";
import { getChannel } from "Utils/CommonHelpers";
import { generateRedirectService } from "Services/generateRedirectService";
import { AppRoute } from "Services/I18n/Constants";
import { history } from "Store/history";

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const instance = axios.create({
  baseURL: baseURL,
  headers: {
    tenantId: getChannel().tenantId as string,
    channelId: getChannel().channelId as string,
  },
  withCredentials: true,
});

const initAxiosHeaders = () => {
  const impersonatedUserId = localStorage.getItem("impersonatedUserId") || null;
  if (impersonatedUserId) {
    instance.defaults.headers.common["impersonatedUserId"] = impersonatedUserId;
  } else if (instance.defaults.headers.common["impersonatedUserId"]) {
    delete instance.defaults.headers.common["impersonatedUserId"];
  }
  const userId = localStorage.getItem("userId") || null;
  if (userId) {
    instance.defaults.headers.common["userId"] = userId;
  } else if (instance.defaults.headers.common["userId"]) {
    delete instance.defaults.headers.common["userId"];
  }
  let languageId = getRouteLanguageId() || getChannel().defaultLanguage?.id;
  if (languageId) {
    instance.defaults.headers.common["languageId"] = languageId;
  }
};

export const initAPIHeaders = () => {
  initAxiosHeaders();
};

export const clearUserIds = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("impersonatedUserId");
  localStorage.removeItem("sessionTimestamp");
  if (instance.defaults.headers.common["impersonatedUserId"]) {
    delete instance.defaults.headers.common["impersonatedUserId"];
  }
  if (instance.defaults.headers.common["userId"]) {
    delete instance.defaults.headers.common["userId"];
  }
  if (window.app.chat.socket) {
    disconnectSocket(window.app.chat.socket);
  }
};

// init headers
initAPIHeaders();
instance.interceptors.response.use(
  (response) => {
    // TODO if is not public route
    localStorage.setItem("sessionTimestamp", new Date().getTime().toString());
    return response;
  },
  (error) => {
    if (error.response?.data.error.statusCode === 401) {
      clearUserIds();
      if (!isRouteWithoutRedirect()) {
        if (isEmployerRoute()) {
          generateRedirectService({
            userType: "company",
            url: window.location.href,
          });
        } else
          generateRedirectService({
            userType: "seeker",
            url: window.location.href,
          });
      }
    }

    if (error.response?.data.error.statusCode === 503) {
      clearUserIds();
      history.push(getRoute(AppRoute.Maintenance));
    }

    return Promise.reject(error);
  },
);

const handleResponse = (response: any) => ({ response: response.data });
const handleError = (error: any) => ({
  error: error.response?.data?.error || error,
});

export const API = {
  get: (url: string) => instance.get(url).then(handleResponse, handleError),
  post: (url: string, data: any | undefined) =>
    instance.post(url, data).then(handleResponse, handleError),
  put: (url: string, data: any | undefined) =>
    instance.put(url, data).then(handleResponse, handleError),
  patch: (url: string, data: any | undefined) =>
    instance.patch(url, data).then(handleResponse, handleError),
  delete: (url: string, data?: any | undefined) =>
    instance.delete(url, { data }).then(handleResponse, handleError),
};
