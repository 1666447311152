export const settingsdropdown = {
  'dropdownsettings.seeYourProfile': 'Vaš karierni profil',
  'dropdownsettings.myAccount': 'Moj Račun',
  'dropdownsettings.myConsents': 'Moja soglasja',
  'dropdownsettings.languageSettings': 'Nastavitve jezika',
  'dropdownsettings.employerBlacklist': 'Employer blacklist',
  'dropdownsettings.helpAndSupport': 'Help & Support',
  'dropdownsettings.logout': 'Odjava',
  'myAccount.account': ' račun',
}
