export const consents = {
  'consents.title': 'My Consents',
  'consents.labelTitle': 'TITLE',
  'consents.labelStatus': 'STATUS',
  'consents.labelDate': 'DATE',
  'consents.labelContent': 'CONTENT',
  'consents.labelAction': 'ACTION',
  'consents.consentGrant': 'Grant consent',
  'consents.consentRevoke': 'Revoke consent',
  'consents.consentAccept': 'Accept consent',
  'consents.consentRecall': 'Recall consent',
  'consents.consentGranted': 'Granted',
  'consents.consentRevoked': 'Revoked',
  'consents.termsOfUse': 'Terms of use',
  // TODO: change this when translations are done
  'consents.privacyPolicyPsychometric': 'Politiki zasebnosti.',
  'consents.privacyPolicy': 'Privacy policy',
  'consents.cookiesPolicy': 'Cookies policy',
  'consents.consentsExpired': 'Expired',
  'consents.consentsUpToDate': 'Your consents are up to date.',
  'consents.consentsUpToDateFullAdvantage':
    'Your consents are up to date and you can take advantage of our full functionality, including checking employers (use for cross-linking to search employer page, etc...). ',
  'consents.consentsContent': 'Content',
  'consents.consentsDownload': 'Download',
  'consents.consentsPrint': 'Print',
  'consents.modalTermsOfUSeAgree':
    'I agree to the terms of use of the portal & transactional email',
  'consents.modalPersonalData':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marketplaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov.',
  // TODO: Change this when translations are done
  'consents.infoModalPsychometric':
    'Soglašam z obdelavo svojih osebnih podatkov za pripravo mojega poročila psihometričnega testiranja na strani družbe Styria digital marketplaces, d.o.o., ki ga bom prejel/a v uporabniški profil na spletnem portalu Preferenca.si, z namenom zagotavljanja prilagojenih ponudb novih zaposlitvenih možnosti. Več informacij o obdelavi vaših osebnih podatkov si preberite v ',
  'consents.infoModalCognitive':
    'Soglašam z obdelavo svojih osebnih podatkov, posredovanih na podlagi sledečega vprašalnika, z namenom priprave mojega poročila testiranja kognitivnih sposobnosti na spletnem portalu Preferenca.si, ki ga bom prejel/a v svoj uporabniški profil. Priprava poročila temelji na avtomatizirani obdelavi posredovanih podatkov, več informacij o obdelavi podatkov si preberite v ',
  'consents.modalMarketingText':
    'V tem primeru vas ne bomo več obveščali o vsebinah družbe Styria digital marketplaces, d.o.o. niti o vsebinah njenih partnerjev (tretjih oseb). Tovrstna obvestila zajemajo informacije o aktualnih akcijah, novostih, produktih, nagradnih igrah in drugih dogodkih.',
  'consents.infoModalPersonalData':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marektlaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov. S podajo vaše privolitve vas bomo obveščali o vsebinah družbe Styria digital marketplaces, d.o.o. kot tudi o vsebinah njenih partnerjev (tretjih oseb). Tovrstna obvestila zajemajo informacije o aktualnih akcijah, novostih, produktih, nagradnih igrah in drugih dogodkih. Več informacij o obdelavi vaših podatkov si preberite v ',
  'consents.infoModalNewsletter':
    'eNovice Preferenca.si so elektronska obvestila namenjena informiranju registriranih uporabnikov o novostih in sorodnih storitvah spletnega mesta Preferenca.si, če se od prejemanja tovrstnih obvestil niste odjavili v trenutku registracije ali v katerem koli trenutku po opravljeni registraciji. Več informacij v ',
  'consents.infoModalNewsletter2':
    'V primeru vaše odjave od prejemanja eNovice Preferenca.si, bomo vaš e-naslov prenehali uporabljati za namene obveščanja o novostih in sorodnih storitvah spletnega mesta Preferenca.si, in sicer v roku 15 dni od prejema vaše odjave.',
  'consents.modalCantBeRevoked':
    'For the provision of services, revoking this consent is not available. If you have additional questions or you wish to delete your account, please contact us on podpora@preferenca.si.',
  'consents.modalWithdrawPrivacyOrCookies':
    'Are you sure you want to withdraw consent? In this case, we will deactivate your career profile, which will permanently disable all your data and all services available to registered users (eg applying for vacancies via the portal & transactional emails MojeDelo.com, creating e-reminders, editing CVs, etc.). ',
  'consents.statementOfConsent': 'STATEMENT OF CONSENT',
  'consents.withdrawConsent': 'ARE YOU SURE YOU WANT TO REVOKE THIS CONSENT?',
  'consents.modalWithdrawTestsConsents':
    'After consent is revoked, you will not be able to take the tests or see the results.',
  'consents.basicConsents': 'Basic consents',
  'consents.consentsForNotifications': 'Consents for notifications',
  'consents.consentsForTests': 'Consents for tests',
  'consents.granted': 'Granted',
  'consents.revoked': 'Revoked',
  'consents.checkmarkText':
    'I agree to the processing of my personal data to create a user profile in the online application (P) reference in order to provide services intended for registered users.',
  'consents.privacyPolicyModalTitle':
    'Privacy and personal data protection policy',
  // TODO: Change this when translations are done
  'consents.checkboxNewsletter':
    'Soglašam s prejemanjem eNovice Preferenca.si za namene obveščanja o novostih in sorodnih storitvah spletnega mesta Preferenca.si.',
  'consents.checkboxMarketing':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marektlaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov.',
  'consents.thirdPartyMarketing': '3rd party marketing consent (optin)',
  'consents.mandatoryConsentsText': 'Consents',
  'consents.mandatoryConsentsTitle': 'We need your Consent',
  'consents.mandatoryConsentsDescription':
    'You have successfully logged in to Preferenca.si, but we need the following consents from you to continue.',
  'consents.reviewNotificationConsents': 'Review your notification consents',
  'consents.successfullyUnsubscribed':
    'You have successfully unsubscribe from ',
}
