import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalOfferRequest.module.scss'
import {DateTime} from 'luxon'

import ModalRejectOfferRequest from './ModalRejectOfferRequest'
import ModalApproveOfferRequest from './ModalApproveOfferRequest'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'

import classnames from 'classnames'
import {IncomingRequestType} from 'Interfaces'
import {FC, useRef, useState} from 'react'
import {
  Business,
  ConnectWithoutContact,
  Notifications,
  Update,
  HourglassBottom,
  HistoryEdu,
  AttachFile,
  ForumOutlined,
  Flag,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'
import {
  CombinedRequestStatues,
  OfferRequestStatus,
  seekerRequestStatusTranslationMap,
} from 'Components/IncomingRequests/Constants'
import {Translation} from 'Components/Translation'
import {takeScreenshot} from 'Utils/CommonHelpers'
import {ReportModalContainer} from 'Components/ReportModal'
import {DSA_REPORT_LOCATIONS_MESSAGES} from 'Interfaces/DSAType'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO candidate-dashboard: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={cdt.expiresToday} />
        </p>
      </div>
    )
  }
  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }

  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

interface ModalOfferRequestProps {
  request: IncomingRequestType
  isOpenOfferRequest: boolean
  setIsOpenOfferRequest: (open: boolean) => void
  thumbUp?: boolean
  modalDetails?: boolean
  setModalDetails?: (modalDetails: boolean) => void
  showDetails?: boolean
}

export const ModalOfferRequest: FC<ModalOfferRequestProps> = ({
  request,
  isOpenOfferRequest,
  setIsOpenOfferRequest,
  thumbUp,
  modalDetails,
  setModalDetails,
  showDetails = true,
}) => {
  const [details, setDetails] = useState(modalDetails)
  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  const captureRef = useRef<HTMLDivElement>(null)

  const [reportModal, setReportModal] = useState(false)
  const [screenShotPictureId, setScreenShotPictureId] = useState('')

  const getScreenshot = async () => {
    if (captureRef.current) {
      try {
        const response = await takeScreenshot(
          captureRef.current,
          'report-company-screenshot.png'
        )
        if (!response) {
          return
        }
        setScreenShotPictureId(response)
        setReportModal(true)
      } catch (error) {
        console.error('Error taking screenshot:', error)
      }
    }
  }

  return (
    <>
      <Modal
        className={details ? styles.modalDetails : styles.modal}
        icon={ForumOutlined}
        isOpen={isOpenOfferRequest}
        title={cdt.offerDetails}
        onAfterClose={() => {
          setIsOpenOfferRequest(false)
          setModalDetails && setModalDetails(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          request.status === CombinedRequestStatues.PENDING && (
            <ThumbsFooter
              theme={thumbUp ? 'thumbUp' : 'thumbs'}
              onApprove={() => {
                setApproveModal(true)
              }}
              approveText={cdt.accept}
              rejectText={cdt.reject}
              onReject={() => {
                setRejectModal(true)
              }}
              onCancel={() => {
                setIsOpenOfferRequest(false)
                setModalDetails && setModalDetails(false)
              }}
            />
          )
        }
      >
        <div className={styles.containerInterviewDetails} ref={captureRef}>
          <p className={styles.title}>{request.companyName}</p>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Business className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.company} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>{request.companyName}</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.ContactRequestText}>
                <FormattedMessage id={cdt.offer} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.rejected]:
                    request.status === OfferRequestStatus.REJECTED ||
                    request.status === OfferRequestStatus.CANCELLED,
                  [styles.accepted]:
                    request.status === OfferRequestStatus.ACCEPTED,
                  [styles.expired]:
                    request.status === OfferRequestStatus.EXPIRED,
                })}
              >
                <FormattedMessage
                  id={seekerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemOffer}>
              <div className={styles.itemOfferLeft}>
                <HistoryEdu className={styles.icon} />
                <p className={styles.itemOfferTitle}>
                  <FormattedMessage id={cdt.content} />:
                </p>
              </div>
              <div className={styles.itemOfferRight}>
                {/* <p className={styles.itemOfferName}>
                <FormattedMessage id={cdt.dear} />
              </p>
              <p className={styles.itemOfferTitle}>
                <FormattedMessage id={cdt.modalOfferWeSendYouOurP} />
              </p> */}
                <div>
                  <p className={styles.itemOfferTitle}>{request.content}</p>
                  <div className={styles.itemReport}>
                    <Flag />
                    <button onClick={getScreenshot}>
                      <Translation text="Report content" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDate(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>
          {request.attachment && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <AttachFile className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.attachment} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <a
                  className={classnames(
                    styles.itemRightText,
                    styles.itemRightTextBlue
                  )}
                  href={request.attachment?.url}
                  target="_blank"
                >
                  {request.attachment?.title
                    ? request.attachment.title
                    : request.attachment?.originalName}
                </a>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <ModalApproveOfferRequest
        request={request}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
        setIsOpenOfferRequest={setIsOpenOfferRequest}
        showDetails={!details ? showDetails : false}
      />
      <ModalRejectOfferRequest
        request={request}
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
        setDetails={setDetails}
        setIsOpenOfferRequest={setIsOpenOfferRequest}
        showDetails={!details ? showDetails : false}
      />
      <ReportModalContainer
        isOpen={reportModal}
        setIsOpen={(value: boolean) => setReportModal(value)}
        location={DSA_REPORT_LOCATIONS_MESSAGES.JOB_OFFER}
        id={request.id}
        screenShotPictureId={screenShotPictureId}
      />
    </>
  )
}
