export const identityProvider = {
  'identityProvider.welcomeMessage': 'Dobrodošli na Preferenci',
  'identityProvider.description': 'Prijavili se boste kot:',
  'identityProvider.changeUser': 'Izberi drugega uporabnika',
  'identityProvider.next': 'Naprej',
  'identityProvider.alreadyHaveAccount': 'Že imate račun?',
  'identityProvider.loginNow': 'Prijavite se',
  'identityProvider.unauthorizedError':
    'Napaka: poskušate se prijaviti z računom, ki nima dostopa do sekcije za delodajalce. Preverite, če ste ob registraciji vnesli elektronski naslov, na katerega ste prejeli vabilo.',
  'Login or register with email from invite':
    'Prijavite se ali registrirajte z e-pošto iz povabila',
  'identityProvider.privacyNoticePartOne':
    'Vaše osebne podatke bo Styria digital marketplaces, d.o.o., Verovškova ulica 55, Ljubljana obdelovala za namen registracije in rabe portala na podlagi pogodbe (člen 6(1)b GDPR), ki jo predstavljajo pogoji poslovanja ',
  'identityProvider.privacyNoticePartTwo':
    '. Če nam svojih osebnih podatkov ne želite razkriti, prijava na portal in koriščenje storitev na njem nujno ne bosta mogoča. Če ste se prijavili na naše e-novice, bomo vaše osebne podatke na podlagi vaše privolitve (člen 6(1)a GDPR) obdelovali do preklica. Od prejemanja e-novic se lahko kadarkoli odjavite s klikom na povezavo v prejetem e-sporočilu. Več si lahko preberete v našem obvestilu o zasebnosti',
  'identityProvider.privacyPolicy':
    'Politika zasebnosti in varovanja osebnih podatkov',
  'identityProvider.termsAndConditions':
    '(Pravila in pogoji uporabe Preferenca.si)',
}
