import {useAutocomplete} from '@mui/base/AutocompleteUnstyled'
import {ArrowDropDown} from '@mui/icons-material'
import clsx from 'clsx'
import {CheckBoxIcon} from 'Components/Icons'
import {Translation} from 'Components/Translation'
import TextError from 'Components/Error/TextError'
import {useTranslate} from 'Hooks'
import {BaseTaxonomyType, TaxonomyWithCategoryType} from 'Interfaces'
import {isEqual} from 'lodash'
import {
  FC,
  InputHTMLAttributes,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import API from 'Services/API'

import styles from './TypeAheadFetch.module.scss'

type valueType =
  | BaseTaxonomyType
  | TaxonomyWithCategoryType
  | (BaseTaxonomyType | TaxonomyWithCategoryType)[]
  | null
  | ''
/* eslint-disable no-unused-vars */

interface TypeAheadFetchProps {
  name: string
  value: valueType
  setValue: (name: string, newValue: valueType) => void
  options: BaseTaxonomyType[] | TaxonomyWithCategoryType[]
  optionsQueryUrl?: string
  label?: string
  placeholder?: string
  error?: string
  className?: string
  disabled?: boolean
  multiple?: boolean
  categoryName?: string
  autoComplete?: boolean
  smallIndex?: boolean
  themes?: ('white' | 'withMargin')[]
  required?: boolean
  withChips?: boolean
}

const TypeAheadWithFetch: FC<TypeAheadFetchProps> = ({
  name,
  value = null,
  setValue,
  options,
  label,
  placeholder,
  className,
  error,
  categoryName,
  disabled = false,
  multiple = false,
  autoComplete = false,
  smallIndex = false,
  themes,
  withChips = false,
  optionsQueryUrl,
}) => {
  
  const [open, setOpen] = useState(false)
  const [localValue, setLocalValue] = useState(value)
  const [localOptions, setLocalOptions] = useState<
    BaseTaxonomyType[] | TaxonomyWithCategoryType[]
  >([])
  const [localError, setLocalError] = useState<string | undefined>(undefined)
  const closeList = () => setOpen(false)
  const openList = () => setOpen(true)

  useEffect(() => {
    if (!isEqual(value, localValue)) {
      /* TODO check if there is a proper way to clear inputValue.
         If we want to clear the value and we set it to null, inputValue matches previous translation
         there is no way to programmatically clear it, but they are aware of this issue an will fix in the future
         https://github.com/mui/material-ui/issues/37738
         We are currently setting the value to empty string which works, but causes warning in the console
      */
      setLocalValue(value || '')
    }
  }, [value, localValue])

  const handleChange = useCallback(
    (
      _: SyntheticEvent,
      v: string | BaseTaxonomyType | null | (string | BaseTaxonomyType)[]
    ) => {
      let newValue = null
      if (typeof v === 'string') {
        newValue = [...(localOptions ? localOptions : options)].find(
          (o) => o.translation === v
        ) || {
          id: `newValue~${v}`,
          translation: v,
        }
      } else if (Array.isArray(v)) {
        newValue = v.map((va) =>
          typeof va === 'string' ? {id: `newValue~${va}`, translation: va} : va
        )
      } else newValue = v
      setLocalValue(newValue)
      setValue(name, newValue)
    },
    [localOptions, options, name]
  )

  const isOptionEqualToValue = (
    option: string | BaseTaxonomyType | null,
    value: string | BaseTaxonomyType
  ) => {
    if (!option) return false
    if (typeof value !== 'string') {
      return typeof option !== 'string'
        ? option.id === value.id
        : option === value.translation
    }
    return typeof option !== 'string'
      ? option.translation === value
      : option === value
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
    focused,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete({
    id: name,
    freeSolo: smallIndex,
    autoSelect: smallIndex,
    multiple: multiple,
    options: optionsQueryUrl ? localOptions : options,
    disabled: disabled,
    getOptionLabel: (option: BaseTaxonomyType | string) =>
      typeof option === 'string' ? option : option.translation,
    value: multiple && !localValue ? [] : localValue,
    onChange: handleChange,
    isOptionEqualToValue,
    disableCloseOnSelect: multiple,
    open: open,
    onOpen: openList,
    onClose: closeList,
    autoComplete,
  })

  const listboxProps = getListboxProps()
  const inputProps = getInputProps()
  const translate = useTranslate()
  const shouldShowNonExisting = useMemo(() => {
    return (
      open &&
      smallIndex &&
      (inputValue !== '' || multiple) &&
      groupedOptions.findIndex((o) =>
        typeof o === 'string'
          ? o === inputValue
          : 'translation' in o
          ? o.translation === inputValue
          : false
      ) === -1
    )
  }, [smallIndex, inputValue, groupedOptions, open])

  const inputValueIsSelected = useMemo(() => {
    return localValue !== ''
      ? Array.isArray(localValue)
        ? localValue.findIndex((lv) => lv.translation === inputValue) > -1
        : localValue?.translation === inputValue
      : false
  }, [localValue, inputValue])

  // const nonExistingValues = useMemo(
  //   () =>
  //     multiple && localValue && Array.isArray(localValue) ? localValue.filter((v) => v.id.includes('newValue~')) : [],
  //   [localValue, multiple],
  // )

  const toggleList = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  useEffect(() => {
    let mounted = true
    if (optionsQueryUrl) {
      API.get(`${optionsQueryUrl}${inputValue}`).then((rawResponse) => {
        if (mounted) {
          if ('response' in rawResponse) {
            setLocalOptions(rawResponse.response.data.items)
            setLocalError(undefined)
          } else if ('error' in rawResponse) {
            setLocalOptions([])
            setLocalError(rawResponse.error.message)
          }
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [inputValue, optionsQueryUrl])

  return (
    <div
      className={clsx(
        styles.container,
        themes ? themes.map((theme) => styles[theme]) : '',
        className,
        {[styles.disabled]: disabled}
      )}
    >
      <div
        className={clsx(styles.dropdownContainer, {
          [styles.open]: popupOpen && !disabled,
          [styles.withChips]: withChips && multiple,
        })}
        {...getRootProps()}
      >
        <div className={styles.autocomplete}>
          <div
            className={clsx(styles.inputWrapper, {
              [styles.inputWrapperError]: error || localError,
              [styles.inputWrapperFocused]: focused,
              [styles.inputWrapperDisabled]: disabled,
            })}
            ref={setAnchorEl}
          >
            <label
              className={styles.label}
              htmlFor={name}
              {...getInputLabelProps()}
            >
              <Translation>{label}</Translation>
            </label>
            <div>
              <input
                className={clsx(styles.input, {
                  [styles.inputHidden]: !autoComplete,
                })}
                {...inputProps}
                placeholder={translate(placeholder)}
                disabled={disabled}
              />
              <div
                className={clsx(styles.inputIcon, {
                  [styles.inputIconOpen]: !multiple && popupOpen && !disabled,
                  [styles.inputIconDisabled]: disabled,
                })}
              >
                {categoryName ? null : <ArrowDropDown onClick={toggleList} />}
              </div>
            </div>
            {!autoComplete && (
              <button
                className={clsx(styles.input, styles.inputHiddenButton)}
                onClick={openList}
                onFocus={openList}
                onBlur={closeList}
              />
            )}
          </div>
          <div
            className={clsx(styles.listWrap, {
              [styles.listWrapNoLabel]: !error && !localError && !label,
            })}
          >
            <ul className={styles.list} {...listboxProps} role="listbox">
              {groupedOptions.length > 0 ? (
                groupedOptions.map((option, index) => {
                  const optionProps = getOptionProps({
                    option: option as BaseTaxonomyType,
                    index,
                  })
                  return (
                    <li
                      {...optionProps}
                      key={(option as BaseTaxonomyType).id}
                      className={clsx(styles.option, {
                        [styles.optionSelected]: !!optionProps['aria-selected'],
                      })}
                    >
                      <span className={styles.text}>
                        {(option as BaseTaxonomyType).translation}
                      </span>
                    </li>
                  )
                })
              ) : !smallIndex ? (
                <li className={styles.option}>
                  <Translation>No results</Translation>
                </li>
              ) : (
                inputValue === '' && (
                  <li className={styles.option}>
                    <Translation>Type to add</Translation> <Translation>{`${placeholder}`}</Translation>
                  </li>
                )
              )}
              {shouldShowNonExisting && (
                <li
                  className={clsx(styles.option, {
                    [styles.optionSelected]: inputValueIsSelected,
                  })}
                  onClick={() => {
                    setTimeout(() => {
                      ;(
                        inputProps as InputHTMLAttributes<HTMLInputElement> & {
                          ref?: {current: HTMLElement}
                        }
                      ).ref?.current.blur()
                    }, 0)
                  }}
                  role="option"
                  aria-selected={inputValueIsSelected}
                >
                  {multiple && (
                    <CheckBoxIcon
                      checked={inputValueIsSelected}
                      className={styles.checkboxIcon}
                    />
                  )}
                  <span className={styles.text}>
                    {inputValueIsSelected ? (
                      inputValue
                    ) : (
                      <span>
                        <Translation text={'Add'} />
                        {` ${inputValue}`}
                      </span>
                    )}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {error || localError ? (
        <TextError text={error || localError || ''} />
      ) : null}
    </div>
  )
}

export const TypeAheadFetch = memo(TypeAheadWithFetch)
