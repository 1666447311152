import {FC, ReactNode} from 'react'
import classnames from 'classnames'
import CheckBox from 'Components/Inputs/Checkbox'
import styles from './Modal.module.scss'
import {Button} from 'Components/Button'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {
  ConsentsTranslations as ct,
  RegistrationTranslations as rt,
  ModalsAssessmentsTranslations as mat,
} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

interface SubmitFooterProps {
  onSubmit: () => void
  onCancel?: () => void
  submitText?: string
  cancelText?: string
  theme?:
    | 'modalProfileSubmit'
    | 'modalSubmit'
    | 'spaceBetween'
    | 'understand'
    | 'error'
    | ''
  checkboxValue?: boolean
  setCheckboxValue?: Function
  type?: string | undefined
  modalText?: string
  disabled?: boolean
  shelbyMillsFormContent?: JSX.Element | boolean | null
  checkboxText?: ReactNode
  link?: boolean
  bottomText?: ReactNode
}

export const SubmitFooter: FC<SubmitFooterProps> = ({
  onSubmit,
  onCancel,
  submitText = 'Save changes',
  cancelText = 'Cancel',
  theme = '',
  checkboxValue,
  setCheckboxValue,
  type = '',
  modalText,
  disabled,
  checkboxText,
  link = true,
  bottomText,
}: SubmitFooterProps) => {
  const translation = useTranslation()

  return (
    <div className={styles.submitFooter}>
      {type === 'checkboxFooter' && modalText !== ct.modalCantBeRevoked && (
        <div className={styles.checkboxWrapper}>
          <CheckBox
            name="consent1B"
            value={checkboxValue ? checkboxValue : false}
            onChange={() => {
              setCheckboxValue && setCheckboxValue(!checkboxValue)
            }}
            topAlign
          >
            {checkboxText || <FormattedMessage id={ct.checkmarkText} />}{' '}
            {link && (
              <Link
                to={getRoute(AppRoute.PrivacyPolicy)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.consentLink}
              >
                {checkboxText ? (
                  <>
                    <FormattedMessage id={mat.privacyPolicy} />
                  </>
                ) : (
                  <FormattedMessage id={rt.readMore} />
                )}
              </Link>
            )}
          </CheckBox>
        </div>
      )}
      <div
        className={classnames(styles.buttonsContainer, {
          [styles.spaceBetween]: theme === 'spaceBetween',
        })}
      >
        <Button
          disabled={disabled}
          text={translation[submitText] || submitText}
          theme={theme === 'modalProfileSubmit' ? theme : 'modalSubmit'}
          onClick={onSubmit}
          className={classnames({
            [styles.spaceBetweenNext]: theme === 'spaceBetween',
            [styles.redTextCloseButton]: theme === 'error',
            [styles.understandButton]: theme === 'understand',
          })}
        />
        {onCancel && (
          <Button
            text={translation[cancelText] || cancelText}
            type="button"
            theme="back"
            onClick={onCancel}
            className={theme === 'spaceBetween' ? styles.spaceBetweenBack : ''}
          />
        )}
        {bottomText && bottomText}
      </div>
    </div>
  )
}
