import {ErrorType} from './BaseType'

// REPORT TYPES
export const DSA_REPORT_LOCATIONS_MESSAGES = {
  JOB_AD: 'job-ad',
  COMPANY_PROFILE: 'company-profile',
  SEEKER_PROFILE: 'seeker-profile',
  JOB_APPLICATION: 'job-application', //TODO:Danijel ask Nikola
  INTERVIEW_INVITATION: 'interview-invitation',
  INTERVIEW_CANCELATION: 'interview-cancelation',
  JOB_OFFER: 'job-offer',
} as const

export type DSAReportLocationsType =
  typeof DSA_REPORT_LOCATIONS_MESSAGES[keyof typeof DSA_REPORT_LOCATIONS_MESSAGES]

export type DSAReportReasonsType =
  | 'animal-welfare'
  | 'data-protection-and-privacy-violations'
  | 'illegal-or-harmful-speech'
  | 'intellectual-property-infringements'
  | 'negative-effects-on-civil-discourse-or-elections'
  | 'non-consensual-behaviour'
  | 'online-bulling-intimidation'
  | 'pornography-or-sexualized-content'
  | 'protection-of-minors'
  | 'risk-for-public-security'
  | 'scams-or-fraud'
  | 'self-harm'
  | 'scope-of-platform-service'
  | 'unsafe-or-illegal-products'
  | 'violence'
  | 'other'
  | ''

export interface DSAReportPayload {
  reportLocation: ReportLocation
  reportReason: DSAReportReasonsType
  reportReasonOther: string
  reporter: string
  email: string
  screenShotPictureId: string
}

interface ReportLocationBase {
  location: DSAReportLocationsType
}

export type ReportLocationId =
  | {
      jobId: string
      seekerId?: never
      companySeekerJourneyContactRequestId?: never
      companySeekerJourneyOfferRequestId?: never
      companyId?: never
    }
  | {
      seekerId: string
      jobId?: never
      companySeekerJourneyContactRequestId?: never
      companySeekerJourneyOfferRequestId?: never
      companyId?: never
    }
  | {
      companySeekerJourneyContactRequestId: string
      jobId?: never
      seekerId?: never
      companySeekerJourneyOfferRequestId?: never
      companyId?: never
    }
  | {
      companySeekerJourneyOfferRequestId: string
      jobId?: never
      seekerId?: never
      companySeekerJourneyContactRequestId?: never
      companyId?: never
    }
  | {
      companyId: string
      jobId?: never
      seekerId?: never
      companySeekerJourneyContactRequestId?: never
      companySeekerJourneyOfferRequestId?: never
    }

type ReportLocation = ReportLocationBase & ReportLocationId

export interface DSAReportResponse {
  id: string
  reportLocation: ReportLocation
  reportReason: DSAReportReasonsType
  reportReasonOther: string
  reporter: string
  email: string
}

// APPEAL TYPES
export interface DSAAppealPayloadType {
  email: string
  appealer: string
  reason: string
  reportId: string
}

// STATE
export interface DSAStateType {
  data: {
    report: DSAReportResponse | undefined
    reportSuccess: boolean
    appeal: DSAReportResponse | undefined
    appealSuccess: boolean
  }
  loading?: boolean
  error: ErrorType | undefined
}
