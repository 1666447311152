import {CircularProgress} from '@mui/material'
import Logo from 'Assets/Images/preferenca-logo.svg'
import TextError from 'Components/Error/TextError'
import {ErrorType} from 'Interfaces'
import {FC, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'

import styles from './Auth.module.scss'

interface AuthProps {
  error?: ErrorType | null
  ssoCallback: (data: {code: string; state: string}) => void
}

export const Auth: FC<AuthProps> = ({error, ssoCallback}) => {
  const isMobile = useMediaQuery({maxWidth: 767})

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const code = params.get('code')
    const state = params.get('state')

    if (code && state) {
      ssoCallback({code: code, state: state})
    }
  }, [])

  return (
    <div className={styles.authContainer}>
      <div className={styles.logo}>
        <img src={Logo} alt={'Preferenca logo'} />
      </div>
      {error ? (
        <TextError text={error.message} className={styles.error} />
      ) : (
        <div className={styles.loaderContainer}>
          <CircularProgress
            className={styles.loader}
            size={isMobile ? 45 : 60}
          />
        </div>
      )}
    </div>
  )
}
