export const reportReasonsData = [
  {
    id: 'animal-welfare',
    translation: 'Animal welfare',
    description: 'Animal harm, Unlawful sale of animals',
  },
  {
    id: 'data-protection-and-privacy-violations',
    translation: 'Data protection and privacy violations',
    description:
      'Biometric data breach, Missing processing ground for data, Right to be forgotten, Data falsification',
  },
  {
    id: 'illegal-or-harmful-speech',
    translation: 'Illegal or harmful speech',
    description: 'Defamation, Discrimination, Hate speech',
  },
  {
    id: 'intellectual-property-infringements',
    translation: 'Intellectual property infringements',
    description:
      'Copyright infringement, Design infringement, Geographical indications infringements, Patent infringement, Trade secret infringement, Trademark infringement',
  },
  {
    id: 'negative-effects-on-civil-discourse-or-elections',
    translation: 'Negative effects on civic discourse or elections',
    description: 'Dezinformacije, manipulacija tujih informacij',
  },
  {
    id: 'non-consensual-behaviour',
    translation: 'Non-consensual behaviour',
    description:
      'Non-consensual image sharing, Non-consensual items containing deepfake or similar technology using a third party’s features',
  },
  {
    id: 'online-bulling-intimidation',
    translation: 'Online bullying/intimidation',
    description: '',
  },
  {
    id: 'pornography-or-sexualized-content',
    translation: 'Pornography or sexualized content',
    description: '',
  },
  {
    id: 'protection-of-minors',
    translation: 'Protection of minors',
    description:
      'Age-specific restrictions concerning minors, Child sexual abuse material, Grooming/sexual enticement of minors, Unsafe challenges',
  },
  {
    id: 'risk-for-public-security',
    translation: 'Risk for public security',
    description:
      'Illegal organizations, Risk for environmental damage, Risk for public health, Terrorist content',
  },
  {
    id: 'scams-or-fraud',
    translation: 'Scams and/or fraud',
    description:
      'Inauthentic accounts, Inauthentic listings, Inauthentic user reviews, Impersonation or account hijacking, Phishing, Pyramid schemes',
  },
  {
    id: 'self-harm',
    translation: 'Self-harm',
    description:
      'Content promoting, eating disorders, Self-mutilation, Suicide',
  },
  {
    id: 'scope-of-platform-service',
    translation: 'Scope of platform service',
    description: 'Goods/services not permitted to be offered on the platform',
  },
  {
    id: 'unsafe-or-illegal-products',
    translation: 'Unsafe and/or illegal products',
    description: '',
  },
  {id: 'violence', translation: 'Violence', description: ''},
  {id: 'other', translation: 'Other', description: ''},
]
