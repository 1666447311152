import {all, put, takeLatest, call} from 'redux-saga/effects'
import * as type from '../types'
import API from 'Services/API'
import {DSAAppealPayloadType, DSAReportPayload} from 'Interfaces/DSAType'
import {ActionType} from 'Interfaces'

function* createReport(action: ActionType<DSAReportPayload>) {
  const {response, error} = yield call(API.post, '/reports', action.payload)

  if (response) {
    yield put({
      type: type.dsaActionTypes.createReport.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.dsaActionTypes.createReport.failed,
      payload: error,
    })
  }
}

function* createAppeal(action: ActionType<DSAAppealPayloadType>) {
  if (!action.payload?.reportId) {
    return
  }
  const {reportId, ...appealPayload} = action.payload

  const {response, error} = yield call(
    API.post,
    `/reports/${reportId}/appeal`,
    appealPayload
  )

  if (response) {
    yield put({
      type: type.dsaActionTypes.createAppeal.succeeded,
      payload: response, //TODO
    })
  } else {
    yield put({
      type: type.dsaActionTypes.createAppeal.failed,
      payload: error,
    })
  }
}

export default function* DSASaga(): Generator {
  yield all([
    takeLatest(type.dsaActionTypes.createReport.requested, createReport),
    takeLatest(type.dsaActionTypes.createAppeal.requested, createAppeal),
  ])
}
