import {all, call, put, takeLatest, select} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import {
  CompanyProfileHeaderSaveValue,
  WelcomeMessageDataFormikType,
  ActionType,
  EmploymentInformationItem,
  CompanyDescriptionItem,
  QuestionValue,
  CompanyBenefitsSaveType,
  ImpressionsAddType,
  ImpressionsUpdateType,
  MeType,
  VideoType,
} from 'Interfaces'
import {
  packWelcomeMessagesUpdateValues,
  packWelcomeMessageValues,
} from 'Store/Packing/Company'

function* getCompanyData(action: ActionType<string>) {
  let companyId = action.payload as string
  if (!companyId) {
    const me: MeType = yield select((state) => state.auth.data)
    companyId = me?.company?.id as string
  }
  const {response, error} = yield call(API.get, `/company/${companyId}`)
  if (response) {
    yield put({
      type: type.company.data.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.company.data.failed, payload: error})
  }
}
//
// function* addCompanyProfileHeader(
//   action: ActionType<CompanyProfileHeaderType>
// ) {
//   if (!action.payload) return
// const {response, error} = yield call(
//   API.patch,
//   '/company/42bc7ec1-484f-4d87-812b-ff6a6fa774fe/header',
//   {
//     ...action.payload,
//   }
// )
// if (response) {
//   yield put({
//     type: type.SEEKER_COMPANYPROFILEHEADER_ADD_SUCCEEDED,
//     payload: {...action.payload, id: response.data.id},
//   })
// } else yield put({type: type.SEEKER_COMPANYPROFILEHEADER_ADD_FAILED, error})
// }
function* addCompanyProfileHeader(
  action: ActionType<CompanyProfileHeaderSaveValue>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}}/cover-image`,
    action.payload
  )
  if (response) {
    yield put({
      type: type.company.header.add.succeeded,
      payload: response,
    })
  } else yield put({type: type.company.header.add.failed, payload: error})
}

function* editCompanyProfileHeader(
  action: ActionType<CompanyProfileHeaderSaveValue>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/header`,
    action.payload
  )
  if (response) {
    yield put({
      type: type.company.header.edit.succeeded,
      payload: response.data,
    })
  } else yield put({type: type.company.header.edit.failed, payload: error})
}

function* addWelcomeMessage(action: ActionType<WelcomeMessageDataFormikType>) {
  if (!action.payload) return
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/${companyId}/welcome-message`,
    packWelcomeMessageValues(action.payload)
  )
  if (response) {
    yield put({
      type: type.company.welcomeMessage.add.succeeded,
      payload: response.data,
    })
  } else
    yield put({type: type.company.welcomeMessage.add.failed, payload: error})
}

function* editWelcomeMessage(action: ActionType<WelcomeMessageDataFormikType>) {
  if (!action.payload) return
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/welcome-message`,
    packWelcomeMessagesUpdateValues(action.payload)
  )
  if (response) {
    yield put({
      type: type.company.welcomeMessage.edit.succeeded,
      payload: response.data,
    })
  } else
    yield put({type: type.company.welcomeMessage.edit.failed, payload: error})
}

function* sendQuestions(action: ActionType<QuestionValue[]>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/${companyId}/question`,
    action.payload
  )
  if (response) {
    yield put({
      type: type.company.questions.save.succeeded,
      payload: response.data.items,
    })
  } else yield put({type: type.company.questions.save.failed, payload: error})
}
function* saveCompanyDescription(action: ActionType<CompanyDescriptionItem>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/description`,
    {
      aboutCompany: action.payload?.aboutCompany,
      mainActivities: action.payload?.mainActivities,
      visionAndGoals: action.payload?.visionAndGoals,
      structureAndCulture: action.payload?.structureAndCulture,
      customCompanyDescription: action.payload?.customCompanyDescription,
    }
  )
  if (response) {
    yield put({
      type: type.company.companyDescription.save.succeeded,
      payload: {...response.data},
    })
  } else
    yield put({
      type: type.company.companyDescription.save.failed,
      payload: error,
    })
}

function* CompanyDescriptionBiziSi() {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.get,
    `/company/${companyId}/bizi-si-info`
  )

  if (response) {
    yield put({
      type: type.company.companyDescription.biziSi.succeeded,
      payload: {
        titleBiziSi: response.data.title,
        addressBiziSi: response.data.address,
        cityBiziSi: response.data.city,
        postalCodeBiziSi: response.data.postalCode,
        vatNumberBiziSi: response.data.vatNumber,
        registrationNumberBiziSi: response.data.registrationNumber,
        incomeBiziSi: response.data.income,
        companySizeBiziSi: response.data.companySize,
        industryBiziSi: response.data.industry,
        companyLinkBiziSi: response.data.companyLink,
        logoUrlBiziSi: response.data.logoUrl,
      },
    })
  } else
    yield put({
      type: type.company.companyDescription.biziSi.failed,
      payload: error,
    })
}

function* savePortfolio(action: ActionType<string>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/portfolio`,
    {portfolio: action.payload !== '<p></p>\n' ? action.payload : ''}
  )
  if (response) {
    yield put({
      type: type.company.portfolio.save.succeeded,
      payload: response.data.portfolio,
    })
  } else {
    yield put({type: type.company.portfolio.save.failed, payload: error})
  }
}

function* saveBenefits(action: ActionType<CompanyBenefitsSaveType>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/benefit`,
    {
      benefitsText: action.payload?.benefitsText,
      customBenefitsTextHtml: action.payload?.customBenefitsTextHtml,
      additionalBenefitIds: action.payload?.additionalBenefitIds,
    }
  )

  if (response) {
    yield put({
      type: type.company.benefits.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.company.benefits.save.failed, payload: error})
  }
}

function* saveEmploymentInformation(
  action: ActionType<EmploymentInformationItem>
) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/employment-information`,

    {
      whatWeExpect: action.payload?.employmentInformationWhatWeExpect,
      whatWeOffer: action.payload?.employmentInformationWhatWeOffer,
      customTextHtml:
        action.payload?.employmentInformationCustomTextHtml === '<p></p>\n'
          ? ''
          : action.payload?.employmentInformationCustomTextHtml,
    }
  )

  if (response) {
    yield put({
      type: type.company.employmentInformation.save.succeeded,

      payload: {
        employmentInformationWhatWeExpect: response.data.whatWeExpect,
        employmentInformationWhatWeOffer: response.data.whatWeOffer,
        employmentInformationCustomTextHtml: response.data.customTextHtml,
      },
    })
  } else
    yield put({
      type: type.company.employmentInformation.save.failed,
      payload: error,
    })
}

function* addImpressions(action: ActionType<ImpressionsAddType[]>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/${companyId}/company-impressions`,
    action.payload
  )

  if (response) {
    yield put({
      type: type.company.impressions.add.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.company.impressions.add.failed, payload: error})
  }
}
function* updateImpressions(action: ActionType<ImpressionsUpdateType[]>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/company-impressions`,
    action.payload
  )

  if (response) {
    yield put({
      type: type.company.impressions.update.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.company.impressions.update.failed, payload: error})
  }
}

function* addVideos(action: ActionType<VideoType[]>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.post,
    `/company/${companyId}/video`,
    action.payload
  )

  if (response) {
    yield put({
      type: type.company.videos.add.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.company.videos.add.failed, payload: error})
  }
}
function* updateVideos(action: ActionType<VideoType[]>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/video`,
    action.payload
  )

  if (response) {
    yield put({
      type: type.company.videos.update.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.company.videos.update.failed, payload: error})
  }
}
function* saveLogo(action: ActionType<{fileId: string}>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/logotype`,
    {fileId: action.payload}
  )

  if (response) {
    yield put({
      type: type.company.logotype.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.company.logotype.save.failed, payload: error})
  }
}
function* saveCover(action: ActionType<{fileId: string}>) {
  const me: MeType = yield select((state) => state.auth.data)
  const companyId = me?.company?.id
  const {response, error} = yield call(
    API.patch,
    `/company/${companyId}/cover-image`,
    {fileId: action.payload}
  )

  if (response) {
    yield put({
      type: type.company.coverImage.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.company.coverImage.save.failed, payload: error})
  }
}

export default function* CompanySaga(): Generator {
  yield all([
    takeLatest(type.company.data.requested, getCompanyData),
    takeLatest(type.company.header.edit.requested, editCompanyProfileHeader),
    takeLatest(type.company.header.add.requested, addCompanyProfileHeader),
    takeLatest(type.company.welcomeMessage.add.requested, addWelcomeMessage),
    takeLatest(type.company.welcomeMessage.edit.requested, editWelcomeMessage),
    takeLatest(
      type.company.companyDescription.save.requested,
      saveCompanyDescription
    ),
    takeLatest(
      type.company.companyDescription.biziSi.requested,
      CompanyDescriptionBiziSi
    ),
    takeLatest(type.company.portfolio.save.requested, savePortfolio),
    takeLatest(type.company.benefits.save.requested, saveBenefits),
    takeLatest(
      type.company.employmentInformation.save.requested,
      saveEmploymentInformation
    ),
    takeLatest(type.company.questions.save.requested, sendQuestions),
    takeLatest(type.company.impressions.add.requested, addImpressions),
    takeLatest(type.company.impressions.update.requested, updateImpressions),
    takeLatest(type.company.videos.add.requested, addVideos),
    takeLatest(type.company.videos.update.requested, updateVideos),
    takeLatest(type.company.logotype.save.requested, saveLogo),
    takeLatest(type.company.coverImage.save.requested, saveCover),
  ])
}
