import {blacklistsettings} from './Blacklist/sl'
import {languagesettings} from './Language/sl'
import {settingsdropdown} from './SettingsDropdown/sl'
import {myAccount} from './MyAccount/sl'

export const settings = {
  ...languagesettings,
  ...blacklistsettings,
  ...settingsdropdown,
  ...myAccount,
}
