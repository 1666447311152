import {BaseTaxonomyType} from 'Interfaces'
import {useCallback, useMemo} from 'react'
import {getAllTranslations} from 'Services/I18n/Utils'
import {useIntl} from 'react-intl'

export const useDynamicTranslation = () => {
  const {formatMessage} = useIntl()
  return useCallback(
    (text?: string | null, values?: {[name: string]: string}) =>
      text ? formatMessage({id: text}, values) : '',
    []
  )
}
export const useTranslation = () => {
  return useMemo(() => getAllTranslations(), [getAllTranslations])
}

export const useTranslate = () => {
  const memo = useMemo(() => getAllTranslations(), [getAllTranslations])
  return (id?: string | null) => memo[id] || null
}

export const useTranslatedBaseTaxonomyArray = (items: BaseTaxonomyType[]) => {
  const translations = useTranslation()

  return useMemo(
    () =>
      items.map(({id, translation: translationId}) => ({
        id,
        translation: translations[translationId],
      })),
    [items]
  )
}
