import {call, takeLatest, all, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import qs from 'qs'

import API from 'Services/API'
import * as type from 'Store/types'
import {
  ActionType,
  InviteFriendFormikType,
  // TestimonialsUserType,
  LogGuestConsentChangeType,
  UpdateNewsletterConsentType,
  GetConsentsParamsType,
} from 'Interfaces'
import {employerTestimonials} from 'Store/MockedData/TestimonialsDataMock'
import {
  packConsentUnsubscribeReasons,
  packConsentUnsubscribeResponse,
} from 'Store/Packing'

function* inviteFriend(action: ActionType<InviteFriendFormikType>) {
  const {response, error} = yield call(
    API.post,
    `/public/send-email-to-friend`,
    action.payload
  )

  if (response) {
    yield put({
      type: type.homepage.inviteFriend.succeeded,
    })
  } else {
    yield put({
      type: type.homepage.inviteFriend.failed,
      payload: error,
    })
  }
}

function* getTestimonials() {
// function* getTestimonials(action: ActionType<TestimonialsUserType>) {
  // let testimonialsUrl = `/public/seeker/testimonials`
  // if (action.payload === TestimonialsUserType.COMPANY_USER) {
  //   testimonialsUrl = `/public/company-user/testimonials`
  // }

  // const {response, error} = yield call(API.get, testimonialsUrl)
  // TODO: Remove when images serve gets implemented
  const response = {}
  const error = {}

  if (response) {
    yield put({
      type: type.homepage.testimonials.succeeded,
      // payload: response.data.items,
      payload: employerTestimonials,
    })
  } else {
    yield put({
      type: type.homepage.testimonials.failed,
      payload: error,
    })
  }
}

function* getTopCompanies() {
  const {response, error} = yield call(API.get, `/companies/top`)

  if (response) {
    yield put({
      type: type.homepage.topCompanies.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.homepage.topCompanies.failed,
      payload: error,
    })
  }
}

function* getNewsletterConsent(action: ActionType<GetConsentsParamsType>) {
  const {response, error} = yield call(
    API.get,
    `/consent/newsletter-consents?${qs.stringify(action.payload)}`
  )

  if (response) {
    yield put({
      type: type.homepage.consents.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.homepage.consents.failed,
      payload: error,
    })
  }
}

function* updateNewsletterConsent(
  action: ActionType<UpdateNewsletterConsentType>
) {
  const {email, hash, consents} = action.payload as UpdateNewsletterConsentType
  const {response, error} = yield call(
    API.patch,
    `/consent/change-newsletter-consents-status?${qs.stringify({email, hash})}`,
    consents
  )

  if (response) {
    yield put({
      type: type.homepage.updateConsents.succeeded,
      payload: packConsentUnsubscribeResponse(response.data.items),
    })
    yield put(push(getRoute(AppRoute.NewsletterUnsubscribeReason)))
  } else {
    yield put({
      type: type.homepage.updateConsents.failed,
      payload: error,
    })
  }
}

function* updateUnsubscribeReasons(
  action: ActionType<LogGuestConsentChangeType>
) {
  const {response, error} = yield call(
    API.post,
    `/consent/save-consent-change-log-reasons`,
    packConsentUnsubscribeReasons(action.payload as LogGuestConsentChangeType)
  )

  if (response) {
    yield put({
      type: type.homepage.logConsentReason.succeeded,
    })
  } else {
    yield put({
      type: type.homepage.logConsentReason.failed,
      payload: error,
    })
  }
}

export default function* HomepageSaga(): Generator {
  yield all([
    takeLatest(type.homepage.inviteFriend.requested, inviteFriend),
    takeLatest(type.homepage.testimonials.requested, getTestimonials),
    takeLatest(type.homepage.topCompanies.requested, getTopCompanies),
    takeLatest(type.homepage.consents.requested, getNewsletterConsent),
    takeLatest(type.homepage.updateConsents.requested, updateNewsletterConsent),
    takeLatest(
      type.homepage.logConsentReason.requested,
      updateUnsubscribeReasons
    ),
  ])
}
