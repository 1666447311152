import {Warning} from '@mui/icons-material'
import Modal, {SubmitFooter} from 'Components/Modal'
import styles from './ReportSuccessModal.module.scss'
import Title from 'Components/Title'
import {FormattedMessage} from 'react-intl'
import {Box} from '@mui/material'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {removeReportSuccess} from 'Store/Actions'
import {useDynamicTranslation} from 'Hooks'

interface ReportModalProps {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
  dsaReportId: string
}

// DSA - report success modal content
export const ReportSuccessModal = ({
  isOpen,
  setIsOpen,
  dsaReportId,
}: ReportModalProps) => {
  const dispatch = useDispatch()
  const translate = useDynamicTranslation()

  useEffect(() => {
    return () => {
      dispatch(removeReportSuccess())
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        setIsOpen(false)
      }}
      title="Report content"
      icon={Warning}
      ariaHideApp={false}
      className={styles.reportModal}
      Footer={
        <SubmitFooter
          onSubmit={() => setIsOpen(false)}
          submitText="I understand"
          theme="modalProfileSubmit"
        />
      }
    >
      <Box className={styles.reportModalBox}>
        <Title text="We received your report." />
        <p>
          <FormattedMessage
            id={translate(
              `The report is managed under task number #{reportId} and is already under review by our moderation team. If you have not provided your email address in your report, please make a note of the application reference number, as you will need it in case you wish to appeal against our decision on your application.`,
              {
                reportId: dsaReportId,
              }
            )}
          />
        </p>
      </Box>
    </Modal>
  )
}
