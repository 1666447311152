import * as type from 'Store/types'
import {
  ActionType,
  AttachmentType,
  AuthRoutingType,
  AuthStateType,
  ConsentType,
  EducationSaveValueType,
  EmployerProfileType,
  ErrorType,
  ImageType,
  LoginType,
  MeType,
  RegistrationType,
  RjbProfileStatus,
  SeekerProfileType,
  SkillType,
  UserUpdateBioType,
  UserWelcomeDataType,
} from 'Interfaces'

const initialState = {
  data: undefined,
  consents: [],
  seekerConsents: [],
  welcomePageData: {
    data: null,
    error: null,
  },
  loading: false,
  error: undefined,
}

const AuthReducer = (
  state: AuthStateType = initialState,
  action: ActionType<
    | MeType
    | ErrorType
    | RegistrationType
    | LoginType
    | AuthRoutingType
    | AttachmentType
    | SkillType
    | UserUpdateBioType
    | EducationSaveValueType
    | SeekerProfileType
    | ConsentType[]
    | RjbProfileStatus
  >
): AuthStateType => {
  switch (action.type) {
    case type.USER_FETCH_REQUESTED:
    case type.SEEKER_LOGIN_REQUESTED:
    case type.EMPLOYER_LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
        dirty: true,
        error: undefined,
      }
    case type.seeker.getSeekerLoginConsents.requested:
    case type.seeker.acceptSeeekerLoginConsents.requested:
    case type.employer.getLoginConsents.requested:
    case type.employer.acceptLoginConsents.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.USER_FETCH_SUCCEEDED:
    case type.USER_LOGIN_SUCCEEDED:
    case type.seeker.register.succeeded:
      return {
        ...state,
        data: action.payload as MeType,
        loading: false,
        error: undefined,
      }
    case type.auth.rjbProfileStatus.update:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          rjbProfileStatus: action.payload as RjbProfileStatus,
        },
      }
    case type.seeker.personalData.save.succeeded:
    case type.employer.personalData.save.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          profilePicture: (action.payload as EmployerProfileType)
            ?.profilePicture as ImageType,
          firstName: (action.payload as SeekerProfileType)?.firstName as string,
          lastName: (action.payload as SeekerProfileType)?.lastName as string,
        },
        loading: false,
        error: undefined,
      }

    case type.systemLanguageUpdate.succeeded:
      return {
        ...state,
        data: {...(state.data as MeType), languageId: action.payload as string},
        loading: false,
        error: undefined,
      }
    case type.contentLanguageUpdate.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          contentLanguageId: action.payload as string,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.confirmProfile.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.auth.confirmProfile.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          rjbProfileStatus: action.payload as RjbProfileStatus,
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.getSeekerLoginConsents.succeeded:
    case type.seeker.acceptSeeekerLoginConsents.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          userConsents: action?.payload as ConsentType[],
        },
        loading: false,
        error: undefined,
      }
    case type.employer.getLoginConsents.succeeded:
    case type.employer.acceptLoginConsents.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          companyConsents: action?.payload as ConsentType[],
        },
        loading: false,
        error: undefined,
      }
    case type.employerOnboarding.stepHiringPlan.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          companyStructure: {
            ...state.data,
            company: {
              ...state.data?.company,
              companyOnboardingStatus: 'done',
            },
          },
        } as MeType,
      }
    case type.auth.welcomePageData.succeeded:
      return {
        ...state,
        welcomePageData: {
          data: action.payload as UserWelcomeDataType,
          error: null,
        },
      }
    case type.auth.welcomePageData.failed:
      return {
        ...state,
        welcomePageData: {data: null, error: action.payload as ErrorType},
      }

    case type.auth.confirmProfile.failed:
    case type.USER_FETCH_FAILED:
    case type.USER_LOGIN_FAILED:
    case type.systemLanguageUpdate.failed:
    case type.contentLanguageUpdate.failed:
    case type.seeker.getSeekerLoginConsents.failed:
    case type.seeker.acceptSeeekerLoginConsents.failed:
    case type.employer.getLoginConsents.failed:
    case type.employer.acceptLoginConsents.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.USER_LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default AuthReducer
