import {connect} from 'react-redux'
import {RootState} from 'Store/Reducers'

import {Auth} from './Auth'
import {ssoCallback} from 'Store/Actions'
import {AppDispatch} from 'Store'

const mapStateToProps = (state: RootState) => ({
  error: state.auth.welcomePageData?.error,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  ssoCallback: (data: {code: string; state: string}) =>
    dispatch(ssoCallback(data)),
})

export const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth)
