import {Check, PriorityHigh} from '@mui/icons-material'
import clsx from 'clsx'
import {FC} from 'react'

import styles from './AlertComponent.module.scss'
import {FormattedMessage} from 'react-intl'

interface AlertComponentProps {
  iconName?: 'PriorityHigh' | 'Check'
  text: string
  theme?: 'horizontal'
}

const AlertComponent: FC<AlertComponentProps> = ({text, iconName, theme}) => {
  const icons = {
    PriorityHigh,
    Check,
  }

  const Icon = iconName ? icons[iconName] : null
  return (
    <div
      className={clsx(styles.alertContainer, {
        [styles.horizontalLayout]: theme === 'horizontal',
      })}
    >
      {Icon && (
        <div
          className={clsx(styles.iconWrapper, {
            [styles.successIconWrapper]: iconName === 'Check',
            [styles.errorIconWrapper]: iconName === 'PriorityHigh',
          })}
        >
          <Icon />
        </div>
      )}
      <p>
        <FormattedMessage id={text} />
      </p>
    </div>
  )
}

export default AlertComponent
