import {ChangeEvent, FC, useState, useRef} from 'react'
import styles from './TextArea.module.scss'
import classnames from 'classnames'
import TextError from 'Components/Error/TextError'
import classNames from 'classnames'
import {useTranslation} from 'Hooks'

interface Props {
  name: string
  value?: string
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  placeholderText?: string
  className?: string
  error?: string
  theme?: 'white' | 'mediumBorderRadiusTextArea' | 'grey' | 'modal'
  type?: 'letterCalculator' | 'letterCalculatorDark'
  maximumCharactersNumber?: number
  horizontalLabel?: string
  verticalLabel?: string
  rows?: number
}

export const TextArea: FC<Props> = ({
  name,
  value = '',
  onChange,
  className,
  placeholderText,
  error,
  theme = 'grey',
  type = '',
  maximumCharactersNumber,
  horizontalLabel,
  verticalLabel,
  rows,
}) => {
  const [charactersNumber, setCharactersNumber] = useState(
    value ? value.length : 0
  )
  const textAreaElement = useRef<HTMLTextAreaElement>(null)
  const textAreaWrapperElement = useRef<HTMLDivElement>(null)
  const translation = useTranslation()

  // letter calculator handler
  const keyUpHandler = () => {
    textAreaElement.current &&
      setCharactersNumber(textAreaElement.current.value.length)
  }

  const isLetterCounter =
    type === 'letterCalculator' || type === 'letterCalculatorDark'

  return (
    <div
      className={classnames(styles.textAreaContainer, styles[theme], className)}
    >
      <div
        className={classnames(styles[type], {
          [styles.error]:
            (error && isLetterCounter) || (error && verticalLabel),
          [styles.verticalLabelWrapper]: verticalLabel,
        })}
        ref={textAreaWrapperElement}
      >
        <div
          className={classNames(styles.secondDiv, {
            [styles.horizontalLabelInputWrapper]: horizontalLabel,
          })}
        >
          {(horizontalLabel || verticalLabel) && (
            <>
              <label
                className={classnames(styles.horizontalLabel, {
                  [styles.verticalLabel]: verticalLabel,
                })}
                htmlFor={name}
              >
                {horizontalLabel || verticalLabel}
              </label>
              &nbsp;
            </>
          )}
          <textarea
            className={classnames(styles.textArea, className, {
              [styles.error]: error && !isLetterCounter && !verticalLabel,
            })}
            name={name}
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder={translation[placeholderText] || placeholderText}
            onFocus={() => {}}
            onKeyUp={() => isLetterCounter && keyUpHandler()}
            ref={textAreaElement}
            rows={rows}
          />
        </div>

        {isLetterCounter && (
          <div className={styles.charactersCalculator}>
            {charactersNumber} / {maximumCharactersNumber}
          </div>
        )}
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
