import {FC, useEffect, useState} from 'react'
import Breadcrumbs from 'Components/Breadcrumbs'
import {AuthStateType} from 'Interfaces'
import './ReportInformation.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {useIntl} from 'react-intl'
import {ReportInformationTranslations as rit} from 'Services/I18n/Constants'
import {Translation} from 'Components/Translation'
import {AppealModalContainer} from 'Components/AppealModal'
import {DSAStateType} from 'Interfaces/DSAType'
import {AppealSuccessModal} from 'Components/AppealSuccessModal/AppealSuccessModal'

interface ReportInformationProps {
  auth: AuthStateType
  dsa: DSAStateType
}

export const ReportInformation: FC<ReportInformationProps> = ({auth, dsa}) => {
  const {formatMessage} = useIntl()

  const [appealModal, setAppealModal] = useState(false)
  const [appealModalSuccess, setAppealModalSuccess] = useState(false)

  const reportInformationHtml = formatMessage({
    id: rit.reportInformationHtml,
  })
    .replaceAll('#privacy-policy', getRoute(AppRoute.PrivacyPolicy))
    .replaceAll('#terms-and-conditions', getRoute(AppRoute.TermsAndConditions))
    .replaceAll(
      '#data-protection-agreement',
      getRoute(AppRoute.DataProtectionAgreement)
    )

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: 'Pogoji uporabe'},
  ]

  useEffect(() => {
    if (!!dsa.data.appealSuccess) {
      setAppealModalSuccess(true)
    }
  }, [dsa.data.appealSuccess])

  return (
    <div className="termsAndConditionsContainer">
      <DownloadAndPrintHeader
        downloadLink={
          process.env.PUBLIC_URL +
          '/files/splosni_pogoji_uporabe_31.01.2023.pdf'
        }
      />
      {auth.data && (
        <h1 className="termsAndConditionsTermsAndConditionsTitle">
          Information
        </h1>
      )}
      {!auth.data && (
        <div className="termsAndConditionsContainerUp">
          <div className="termsAndConditionsContainerUpTitle">
            <div className="termsAndConditionsTitleWrapper">
              <h1 className="termsAndConditionsTermsAndConditionsTitle">
                Information
              </h1>
            </div>
          </div>
        </div>
      )}
      <div className="termsAndConditionsContainerMiddle">
        <Breadcrumbs
          className="termsAndConditionsSubNavigation"
          breadcrumbs={breadcrumbsArray}
        />
      </div>

      <div className="termsAndConditionsContainerDown">
        <div
          dangerouslySetInnerHTML={{
            __html: reportInformationHtml,
          }}
        />
        <p className="termsAndConditionsContainerDownText">
          <Translation text="If you want to appeal to our decision regarding your report, you can do it" />
          &nbsp;
          <span className="hereText" onClick={() => setAppealModal(true)}>
            <Translation text="here" />.
          </span>
        </p>
      </div>

      <AppealModalContainer
        isOpen={appealModal}
        setIsOpen={(value: boolean) => setAppealModal(value)}
      />
      <AppealSuccessModal
        isOpen={appealModalSuccess}
        setIsOpen={(value: boolean) => setAppealModalSuccess(value)}
      />
    </div>
  )
}
