import {connect} from 'react-redux'

import {ReportInformation} from './ReportInformation'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  dsa: state.dsa,
})

export const ReportInformationContainer = connect(
  mapStateToProps,
  null
)(ReportInformation)
