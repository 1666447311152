import routes from './Routes/sl'
import {seeker} from './Seeker/sl'
import {publicPages} from './PublicPages/sl'
import {settings} from './Settings/sl'
import validation from './Validation/sl'
import {general} from './General/sl'
import {layout} from './Layout/sl'
import {employer} from './Employer/sl'
import {assessments} from './Assessments/sl'
import messageCenter from './MessageCenter/sl'
import {company} from './Company/sl'
import {unsubscribe} from './Unsubscribe/sl'
import {aboutUs} from './AboutUs/sl'
import {identityProvider} from './IdentityProvider/sl'

export const sl = {
  ...routes,
  ...seeker,
  ...settings,
  ...validation,
  ...publicPages,
  ...general,
  ...layout,
  ...employer,
  ...assessments,
  ...messageCenter,
  ...company,
  ...unsubscribe,
  ...aboutUs,
  ...identityProvider,
  emailAddress: 'Elektronski naslov',
  password: 'Geslo',
  next: 'Naprej',
  back: 'Nazaj',
  skills: 'Skills',
  saveChanges: 'Shrani spremembe',
  cancel: 'Prekličite',
  preview: 'Predogled',
  add: 'Dodajte',
  edit: 'Urejanje',
  employer: 'Delodajalec',
  address: 'Naslov',
  'Postal code': 'Poštna številka',
  City: 'Mesto',
  Country: 'Država',
  'Select your country': 'Izberite svojo državo',
  'Phone number': 'Telefonska številka',
  yes: 'Da',
  no: 'Ne',
  'Save changes': 'Shrani spremembe',
  Cancel: 'Prekličite',
  delete: 'Izbriši',
  warning: 'Opozorilo!',
  undefinedInput: ' ',
  'Show fewer': 'Prikaži manj',
  'Show more': 'Prikaži več',
  'Preferenca.com': 'Preferenca.si',
  'Preferenca.si': 'Preferenca.si',
  'Select from list': 'Izberite s seznama',
  'Select value': 'Izberite vrednost',
  'No entry found': 'Noben zapis ne ustreza iskanju',
  Other: 'Ostalo',
  /** Page content */
  'home.title': 'Početna',
  'home.content': 'Početna',
  'First name': 'Ime',
  'Last name': 'Priimek',

  'assessments.title': 'Procene',
  'assessmentssuccess.title': 'Procene uspeha',

  'publicHome.title': 'Početna',
  'homePage.title': 'Početna strana',
  'homePageEmployer.title': 'Zaposleni',
  'homePageCandidate.title': 'Kandidat',
  'modal.modal': 'Modal',

  'summary.title': 'Strana',
  'summary.content': 'Strana tekst',

  'error.title': 'Greska',
  'error.content': 'Greska',

  'alerts.title': 'Alert',
  'lazy.title': 'Lenjo',

  'seekerlogin.title': 'Ulaz',
  'employerlogin.title': 'Ulaz',

  'formikexample.title': 'Formik Primer',

  'onboarding.title': 'Onboarding',

  'uploadtest.title': 'Upload Test',

  'jobpreferences.title': 'Pogodnosti Posla',

  'consents.title': 'Moja Soglasja',

  'companyprofile.title': 'soglasje',
  'termsndconditions.title': 'Določila In Pogoje',

  'workexperience.title': 'Radno Iskustvo',
  'profile.title': 'Profil',
  'publicprofile.title': 'Profil',

  'homepagecandidate.title': 'Home Page Candidate',

  'frequentlyaskedquestions.title': 'Frequently Asked Questions',

  /** Component content */
  'checkbox-error-img.alt': 'ErrorIcon-sl',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  'dragndrop.title': 'dragndrop example',
  'gallery.title': 'gallery example',
  'employerdashboard.title': 'Employer Dashboard',

  'languagesettings.title': 'Nastavitve jezika',
  'logout.title': 'Odjava',
  'calendar.title': 'Kalendar',
  'employercontactrequests.title': 'Employer Contact Requests',
  'employerinterviewinvitations.title': 'Employer Interview Invitations',
  'employeroffers.title': 'Employer Offers',
  'employerplacements.title': 'Employer Placements',
  'reviewcandidate.title': 'Review Candidate',
  Monday: 'Ponedeljek',
  Tuesday: 'Torek',
  Wednesday: 'Sreda',
  Thursday: 'Četrtek',
  Friday: 'Petek',
  Saturday: 'Sobota',
  Sunday: 'Nedelja',
  Daily: 'Dnevno',
  Monthly: 'Mesečno',
  Disabled: 'Onemogočeno',
  monday: 'Ponedeljek',
  tuesday: 'Torek',
  wednesday: 'Sreda',
  thursday: 'Četrtek',
  friday: 'Petek',
  saturday: 'Sobota',
  sunday: 'Nedelja',
  daily: 'Dnevno',
  monthly: 'Mesečno',
  disabled: 'Onemogočeno',
  or: 'ali',
  here: 'tukaj',
  'Log out warning': 'Log out warning',
  'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.':
    'Čez eno minuto boste odjavljeni iz sistema. Če tega ne želite, premaknite miško ali kliknite kamorkoli na strani.',
  ' years old': ' let',
  'You can find this candidate in following projects:':
    'You can find this candidate in following projects:',
  Occupation: 'Delovno mesto',
  'Job category': 'Področje dela',
  Employer: 'Delodajalec',
  Skill: 'Znanje',
  town: 'mesto',
  region: 'regija',
  country: 'država',
  'Preferenca is currently down for maintenance.':
    'Preferenca je trenutno nedostopna zaradi vzdrževanja.',
  'We expect to be back shortly. Thank you for your patience.':
    'Pričakujemo, da se kmalu vrnemo. Hvala za vaše potrpljenje.',
  'Refresh page and check again': 'Osvežite stran in preverite znova',
  Keyword: 'Ključna beseda',
  'Company user login forbidden, company owner action needed':
    'Company user login forbidden, company owner action needed',
  'Wrong identifier or password': 'Napačen elektronski naslov ali geslo.',
  'Company is inactive': 'To podjetje je neaktivno',
  'Animal harm, Unlawful sale of animals':
    'Poškodovanje živali, nezakonita prodaja živali.',
  'Biometric data breach, Missing processing ground for data, Right to be forgotten, Data falsification':
    'Kršitev biometričnih podatkov, pomanjkanje podlage za obdelavo podatkov, pravica do pozabe, ponarejanje podatkov.',
  'Defamation, Discrimination, Hate speech':
    'Obrekovanje, diskriminacija, sovražni govor.',
  'Copyright infringement, Design infringement, Geographical indications infringements, Patent infringement, Trade secret infringement, Trademark infringement':
    'Kršitev avtorskih pravic, kršitev pravic industrijskega oblikovanja, kršitev geografskih označb, kršitev patentov, kršitev poslovne skrivnosti, kršitev blagovnih znamk.',
  'Disinformation, Foreign information manipulation and interference, Misinformation':
    'Dezinformacije, manipulacija tujih informacij.',
  'Non-consensual image sharing, Non-consensual items containing deepfake or similar technology using a third party’s features':
    'Deljenje slik brez soglasja, predmetov brez soglasja, ki vsebujejo deepfake ali podobno tehnologijo z uporabo funkcij tretjih strani.',
  'Age-specific restrictions concerning minors, Child sexual abuse material, Grooming/sexual enticement of minors, Unsafe challenges':
    'Starostne omejitve v zvezi z mladoletniki, gradivo v zvezi s spolno zlorabo otrok, zalezovanje/spolno zvabljanje mladoletnikov.',
  'Illegal organizations, Risk for environmental damage, Risk for public health, Terrorist content':
    'Nezakonite organizacije, nevarnost škode za okolje, nevarnost za javno zdravje, teroristične vsebine.',
  'Inauthentic accounts, Inauthentic listings, Inauthentic user reviews, Impersonation or account hijacking, Phishing, Pyramid schemes':
    'Lažni računi, lažni oglasi, lažne ocene uporabnikov. Lažno predstavljanje ali ugrabitev računa, kraja identitete, piramidne sheme.',
  'Content promoting, eating disorders, Self-mutilation, Suicide':
    'Promocija vsebine v povezavi s samopoškodovanjem.',
  'Goods/services not permitted to be offered on the platform':
    'Blago/storitve, ki jih ni dovoljeno ponujati na platformi.',
  'Reason for reporting the content': 'RAZLOG ZA PRIJAVO VSEBINE',
  'FULL NAME OR COMPANY NAME': 'IME IN PRIIMEK ALI IME PODJETJA',
  'Enter your full name or company name':
    'Vnesite ime in priimek ali ime podjetja',
  EMAIL: 'ELEKTRONSKI NASLOV',
  'Enter email': 'Vnesite elektronski naslov',
  'I confirm that I am making the report voluntarily and that the information and statements contained in the report are accurate and complete.':
    'Potrjujem, da prijavo podajam prostovoljno in v dobri veri ter da so informacije in izjave v prijavi točne in popolne.',
  'We received your report.': 'Potrjujemo, da smo prejeli vašo prijavo.',
  'The report is managed under task number #{reportId} and is already under review by our moderation team. If you have not provided your email address in your report, please make a note of the application reference number, as you will need it in case you wish to appeal against our decision on your application.':
    'Prijavo vodimo pod opravilno številko #{reportId} in je že v preverbi s strani naše moderatorske ekipe. V primeru, da v prijavi niste navedli svojega elektronskega naslova, si zabeležite opravilno številko prijave, saj jo boste potrebovali v primeru morebitne vložitve pritožbe zoper našo odločitev glede podane prijave.',
  'The data you provide will be processed by {platformName} for the purpose of processing your application under Article 6 (1)c (Digital Services Act). You can read more about the processing of personal data':
    'Vaše posredovane podatke bo {platformName} obdelovala za namen obravnave vaše prijave po členu 6 (1)c (Akt o digitalnih storitvah). Več o obdelavi osebnih podatkov si lahko preberete',
  'We received your appeal.': 'Potrjujemo, da smo prejeli vašo pritožbo.',
  'Enter reason for reporting the content.':
    'Vnesite razlog za prijavo vsebine.',
  'Report content': 'Prijava vsebine',
  Report: 'Prijava vsebine',
  'I understand': 'Razumem',
  'Appeal form': 'Obrazec za pritožbo',
  'REPORT ID': 'ID prijave',
  'Please enter Report ID': 'Vnesite številko prijave',
  'APPEAL REASON': 'Razlog za pritožbo',
  'Send Appeal': 'Pošlji pritožbo',
}
