export const identityProvider = {
  'identityProvider.welcomeMessage': 'Welcome to Preferenca',
  'identityProvider.description': 'You are logging in as:',
  'identityProvider.changeUser': 'Change user',
  'identityProvider.next': 'Next',
  'identityProvider.alreadyHaveAccount': 'Already have an account?',
  'identityProvider.loginNow': 'Login now',
  'identityProvider.unauthorizedError':
    'Error: Your account is not authorized to login to employer section of MD portal. Make sure you registered with the email you’ve received invitation to. ',
  'Login or register with email from invite':
    'Login or register with email from invite',
  'identityProvider.privacyNoticePartOne':
    'Your personal data shall be processed by Styria digital marketplaces, d.o.o. for the purpose of your registration and further use of website Preferenca.si. Legal basis for such processing is contract (Article 6(1)b of GDPR). ',
  'identityProvider.privacyNoticePartTwo':
    'constitute a contract under this relationship. In case you do not wish to disclose your personal data, signing to Preferenca.si website and full usage of its services shall not be possible. When you give your consent for receiving e-novice (direct marketing e-mails), we shall process your personal data until your withdrawal. Legal basis for such processing is your consent (Article 6(1)a of GDPR. You can withdraw your consent at any time through link in every e-mail you receive. Please find more information in our privacy notice',
  'identityProvider.privacyPolicy':
    'Privacy and Personal Data Protection Policy',
  'identityProvider.termsAndConditions':
    'Terms and conditions of use Preferenca.si ',
}
