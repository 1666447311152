import {EmployerRegistrationType, RegistrationType} from 'Interfaces'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {authConnectingEmployer, registerSeeker} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

import {WelcomePage} from './WelcomePage'

const mapStateToProps = (state: RootState) => ({
  user: state.auth.welcomePageData?.data,
  auth: state.auth,
  seeker: state.seeker,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  registerSeeker: (data: RegistrationType) => dispatch(registerSeeker(data)),
  authConnectingEmployer: (data: EmployerRegistrationType) =>
    dispatch(authConnectingEmployer(data)),
})

export const WelcomePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomePage)
