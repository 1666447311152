import {UnsubscribeNewsletterPayload} from 'Interfaces'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {unsubscribeNewsletter} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

import {NewsletterUnsubscribeSuccess} from './NewsletterUnsubscribeSuccess'

const mapStateToProps = (state: RootState) => ({
  newsletterSubscription: state.consents.newsletterSubscription,
  loading: state.consents.loading,
  error: state.consents.error?.message,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  unsubscribeNewsletter: (payload: UnsubscribeNewsletterPayload) =>
    dispatch(unsubscribeNewsletter(payload)),
})

export const NewsletterUnsubscribeSuccessContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterUnsubscribeSuccess)
