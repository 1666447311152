import {blacklistsettings} from './Blacklist/en'
import {languagesettings} from './Language/en'
import {settingsdropdown} from './SettingsDropdown/en'
import {myAccount} from './MyAccount/en'

export const settings = {
  ...languagesettings,
  ...blacklistsettings,
  ...settingsdropdown,
  ...myAccount,
}
