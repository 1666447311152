import {FC} from 'react'

import styles from './ErrorPage.module.scss'
import Title from 'Components/Title'
import Logo from 'Assets/Images/preferenca-logo.svg'
import YellowButton from 'Components/YellowButton'
import {ErrorTranslations as et} from 'Services/I18n/Constants/PublicPages/ErrorTranslations'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

export const ErrorPage: FC = () => {
  return (
    <div className={styles.errorPageContainer}>
      <Link to={getRoute(AppRoute.HomePageCandidate)}>
        <div className={styles.logo}>
          <img src={Logo} alt="Preferenca logo" />
        </div>
      </Link>
      <div className={styles.contentContainer}>
        <Title text={et.titleError} />
        <p>
          <FormattedMessage id={et.description} />
        </p>
        <div className={styles.imgBtnContainer}>
          <YellowButton
            text={et.buttonText}
            onClick={() => window.history.back()}
            className={styles.yellowButton}
          />
        </div>
      </div>
    </div>
  )
}
