import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {EditOutlined, TipsAndUpdatesOutlined} from '@mui/icons-material'

import SectionHeader from 'Components/Profile/SectionHeader'
import ShowMoreContent from 'Components/ShowMoreContent'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Hr from 'Components/Hr'
import styles from './EmploymentInformation.module.scss'
import Validation from './Validation'
import {CompanyStateType, EmploymentInformationItem} from 'Interfaces'
import Title from 'Components/Title'
import {CompanyEmploymentInformationTranslations as ceit} from 'Services/I18n/Constants'
import {useApiError, useTranslation} from 'Hooks'
import HTMLInput from 'Components/Inputs/HTMLInput'
import {HTMLText} from 'Components/HTMLText'

interface EmploymentInformationProps {
  company: CompanyStateType
  saveEmploymentInformation: (value: EmploymentInformationItem) => void
  canChange: boolean
}

export const EmploymentInformation: FC<EmploymentInformationProps> = ({
  company,
  saveEmploymentInformation,
  canChange,
}) => {
  const FormikValues: EmploymentInformationItem = {
    employmentInformationWhatWeExpect: company.data
      .employmentInformationWhatWeExpect
      ? company.data.employmentInformationWhatWeExpect
      : '',
    employmentInformationWhatWeOffer: company.data
      .employmentInformationWhatWeOffer
      ? company.data.employmentInformationWhatWeOffer
      : '',
    employmentInformationCustomTextHtml: company.data
      .employmentInformationCustomTextHtml
      ? company.data.employmentInformationCustomTextHtml
      : '',
  }

  let canAdd = false

  if (
    (company.data.employmentInformationWhatWeExpect === null ||
      company.data.employmentInformationWhatWeExpect === '') &&
    (company.data.employmentInformationWhatWeOffer === null ||
      company.data.employmentInformationWhatWeOffer === '') &&
    (company.data.employmentInformationCustomTextHtml === null ||
      company.data.employmentInformationCustomTextHtml === '')
  ) {
    canAdd = true
  }

  const translation = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')

  const openEdit = () => {
    setModalMode('Edit')
    formik.resetForm({
      values: FormikValues,
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: FormikValues,
    })
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: FormikValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      saveEmploymentInformation(values)
      setModalOpen(false)
    },
  })

  useApiError(formik.setFieldError, company.error)

  useEffect(() => {
    if (!company.loading && !company.error && modalOpen) setModalOpen(false)
  }, [company.loading])

  const infoItems = [
    {
      prop: 'employmentInformationWhatWeExpect',
      title: ceit.whatDoWeExpectTitle,
      icon: TipsAndUpdatesOutlined,
    },
    {
      prop: 'employmentInformationWhatWeOffer',
      title: ceit.whatDoWeOfferTitle,
      icon: TipsAndUpdatesOutlined,
    },
    {
      prop: 'employmentInformationCustomTextHtml',
      title: ceit.customEmploymentInformationTitle,
      icon: TipsAndUpdatesOutlined,
      html: true,
    },
  ]

  const visibleInfoItems = useMemo(
    () =>
      infoItems.filter(
        (item) =>
          company.data[item.prop] !== '' && company.data[item.prop] !== null
      ),
    [company.data]
  )
  const childRef = useRef(null)

  return company.data.employmentInformationWhatWeExpect || canChange ? (
    <div className={styles.employmentInformation}>
      <div>
        <SectionHeader
          onAdd={canChange && canAdd ? openAdd : undefined}
          title={ceit.employmentInformationTitle}
          className={styles.header}
        />
      </div>
      {!canAdd && (
        <SectionItem className={styles.sectionItem}>
          <ShowMoreContent
            height={141}
            mobileHeight={141}
            childRef={childRef}
            className={classnames(
              styles.showMoreContent,
              styles.showMoreContentStronger
            )}
          >
            <div className={styles.container}>
              {canChange && (
                <div className={styles.editButtonWrap} onClick={openEdit}>
                  <EditOutlined className={styles.edit} sx={{fontSize: 25}} />
                </div>
              )}

              {visibleInfoItems.map((item, index) => (
                <div
                  ref={!index ? childRef : null}
                  key={`${item.title}-${index}`}
                >
                  {index !== 0 && <Hr className={styles.hrView} />}
                  <ItemHeader
                    title={item.title}
                    icon={item.icon}
                    className={styles.itemHeader}
                  />
                  <div className={styles.text}>
                    <HTMLText htmlInputValue={company.data[item.prop]} />
                  </div>
                </div>
              ))}
            </div>
          </ShowMoreContent>
        </SectionItem>
      )}

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={` ${
          modalMode === 'Add'
            ? translation[ceit.modalAddText]
            : translation[ceit.modalEditText]
        } ${translation[ceit.modalEmploymentInformationTitle]}`}
        icon={TipsAndUpdatesOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title
          text={ceit.modalWhatDoWeExpectTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="employmentInformationWhatWeExpect"
          onChange={formik.setFieldValue}
          value={formik.values.employmentInformationWhatWeExpect || ''}
          error={
            formik.errors.employmentInformationWhatWeExpect
              ? formik.errors.employmentInformationWhatWeExpect
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr
          className={classnames(styles.hr, styles.hrStronger)}
          theme="modal"
        />
        <Title
          text={ceit.modalWhatDoWeOfferTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="employmentInformationWhatWeOffer"
          onChange={formik.setFieldValue}
          value={formik.values.employmentInformationWhatWeOffer || ''}
          error={
            formik.errors.employmentInformationWhatWeOffer
              ? formik.errors.employmentInformationWhatWeOffer
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr
          className={classnames(styles.hr, styles.hrStronger)}
          theme="modal"
        />
        <Title
          text={ceit.modalCustomEmploymentInformationTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="employmentInformationCustomTextHtml"
          onChange={formik.setFieldValue}
          value={formik.values.employmentInformationCustomTextHtml}
          error={
            formik.errors.employmentInformationCustomTextHtml
              ? formik.errors.employmentInformationCustomTextHtml
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr
          className={classnames(styles.hr, styles.hrStronger)}
          theme="modal"
        />
      </Modal>
    </div>
  ) : null
}
