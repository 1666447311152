import {home} from './Home/sl'
import {login} from './Login/sl'
import {registration} from './Registration/sl'
import {onboarding} from './Onboarding/sl'
import {contactData} from './ContactData/sl'
import {consents} from './Consents/sl'
import {profile} from './Profile/sl'
import {candidateDashboard} from './Dashboard/sl'
import {CandidateInfo} from './CandidateInfo/sl'
import {CandidateFAQ} from './CandidateFAQ/sl'

export const seeker = {
  ...home,
  ...login,
  ...registration,
  ...onboarding,
  ...contactData,
  ...consents,
  ...profile,
  ...candidateDashboard,
  ...CandidateInfo,
  ...CandidateFAQ,
}
