export enum IdentityProviderLogin {
  'welcomeMessage' = 'identityProvider.welcomeMessage',
  'description' = 'identityProvider.description',
  'changeUser' = 'identityProvider.changeUser',
  'next' = 'identityProvider.next',
  'alreadyHaveAccount' = 'identityProvider.alreadyHaveAccount',
  'loginNow' = 'identityProvider.loginNow',
  'unauthorizedError' = 'identityProvider.unauthorizedError',
  'privacyNoticePartOne' = 'identityProvider.privacyNoticePartOne',
  'privacyNoticePartTwo' = 'identityProvider.privacyNoticePartTwo',
  'privacyPolicy' = 'identityProvider.privacyPolicy',
  'termsAndConditions' = 'identityProvider.termsAndConditions',
}
