import * as type from '../types'
import {
  ActionType,
  ChangeConsentStatusType,
  ChangeSelbyMillsConsentStatusType,
  UnsubscribeNewsletterPayload,
} from 'Interfaces'

export const getConsents = () => {
  return {
    type: type.consents.getConsents.requested,
  }
}

export const changeConsentStatus = (
  data: ChangeConsentStatusType
): ActionType<ChangeConsentStatusType> => {
  return {
    type: type.consents.changeConsentStatus.requested,
    payload: data,
  }
}

export const changeShelbyMillsConsentStatus = (
  data: ChangeSelbyMillsConsentStatusType
): ActionType<ChangeSelbyMillsConsentStatusType> => {
  return {
    type: type.consents.changeShelbyMillsConsentStatus.requested,
    payload: data,
  }
}

export const changeCognitiveConsentStatus = (
  data: ChangeConsentStatusType
): ActionType<ChangeConsentStatusType> => {
  return {
    type: type.consents.changeCognitiveConsentStatus.requested,
    payload: data,
  }
}

export const unsubscribeNewsletter = (
  payload: UnsubscribeNewsletterPayload
): ActionType<UnsubscribeNewsletterPayload> => {
  return {
    type: type.consents.unsubscribeNewsletter.requested,
    payload,
  }
}
