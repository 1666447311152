import * as type from '../types'
import {
  ActionType,
  AuthRoutingType,
  ConsentToSend,
  LoginType,
  UserType,
} from 'Interfaces'

export const loginSeeker = (loginData: LoginType): ActionType<LoginType> => {
  return {
    type: type.SEEKER_LOGIN_REQUESTED,
    payload: {
      ...loginData,
    },
  }
}

export const loginEmployer = (loginData: LoginType): ActionType<LoginType> => {
  return {
    type: type.EMPLOYER_LOGIN_REQUESTED,
    payload: {
      ...loginData,
    },
  }
}

export const getEmployerLoginConsents = (): ActionType<undefined> => {
  return {
    type: type.employer.getLoginConsents.requested,
  }
}
export const getSeekerLoginConsents = (): ActionType<undefined> => {
  return {
    type: type.seeker.getSeekerLoginConsents.requested,
  }
}

export const acceptEmployerLoginConsents = (
  values: ConsentToSend[]
): ActionType<ConsentToSend[]> => {
  return {
    type: type.employer.acceptLoginConsents.requested,
    payload: values,
  }
}
export const acceptSeekerLoginConsents = (
  values: ConsentToSend[]
): ActionType<ConsentToSend[]> => {
  return {
    type: type.seeker.acceptSeeekerLoginConsents.requested,
    payload: values,
  }
}

export const logoutUser = (role?: UserType): ActionType<UserType> => {
  return {
    type: type.USER_LOGOUT_REQUESTED,
    payload: role || UserType.SEEKER,
  }
}

export const getUser = (
  nextRoute?: AuthRoutingType,
  withoutRedirect?: AuthRoutingType
): ActionType<AuthRoutingType> => {
  return {
    type: type.USER_FETCH_REQUESTED,
    payload: {
      ...nextRoute,
      ...withoutRedirect,
    },
  }
}

export const confirmProfile = (): ActionType<undefined> => {
  return {
    type: type.auth.confirmProfile.requested,
  }
}

export const pingAuth = (): ActionType<undefined> => {
  return {
    type: type.auth.ping.requested,
  }
}

export const ssoCallback = (values: {
  code: string
  state: string
}): ActionType<{code: string; state: string}> => {
  return {
    type: type.auth.ssoCallback.requested,
    payload: values,
  }
}
