import {DSAAppealPayloadType, DSAReportPayload} from 'Interfaces/DSAType'
import * as type from '../types'
import {ActionType} from 'Interfaces'

export const createReport = (
  payload: DSAReportPayload
): ActionType<DSAReportPayload> => {
  return {
    type: type.dsaActionTypes.createReport.requested,
    payload,
  }
}

export const removeReportSuccess = () => {
  return {
    type: type.dsaActionTypes.removeReportSuccess,
  }
}

export const removeReportError = () => {
  return {
    type: type.dsaActionTypes.removeReportError,
  }
}

export const createAppeal = (
  payload: DSAAppealPayloadType
): ActionType<DSAAppealPayloadType> => {
  return {
    type: type.dsaActionTypes.createAppeal.requested,
    payload,
  }
}

export const removeAppealSuccess = () => {
  return {
    type: type.dsaActionTypes.removeAppealSuccess,
  }
}
