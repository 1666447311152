import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {DSAAppealPayloadType} from 'Interfaces/DSAType'
import {AppealModal} from './AppealModal'
import {createAppeal} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  dsa: state.dsa,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createAppeal: (payload: DSAAppealPayloadType) =>
    dispatch(createAppeal(payload)),
})

export const AppealModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppealModal)
