import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

import {
  DeleteOutline,
  EditOutlined,
  LockOutlined,
  PhotoCameraOutlined,
} from '@mui/icons-material'
import Title from 'Components/Title'
import {
  AuthStateType,
  BaseTaxonomyType,
  EmployerProfileType,
  EmployerStateType,
  PersonalDataSaveType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import styles from './MyAccount.module.scss'
import YellowButton from 'Components/YellowButton'
import TextField from 'Components/Inputs/TextField/'
import {DropdownProps, TextProps} from 'Utils/FormikProps'
import {Dropdown} from 'Components/Inputs/Dropdown/Dropdown'
import {useApiError, useMonths, useMonthTranslation, useYears} from 'Hooks'
import {getRestDaysOfMonth} from 'Utils/Date'
import {AvatarContainer} from 'Components/Avatar'
import {getLang, getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  ContactDataTranslations as cd,
  ContactDataTranslations as cdt,
  DateTranslations as dt,
  GeneralTranslations as gt,
  MyAccountTranslations as mat,
  ProfileTranslations as pt,
  RegistrationTranslations as rt,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {MainHeader} from 'Components/MainHeader/MainHeader'
import PhoneNumberField from 'Components/Inputs/PhoneNumberField'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {SidebarLink} from 'Components/SidebarLink'
import {FileUpload} from 'Components/Inputs/FileUpload/FileUpload'
import TextError from 'Components/Error/TextError'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {TypeAheadFetch} from 'Components/Inputs/TypeAheadFetch'
import {formatedPhoneNumber} from 'Utils/FormatedPhoneNumber'
import {Translation} from 'Components/Translation'
import ValidationEmployer from './ValidationEmployer'
import ValidationSeeker from './ValidationSeeker'
import {getChannel, getIdentityProviderUrl} from 'Utils/CommonHelpers'

type ChangeEmailProps = {
  seeker: SeekerStateType
  auth: AuthStateType
  taxonomies: TaxonomiesStateType
  getSeekerData: () => void
  getEmployerData: () => void
  getTaxonomies: () => void
  saveSeekerPersonalData: (value: PersonalDataSaveType) => void
  saveEmployerPersonalData: (value: PersonalDataSaveType) => void
  routeEdit?: boolean
  employerFullData?: EmployerProfileType
  employer: EmployerStateType
}

export const MyAccount: FC<ChangeEmailProps> = ({
  seeker,
  auth,
  taxonomies,
  getSeekerData,
  getEmployerData,
  getTaxonomies,
  saveSeekerPersonalData,
  saveEmployerPersonalData,
  routeEdit = false,
  employerFullData,
  employer,
}) => {
  const channel = getChannel()
  const identityProviderUrls = channel?.identityProviderUrls
  const lang = getLang()

  const [imgUploadError, setImgUploadError] = useState('')
  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: '/'},
    {
      name: mat.myAccount,
      link: getRoute(
        companyUserRole ? AppRoute.EmployerMyAccount : AppRoute.MyAccount
      ),
    },
  ]
  let birthday: Date | undefined = undefined

  useEffect(() => {
    if (auth?.data && auth?.data && !companyUserRole) {
      getSeekerData()
    } else {
      getEmployerData()
    }
    getTaxonomies()
  }, [])

  const [editMode, setEditMode] = useState(routeEdit)

  if (auth?.data && !companyUserRole) {
    birthday = seeker.data?.full?.dateOfBirth
      ? new Date(seeker.data?.full?.dateOfBirth)
      : undefined
  } else {
    birthday = employerFullData?.dateOfBirth
      ? new Date(employerFullData?.dateOfBirth)
      : undefined
  }

  const translatedBirthdayMonth = useMonthTranslation(
    birthday && birthday.getMonth()
  )
  const birthdayDay = birthday && birthday.getDate()
  const birthdayYear = birthday && birthday.getFullYear()
  const translatedBirthdayToShow = birthday
    ? `${birthdayDay} ${translatedBirthdayMonth} ${birthdayYear}`
    : null

  useEffect(() => {
    const profile =
      auth?.data && auth?.data && !companyUserRole
        ? seeker.data?.full
        : employerFullData
    if (profile) {
      formik.setValues({
        profilePicture: auth.data?.profilePicture,
        firstName: profile.firstName,
        lastName: profile.lastName,
        day: birthday?.getDate() || 0,
        month: birthday?.getMonth() !== undefined ? birthday.getMonth() + 1 : 0,
        year: birthday?.getFullYear() || 0,
        streetAddress: profile.streetAddress,
        postalCode: profile.postalCode,
        homeTown: profile.homeTown,
        homeRegion: profile.homeRegion,
        homeCountry: profile.homeCountry,
        phone: profile.phone ? profile.phone : '+386',
        email: profile.email,
      })
    }
  }, [seeker.data?.full, employerFullData])

  const defaultValues = {
    profilePicture: auth.data?.profilePicture,
    firstName:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.firstName
        : employerFullData?.firstName,
    lastName:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.lastName
        : employerFullData?.lastName,
    day: birthday?.getDate() || 0,
    month: birthday?.getMonth() !== undefined ? birthday.getMonth() + 1 : 0,
    year: birthday?.getFullYear() || 0,
    streetAddress:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.streetAddress
        : employerFullData?.streetAddress,
    postalCode:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.postalCode
        : employerFullData?.postalCode,
    homeTown:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.homeTown
        : employerFullData?.homeTown,
    homeRegion:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.homeRegion
        : employerFullData?.homeRegion,
    homeCountry:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.homeCountry
        : employerFullData?.homeCountry,
    email:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.email
        : employerFullData?.email,
    phone:
      auth?.data && !companyUserRole
        ? seeker.data?.full?.phone
        : employerFullData?.phone
        ? '+386'
        : '+386',
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: companyUserRole ? ValidationEmployer : ValidationSeeker,

    onSubmit: (values) => {
      let data: PersonalDataSaveType = {
        profilePictureId: values.profilePicture?.id,
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: `${values.year}-${values.month}-${values.day}`,
        streetAddress: values.streetAddress,
        postalCode: `${values.postalCode}`,
        homeCountryId: values.homeCountry?.id,
        phone: values.phone,
      }

      if (values.homeTown?.id.includes('newValue~')) {
        data['homeTownName'] = values.homeTown.translation
      } else {
        data['homeTownId'] = values.homeTown?.id || ''
      }

      if (values.homeRegion?.id.includes('newValue~')) {
        data['homeRegionName'] = values.homeRegion.translation
      } else {
        data['homeRegionId'] = values.homeRegion?.id || ''
      }

      if (auth?.data && !companyUserRole) {
        saveSeekerPersonalData(data)
      } else {
        saveEmployerPersonalData(data)
      }
    },
  })

  useEffect(() => {
    const days = getRestDaysOfMonth(formik.values.month, formik.values.year)
    if (!days.includes(formik.values.day) && formik.values.day !== 0) {
      formik.setFieldValue('day', 0)
    }
  }, [formik.values.month])

  const years = useYears(0, 1950)
  const months = useMonths()

  const setNumberDropdownField = (name: string, value?: string | number) => {
    formik.setFieldValue(name, parseInt(value as string))
  }

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  useApiError(
    formik.setFieldError,
    auth?.data && !companyUserRole ? seeker.error : employer.error
  )

  const deletePicture = () => {
    formik.setFieldValue('profilePicture', undefined)
  }

  useEffect(() => {
    if (!seeker.loading && !seeker.error) {
      setEditMode(false)
    }
  }, [seeker.loading])

  useEffect(() => {
    if (!employer.loading && !employer.error) {
      setEditMode(false)
    }
  }, [employer.loading])

  useEffect(() => {
    formik.validateForm()
  }, [
    formik.values.day,
    formik.values.year,
    formik.values.month,
    formik.values.homeTown,
    formik.values.homeRegion,
  ])

  const openEdit = () => {
    getTaxonomies()
    setEditMode(true)
  }

  const cancelEdit = () => {
    formik.setValues({
      ...defaultValues,
    })
    setEditMode(false)
  }

  const phoneInputError = {...TextProps(formik, 'phone')}.error
  return (
    <div className={styles.container}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={
          <WidgetBarContainer
            sidebarContent={
              <>
                <div className={styles.linksContainer}>
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerMyAccount
                        : AppRoute.MyAccount
                    }
                    text={st.editPersonalData}
                  />
                  <SidebarLink
                    route={
                      identityProviderUrls
                        ? getIdentityProviderUrl(identityProviderUrls, lang)
                        : ''
                    }
                    text={st.changeEmailAddress}
                  />
                  <SidebarLink
                    route={
                      identityProviderUrls
                        ? getIdentityProviderUrl(identityProviderUrls, lang)
                        : ''
                    }
                    text={st.changePassword}
                  />
                </div>
              </>
            }
          />
        }
      >
        <div className={styles.headerWrap}>
          <MainHeader
            breadcrumbsArray={breadcrumbsArray}
            title={mat.myAccount}
          />
        </div>
        <Title text={mat.personalData} className={styles.title} />
        <div
          className={classnames(
            styles.inputContainer,
            styles.inputPersonalData
          )}
        >
          <div className={styles.uploadImage}>
            {editMode ? (
              formik.values.profilePicture ? (
                <div className={styles.profileImage}>
                  <img
                    src={formik.values.profilePicture.url}
                    alt={`${formik.values.firstName} ${formik.values.lastName}`}
                  />
                  <DeleteOutline
                    className={styles.deleteIcon}
                    onClick={deletePicture}
                  />
                </div>
              ) : (
                <FileUpload
                  name="file"
                  endpoint="/upload-file/picture"
                  accept=".jpg,.jpeg,.png,.gif"
                  theme="profileImage"
                  onStart={() => {}}
                  onSuccess={(data) => {
                    setImgUploadError('')
                    formik.setFieldValue('profilePicture', data.data)
                  }}
                  onError={(_error: any, body: any) => {
                    setImgUploadError(body.error.message)
                  }}
                  dropable={true}
                >
                  <div className={styles.iconWrap}>
                    <PhotoCameraOutlined className={styles.photoIcon} />
                    <FormattedMessage id={mat.uploadPhoto} />
                  </div>
                  {imgUploadError ? (
                    <TextError text={imgUploadError} />
                  ) : formik.submitCount && formik.errors.profilePicture ? (
                    <TextError text={formik.errors.profilePicture} />
                  ) : null}
                </FileUpload>
              )
            ) : formik.values.profilePicture ? (
              <img
                src={formik.values.profilePicture.url}
                alt={`${formik.values.firstName} ${formik.values.lastName}`}
              />
            ) : (
              <AvatarContainer className={styles.avatar} />
            )}
          </div>
          {editMode && (
            <div className={styles.inputRows}>
              <div className={styles.inputRow}>
                <TextField
                  name="firstName"
                  className={styles.input}
                  label={rt.firstName}
                  theme="grey"
                  {...TextProps(formik, 'firstName')}
                />
                <TextField
                  name="lastName"
                  className={styles.input}
                  label={rt.lastName}
                  theme="grey"
                  {...TextProps(formik, 'lastName')}
                />
              </div>
              <div className={styles.inputRow}>
                <div
                  className={classnames(styles.dropdown, styles.dayDropdown)}
                >
                  <Dropdown
                    label={mat.day}
                    name="day"
                    emptyValueLabel={dt.selectDay}
                    items={getRestDaysOfMonth(
                      formik.values.month,
                      formik.values.year
                    )}
                    {...DropdownProps(formik, 'day')}
                    setValue={setNumberDropdownField}
                    error={
                      formik.submitCount && formik.errors.day
                        ? formik.errors.day
                        : undefined
                    }
                  />
                </div>
                <div className={styles.dropdown}>
                  <Dropdown
                    label={mat.month}
                    name="month"
                    emptyValueLabel={dt.selectMonth}
                    items={months}
                    {...DropdownProps(formik, 'month')}
                    setValue={setNumberDropdownField}
                  />
                </div>
                <div
                  className={classnames(styles.dropdown, styles.yearDropdown)}
                >
                  <Dropdown
                    label={mat.year}
                    name="year"
                    emptyValueLabel={dt.selectYear}
                    items={years}
                    {...DropdownProps(formik, 'year')}
                    setValue={setNumberDropdownField}
                  />
                </div>
              </div>
            </div>
          )}
          {!editMode && (
            <div className={styles.preview}>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={rt.firstName} />
                </div>
                <div className={styles.value}>{formik.values.firstName}</div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={rt.lastName} />
                </div>
                <div className={styles.value}>{formik.values.lastName}</div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={mat.birthdate} />
                </div>
                <div className={styles.value}>{translatedBirthdayToShow}</div>
              </div>
              <button
                className={styles.editBtn}
                onClick={openEdit}
                type="button"
              >
                <EditOutlined className={styles.editIcon} />
              </button>
            </div>
          )}
        </div>
        <Title text={mat.contactData} className={styles.title} />
        <div
          className={classnames(styles.inputContainer, styles.inputContactData)}
        >
          {editMode && (
            <>
              <div className={styles.inputRow}>
                <TextField
                  name="streetAddress"
                  className={styles.input}
                  label={mat.streetAddress}
                  theme="grey"
                  placeholder={cd.selectAddress}
                  {...TextProps(formik, 'streetAddress')}
                  value={formik.values.streetAddress || ''}
                />
              </div>
              <div className={classnames(styles.inputRow, styles.secondRow)}>
                <TextField
                  name="postalCode"
                  className={styles.input}
                  label={mat.postalCode}
                  theme="grey"
                  type="string"
                  placeholder={cd.selectPostNumber}
                  {...TextProps(formik, 'postalCode')}
                  value={formik.values.postalCode || ''}
                  onChange={(e) => {
                    const numsRegex = /^[0-9\b]+$/
                    if (
                      e.target.value === '' ||
                      numsRegex.test(e.target.value)
                    ) {
                      formik.handleChange(e)
                    }
                  }}
                />
                <TypeAheadFetch
                  name="homeTown"
                  value={formik.values.homeTown || null}
                  options={[]}
                  optionsQueryUrl={`/small-index/towns?${
                    formik.values.homeCountry
                      ? `countryId=${formik.values.homeCountry.id}&`
                      : ''
                  }name=`}
                  setValue={formik.setFieldValue}
                  label={cd.city}
                  placeholder={pt.placeholderTown}
                  error={
                    formik.submitCount && formik.errors?.homeTown
                      ? formik.errors.homeTown
                      : undefined
                  }
                  className={styles.dropdown}
                  smallIndex
                  autoComplete
                />
              </div>
              <div className={classnames(styles.inputRow, styles.thirdRow)}>
                <TypeAheadImproved
                  name="homeRegion"
                  items={taxonomies.regions?.data}
                  defaultValue={
                    formik.values.homeRegion
                      ? [formik.values.homeRegion]
                      : undefined
                  }
                  setValue={setTypeAheadField}
                  label={mat.region}
                  setTouched={formik.getFieldHelpers('homeRegion').setTouched}
                  maxNumSelections={1}
                  error={
                    formik.submitCount && formik.errors?.homeRegion
                      ? formik.errors.homeRegion
                      : undefined
                  }
                  selectNonExisting
                  className={styles.dropdown}
                  placeholder={cd.selectYourRegion}
                />
                <TypeAheadImproved
                  name="homeCountry"
                  items={taxonomies.countries.data}
                  defaultValue={
                    formik.values.homeCountry
                      ? [formik.values.homeCountry]
                      : undefined
                  }
                  setValue={(name, value) => {
                    setTypeAheadField(name, value)
                    if (formik.values.homeCountry?.id !== value[0]?.id) {
                      formik.setFieldValue('homeTown', null)
                    }
                  }}
                  label={cdt.country}
                  setTouched={formik.getFieldHelpers('homeCountry').setTouched}
                  placeholder={cd.selectYourCountry}
                  maxNumSelections={1}
                  error={
                    (formik.submitCount && formik.errors?.homeCountry) ||
                    undefined
                  }
                  className={styles.dropdown}
                />
              </div>
              <div
                className={classnames(styles.phoneRow, {
                  [styles.errorBorder]: !!phoneInputError,
                })}
              >
                <PhoneNumberField
                  label={cdt.phoneNumber}
                  name="phone"
                  {...TextProps(formik, 'phone')}
                  placeholder="+386"
                  setValue={formik.setFieldValue}
                />
              </div>
              <div className={classnames(styles.inputRow, styles.emailRow)}>
                <TextField
                  name="email"
                  label={gt.emailAddress}
                  theme="disabledStrong"
                  {...TextProps(formik, 'email')}
                  disabled
                />
                <LockOutlined className={styles.lock} />
              </div>
            </>
          )}
          {!editMode && (
            <div className={styles.preview}>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={mat.streetAddress} />
                </div>
                <div className={styles.value}>
                  {formik.values.streetAddress}
                </div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={mat.postalCode} />
                </div>
                <div className={styles.value}>{formik.values.postalCode}</div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={cdt.city} />
                </div>
                <div className={styles.value}>
                  {formik.values.homeTown?.translation}
                </div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={mat.region} />
                </div>
                <div className={styles.value}>
                  {formik.values.homeRegion?.translation}
                </div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={cdt.country} />
                </div>
                <div className={styles.value}>
                  {formik.values.homeCountry?.translation}
                </div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={cdt.phoneNumber} />
                </div>
                <div className={classnames(styles.value, styles.previewPhone)}>
                  <PhoneNumberField
                    label=""
                    name="phone"
                    {...TextProps(formik, 'phone')}
                    placeholder="+386"
                    setValue={formik.setFieldValue}
                    flagOnly={false}
                    error=""
                  />
                  <p className={styles.formatedPhoneNumber}>
                    {formatedPhoneNumber(formik.values.phone)}
                  </p>
                </div>
              </div>
              <div className={styles.previewRow}>
                <div className={styles.label}>
                  <FormattedMessage id={gt.emailAddress} />
                </div>
                <div className={styles.value}>{formik.values.email}</div>
              </div>
              <button
                className={styles.editBtn}
                onClick={openEdit}
                type="button"
              >
                <EditOutlined className={styles.editIcon} />
              </button>
            </div>
          )}
        </div>
        <div className={styles.apiError}>
          <Translation>
            {!companyUserRole ? seeker.error?.message : employer.error?.message}
          </Translation>
        </div>
        {editMode && (
          <div className={styles.buttonsWrap}>
            <YellowButton
              text={gt.saveChanges}
              onClick={formik.handleSubmit}
              className={styles.saveBtn}
            />
            <button className={styles.cancelBtn} onClick={cancelEdit}>
              <FormattedMessage id="Cancel" />
            </button>
          </div>
        )}
      </MainLayout>
    </div>
  )
}
