export enum SettingsDropdownTranslations {
  seeYourProfile = 'dropdownsettings.seeYourProfile',
  myAccount = 'dropdownsettings.myAccount',
  myConsents = 'dropdownsettings.myConsents',
  languageSettings = 'dropdownsettings.languageSettings',
  employerBlacklist = 'dropdownsettings.employerBlacklist',
  helpAndSupport = 'dropdownsettings.helpAndSupport',
  logout = 'dropdownsettings.logout',
  account = 'myAccount.account',
}
