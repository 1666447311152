import {FC, ChangeEvent, FocusEvent, useState, useMemo} from 'react'

import {useTranslation, useWindowResize} from 'Hooks'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {FormattedMessage} from 'react-intl'
import SendIcon from '@mui/icons-material/Send'

import styles from './MessageTextField.module.scss'
import classnames from 'classnames'

interface ButtonProps {
  onSubmit: () => void
  disabled: boolean
}

const ButtonWithText: FC<ButtonProps> = ({onSubmit, disabled}) => {
  return (
    <div className={styles.buttons}>
      <button
        className={classnames(styles.iconButton, {
          [styles.buttonDisabled]: disabled,
        })}
        onClick={() => onSubmit()}
        disabled={disabled}
      >
        <FormattedMessage id={mct.send} />
      </button>
    </div>
  )
}

const ButtonWithIcon: FC<ButtonProps> = ({onSubmit, disabled}) => {
  return (
    <div className={styles.buttons}>
      <button
        className={classnames(styles.svgIconButton, {
          [styles.buttonDisabled]: disabled,
        })}
        onClick={() => onSubmit()}
        disabled={disabled}
      >
        <SendIcon classes={{root: styles.sendMessageIcon}}></SendIcon>
      </button>
    </div>
  )
}
interface MessageTextFieldProps {
  placeholder: string
  name: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onSubmit: () => void
  disabled: boolean
}

export const MessageTextField: FC<MessageTextFieldProps> = ({
  placeholder,
  name,
  onChange,
  value,
  onBlur,
  onSubmit,
  disabled,
}) => {
  const translation = useTranslation()

  const [buttonType, setButtonType] = useState<'textButton' | 'svgButton'>(
    'textButton'
  )

  const changeTooltipPlacement = (clientWidth?: number) => {
    setButtonType(clientWidth && clientWidth < 991 ? 'svgButton' : 'textButton')
  }

  useWindowResize(changeTooltipPlacement)

  const SendButton = useMemo(() => {
    if (buttonType === 'textButton') {
      return ButtonWithText
    } else if (buttonType === 'svgButton') {
      return ButtonWithIcon
    } else {
      return ButtonWithText
    }
  }, [buttonType])

  return (
    <form
      className={styles.container}
      onSubmit={(event) => {
        event.preventDefault()
      }}
      onKeyDown={(event) => {
        if (event?.code === 'Enter') console.log('submitting via enter')
        if (event?.code === 'Enter') onSubmit()
      }}
    >
      <input
        name={name}
        onChange={onChange}
        value={value}
        autoComplete="off"
        className={classnames(styles.input, {[styles.disabled]: disabled})}
        placeholder={translation[placeholder] || placeholder}
        type="text"
        onBlur={onBlur}
        disabled={disabled}
      />

      <SendButton onSubmit={onSubmit} disabled={disabled} />
    </form>
  )
}
