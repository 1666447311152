import {IdentityProviderUrlType} from 'Interfaces'

export interface IChannel {
  id: number
  tenantId: string
  channelId: string
  host: string
  type: string
  name?: string
  tenant?: string
  formalName?: string
  defaultLanguage?: {
    id: string
    shortcode: string
  }
  languages: {
    [key: string]: string
  }
  didomiApiKey?: string
  gtmId?: string
  selbyMillsPostUrls: {
    [lang: string]: string
  }
  spectoCalendarUrl: string
  sessionTimeout: number
  candidateSearchPageSize: number
  identityProviderUrls?: IdentityProviderUrlType[]
  googleRecaptchaKey: string
}

export const CHANNELS: IChannel[] = [
  {
    id: 1,
    tenantId: '49a77bf3-396d-4032-95d1-0abb1327ac76',
    channelId: 'c9e1d50e-6013-4cf7-b051-312e218d36e2',
    host: 'localhost',
    name: 'LocalDevRJB',
    type: 'RJB',
    tenant: 'Alterset',
    formalName: 'Styria digital marketplaces d.o.o.',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'http://identity-provider.mangart.xyz', langShortcode: 'sl'},
      {url: 'http://identity-provider.mangart.xyz/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 2,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: '127.0.0.1',
    name: 'LocalDevRJB 127',
    type: 'RJB',
    tenant: 'Alterset',
    formalName: 'Styria digital marketplaces d.o.o.',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 3,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'migration.mangart.xyz',
    name: 'Migration dev',
    type: 'RJB',
    tenant: 'Alterset',
    formalName: 'Styria digital marketplaces d.o.o.',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'http://identity-provider.mangart.xyz', langShortcode: 'sl'},
      {url: 'http://identity-provider.mangart.xyz/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 4,
    tenantId: '6d7065d3-9c07-45da-8896-f66c51e80094',
    channelId: '73915388-752d-4c03-829a-53162a936574',
    host: 'tst-www.preferenca.si',
    name: 'MojeDelo.com',
    type: 'RJB',
    tenant: 'TenantName',
    formalName: 'Styria digital marketplaces d.o.o.',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    gtmId: 'GTM-PNJF9PX',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LfuX1wqAAAAAB_3w8Otg42oh988DLoMdX5kB3r-',
  },
  {
    id: 5,
    tenantId: '49a77bf3-396d-4032-95d1-0abb1327ac76',
    channelId: 'c9e1d50e-6013-4cf7-b051-312e218d36e2',
    host: 'mangart.xyz',
    name: 'MangartXYZ Test',
    type: 'RJB',
    tenant: 'Alterset',
    formalName: 'Styria digital marketplaces d.o.o.',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'http://identity-provider.mangart.xyz', langShortcode: 'sl'},
      {url: 'http://identity-provider.mangart.xyz/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 6,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    formalName: 'Styria digital marketplaces d.o.o.',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.testsdirect.com/',
      en: 'http://preferenca-eng.testsdirect.com/',
    },
    spectoCalendarUrl: 'https://calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 10,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 7,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'www.preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    formalName: 'Styria digital marketplaces d.o.o.',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.testsdirect.com/',
      en: 'http://preferenca-eng.testsdirect.com/',
    },
    spectoCalendarUrl: 'https://calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 10,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 8,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'stg-www.preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    formalName: 'Styria digital marketplaces d.o.o.',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
  {
    id: 9,
    tenantId: '78f6aca2-49a7-44f6-a088-24264c11d58d',
    channelId: '0b2ebef8-e533-4918-b6a2-4f25ae39ab0e',
    host: 'tst-demo.preferenca.si',
    name: 'Test 2 Preferenca',
    type: 'RJB',
    tenant: 'TenantName',
    formalName: 'Styria digital marketplaces d.o.o.',
    defaultLanguage: {
      id: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      shortcode: 'sl',
    },
    languages: {
      sl: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      en: '8d2ddacf-f9a1-4369-bb56-b28a66aae34d',
    },
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    gtmId: 'GTM-PNJF9PX',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
    identityProviderUrls: [
      {url: 'https://tst-identity.mojedelo.work', langShortcode: 'sl'},
      {url: 'https://tst-identity.mojedelo.work/en', langShortcode: 'en'},
    ],
    googleRecaptchaKey: '6LcLOqApAAAAAAvaxKTvtn6Pm2WgjMpOGZKxhlax',
  },
]
