import {Warning} from '@mui/icons-material'
import Hr from 'Components/Hr'
import TextField from 'Components/Inputs/TextField'
import Modal, {SubmitFooter} from 'Components/Modal'
import {useFormik} from 'formik'
import styles from './AppealModal.module.scss'
import Title from 'Components/Title'
import Checkbox from 'Components/Inputs/Checkbox'
import {FormattedMessage} from 'react-intl'
import TextArea from 'Components/Inputs/TextArea'
import {OnChangeProps} from 'Utils/FormikProps'
import {Box} from '@mui/material'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {DSAAppealPayloadType, DSAStateType} from 'Interfaces/DSAType'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Translation} from 'Components/Translation'
import {useEffect} from 'react'
import TextError from 'Components/Error/TextError'
import {useDynamicTranslation} from 'Hooks'
import {getChannel} from 'Utils/CommonHelpers'

const Validation = Yup.object().shape({
  reportId: Yup.string().required('Report id is required'),
  reason: Yup.string().required('Appeal reason is required'),
  appealer: Yup.string().required('Appealer is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
})

interface ReportFormType {
  reportId: string
  reason: string
  appealer: string
  email: string
  agreeWithTerms: false
}

interface AppealModalProps {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
  dsa: DSAStateType
  createAppeal: (payload: DSAAppealPayloadType) => void
}

// DSA - appeal modal content
export const AppealModal = ({
  isOpen,
  setIsOpen,
  createAppeal,
  dsa,
}: AppealModalProps) => {
  const translate = useDynamicTranslation()

  const formik = useFormik<ReportFormType>({
    initialValues: {
      reportId: '',
      appealer: '',
      reason: '',
      email: '',
      agreeWithTerms: false,
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      const payload: DSAAppealPayloadType = {
        email: values.email,
        appealer: values.appealer,
        reason: values.reason,
        reportId: values.reportId,
      }
      createAppeal(payload)
    },
  })

  useEffect(() => {
    if (!!dsa.data.appealSuccess) {
      setIsOpen(false)
    }
  }, [dsa.data.appealSuccess])

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        setIsOpen(false)
      }}
      title="Appeal form"
      icon={Warning}
      ariaHideApp={false}
      className={styles.reportModal}
      Footer={
        <SubmitFooter
          onSubmit={formik.handleSubmit}
          onCancel={() => {
            setIsOpen(false)
          }}
          submitText="Send Appeal"
          theme="modalProfileSubmit"
          bottomText={
            <p className={styles.reportBottomText}>
              <Translation
                text={translate(
                  'The data you provide will be processed by {platformName} for the purpose of processing your application under Article 6 (1)c (Digital Services Act). You can read more about the processing of personal data',
                  {platformName: getChannel().formalName || ''}
                )}
              />
              &nbsp;
              <Link
                to={getRoute(AppRoute.PrivacyPolicy)}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <Translation text="here" />
              </Link>
              .
            </p>
          }
        />
      }
    >
      <Box className={styles.reportModalBox}>
        <Title text="REPORT ID" />
        <TextField
          name="reportId"
          placeholder="Please enter Report ID"
          value={formik.values.reportId}
          onChange={formik.handleChange}
          error={
            formik.touched.reportId && formik.errors.reportId
              ? formik.errors.reportId
              : undefined
          }
        />

        <Hr theme="modal" />
        <Title text="APPEAL REASON" />
        <TextArea
          name="reason"
          placeholderText="Enter reason for reporting the content."
          {...OnChangeProps(formik, 'reason')}
          theme="white"
          className={clsx(styles.textArea, {
            [styles.error]: formik.submitCount > 0 && formik.errors?.reason,
          })}
          error={
            formik.touched.reason && formik.errors.reason
              ? formik.errors?.reason
              : undefined
          }
          rows={4}
        />

        <Hr theme="modal" />
        <Title text="FULL NAME OR COMPANY NAME" />
        <TextField
          name="appealer"
          placeholder="Enter your full name or company name"
          value={formik.values.appealer}
          onChange={formik.handleChange}
          error={
            formik.touched.appealer && formik.errors.appealer
              ? formik.errors.appealer
              : undefined
          }
        />

        <Hr theme="modal" />
        <Title text="EMAIL" />
        <TextField
          name="email"
          placeholder="Enter email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : undefined
          }
        />
        <Hr theme="modal" />
        <Checkbox
          name="agreeWithTerms"
          value={formik.values.agreeWithTerms}
          onChange={() => {
            formik.setFieldValue(
              'agreeWithTerms',
              !formik.values.agreeWithTerms
            )
          }}
          error={
            formik.submitCount > 0 && !formik.values.agreeWithTerms
              ? 'You must confirm'
              : undefined
          }
        >
          <FormattedMessage
            id={
              'I confirm that I am making the report voluntarily and that the information and statements contained in the report are accurate and complete.'
            }
          />
        </Checkbox>
        {dsa.error?.message && <TextError text={dsa.error.message} />}
      </Box>
    </Modal>
  )
}
