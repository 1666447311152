import {all, call, put, select, takeLatest} from 'redux-saga/effects'
// import API from 'Services/API'
import {AppRoute, ProfileTranslations as pt} from 'Services/I18n/Constants'
import {getRoute, getTranslation} from 'Services/I18n/Utils'
import {
  ActionType,
  CancelInterviewType,
  EmployerRegistrationType,
  EmployerRequestsSearchParamsType,
  EmployerRequestsStateType,
  ModalSendMessageEmpType,
  PersonalDataSaveType,
  ReviewCandidateType,
  SeekerEvaluationType,
  sendOfferModalEmpType,
} from 'Interfaces'
import * as type from '../types'
import API, {initAPIHeaders} from 'Services/API'
import qs from 'qs'
import {generateURL} from 'Utils/CommonHelpers'
import {
  packIncomingRequests,
  packInterviewRequests,
  packInterviewRequestsParams,
} from 'Store/Packing/IncomingRequests'
import {RootState} from 'Store/Reducers'
import {packDropdownSeekerItems} from 'Store/Packing/EmployerDashboard'
import {push} from 'connected-react-router'

const contactRequestsSelector = (state: RootState) =>
  state.employer.data.allRequests.contactRequests

const placementsSelector = (state: RootState) =>
  state.employer.data.allRequests.placements

const offersSelector = (state: RootState) =>
  state.employer.data.allRequests.offers

const interviewsSelector = (state: RootState) =>
  state.employer.data.allRequests.interviewInvitations

function* fetchEmployerActiveRequests() {
  const {response, error} = yield call(
    API.get,
    '/company-seeker-journey/company-user/active-requests'
  )

  if (response) {
    yield put({
      type: type.employer.activeRequests.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.employer.activeRequests.failed, payload: error})
  }
}

function* getCanidateReviewData() {
  //const {response, error} = yield call(API.get, '/company/')
  // TODO replace this data with real data
  const response = {
    data: {
      seekerId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
      name: 'John Smith',
      date: '3rd May 2022, 10:00 - 10:30',
      interviewedBy: 'Teja Zaplotnik',
    },
  }
  const error = {}
  if (response) {
    yield put({
      type: type.employer.reviewData.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.employer.reviewData.get.failed, payload: error})
  }
}

function* addCandidateReview(action: ActionType<ReviewCandidateType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-evaluation',
    action.payload
  )

  if (response) {
    yield put({
      type: type.employer.reviewCandidate.add.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.employer.reviewCandidate.add.save.failed,
      payload: error,
    })
  }
}

function* editCandidateReview(action: ActionType<ReviewCandidateType>) {
  const {response, error} = yield call(
    API.patch,
    '/user/seeker-evaluation',
    action.payload
  )
  if (response) {
    yield put({
      type: type.employer.reviewCandidate.edit.save.succeeded,
      payload: {...action.payload},
    })
  } else {
    yield put({
      type: type.employer.reviewCandidate.edit.save.failed,
      payload: error,
    })
  }
}

function* fetchLatestEmployerRequests() {
  const {response, error} = yield call(
    API.get,
    '/company-seeker-journey/company-user/requests?pageSize=5'
  )
  if (response) {
    yield put({
      type: type.employer.allRequests.latest.succeeded,
      payload: {searchResults: packIncomingRequests(response.data.items)},
    })
  } else {
    yield put({
      type: type.employer.allRequests.latest.failed,
      payload: error,
    })
  }
}

// CONTACT REQUESTS:
const contactRequestsURL =
  '/company-seeker-journey/company-user/contact-requests'
function* fetchEmployerContactRequests(
  action: ActionType<EmployerRequestsSearchParamsType>
) {
  let params = action.payload
  const urlWithParams = generateURL(
    contactRequestsURL,
    params as EmployerRequestsSearchParamsType
  )

  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = action.payload as EmployerRequestsSearchParamsType
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(contactRequestsURL, params)
    }

    yield put({
      type: type.employer.allRequests.contactRequests.succeeded,
      payload: {
        searchResults: response.data.items,
        loadMore,
        params,
        total,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.contactRequests.failed,
      payload: error,
    })
  }
}

function* fetchMoreEmployerContactRequests() {
  const contactRequests: EmployerRequestsStateType = yield select(
    contactRequestsSelector
  )
  const totalItems = contactRequests.total
  const totalItemsFetched = contactRequests.searchResults.length
  let loadMore = undefined

  if (totalItems && totalItems > totalItemsFetched) {
    const contactRequests: EmployerRequestsStateType = yield select(
      contactRequestsSelector
    )
    const params = contactRequests.params
    loadMore = generateURL(contactRequestsURL, params, totalItemsFetched)

    const {response, error} = yield call(API.get, loadMore as string)

    if (response) {
      yield put({
        type: type.employer.allRequests.contactRequests.loadmore.succeeded,
        payload: {
          searchResults: response.data.items,
          loadMore,
        },
      })
    } else {
      yield put({
        type: type.employer.allRequests.contactRequests.loadmore.failed,
        payload: error,
      })
    }
  }
}

function* fetchSeekersFromContactRequests(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company-seeker-journey/company-user/seekers?requestType=contact_request${
      action.payload ? `&requestStatus=${action.payload}` : ''
    }`
  )

  if (response) {
    yield put({
      type: type.employer.seekersFrom.contactRequests.succeeded,
      payload: packDropdownSeekerItems(response.data.items),
    })
  } else {
    yield put({
      type: type.employer.seekersFrom.contactRequests.failed,
      payload: error,
    })
  }
}

const interviewRequestsURL =
  '/company-seeker-journey/company-user/interview-requests'
function* fetchEmployerInterviews(
  action: ActionType<EmployerRequestsSearchParamsType>
) {
  const urlWithParams = generateURL(
    interviewRequestsURL,
    packInterviewRequestsParams(
      action.payload as EmployerRequestsSearchParamsType
    )
  )

  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    // Generate load more URL
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = packInterviewRequestsParams(
      action.payload as EmployerRequestsSearchParamsType
    )
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(interviewRequestsURL, params)
    }
    yield put({
      type: type.employer.allRequests.interviewInvitations.succeeded,
      payload: {
        searchResults: packInterviewRequests(response.data.items),
        loadMore,
        params,
        total,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.interviewInvitations.failed,
      payload: error,
    })
  }
}

function* fetchMoreEmployerInterviews() {
  const interviews: EmployerRequestsStateType = yield select(interviewsSelector)

  const totalItems = interviews.total
  const totalItemsFetched = interviews.searchResults.length
  let loadMore = undefined

  if (totalItems && totalItems > totalItemsFetched) {
    const params = packInterviewRequestsParams(
      interviews.params as EmployerRequestsSearchParamsType
    )
    loadMore = generateURL(interviewRequestsURL, params, totalItemsFetched)

    const {response, error} = yield call(API.get, loadMore as string)

    if (response) {
      yield put({
        type: type.employer.allRequests.interviewInvitations.loadmore.succeeded,
        payload: {
          searchResults: packInterviewRequests(response.data.items),
          loadMore,
        },
      })
    } else {
      yield put({
        type: type.employer.allRequests.interviewInvitations.loadmore.failed,
        payload: error,
      })
    }
  }
}

// PLACEMENTS
const placementRequestsURL =
  '/company-seeker-journey/company-user/placement-requests'
function* fetchEmployerPlacements(
  action: ActionType<EmployerRequestsSearchParamsType>
) {
  const urlWithParams = generateURL(
    placementRequestsURL,
    action.payload as EmployerRequestsSearchParamsType
  )

  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    // Generate load more URL
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = action.payload as EmployerRequestsSearchParamsType
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(placementRequestsURL, params)
    }

    yield put({
      type: type.employer.allRequests.placements.succeeded,
      payload: {
        searchResults: response.data.items,
        loadMore,
        params,
        total,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.placements.failed,
      payload: error,
    })
  }
}

function* fetchMoreEmployerPlacements() {
  const placements: EmployerRequestsStateType = yield select(placementsSelector)
  const totalItems = placements.total
  const totalItemsFetched = placements.searchResults.length
  let loadMore = undefined

  if (totalItems && totalItems > totalItemsFetched) {
    const params = placements.params
    loadMore = generateURL(placementRequestsURL, params, totalItemsFetched)
    const {response, error} = yield call(API.get, loadMore as string)

    if (response) {
      yield put({
        type: type.employer.allRequests.placements.loadmore.succeeded,
        payload: {
          searchResults: response.data.items,
          loadMore,
        },
      })
    } else {
      yield put({
        type: type.employer.allRequests.placements.loadmore.failed,
        payload: error,
      })
    }
  }
}

const offerRequestsURL = '/company-seeker-journey/company-user/offer-requests'
function* fetchEmployerOffers(
  action: ActionType<EmployerRequestsSearchParamsType>
) {
  const urlWithParams = generateURL(
    offerRequestsURL,
    action.payload as EmployerRequestsSearchParamsType
  )

  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    // Generate load more URL
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = action.payload as EmployerRequestsSearchParamsType
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(offerRequestsURL, params)
    }

    yield put({
      type: type.employer.allRequests.offers.succeeded,
      payload: {
        searchResults: response.data.items,
        loadMore,
        params,
        total,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.offers.failed,
      payload: error,
    })
  }
}

function* fetchMoreEmployerOffers() {
  const offers: EmployerRequestsStateType = yield select(offersSelector)
  const totalItems = offers.total
  const totalItemsFetched = offers.searchResults.length
  let loadMore = undefined

  if (totalItems && totalItems > totalItemsFetched) {
    const params = offers.params
    loadMore = generateURL(offerRequestsURL, params, totalItemsFetched)
    const {response, error} = yield call(API.get, loadMore as string)

    if (response) {
      yield put({
        type: type.employer.allRequests.offers.loadmore.succeeded,
        payload: {
          searchResults: response.data.items,
          loadMore,
        },
      })
    } else {
      yield put({
        type: type.employer.allRequests.offers.loadmore.failed,
        payload: error,
      })
    }
  }
}

function* fetchSeekersFromInterviews(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company-seeker-journey/company-user/seekers?requestType=interview_request${
      action.payload ? `&requestStatus=${action.payload}` : ''
    }`
  )

  if (response) {
    yield put({
      type: type.employer.seekersFrom.interviewInvitations.succeeded,
      payload: packDropdownSeekerItems(response.data.items),
    })
  } else {
    yield put({
      type: type.employer.seekersFrom.interviewInvitations.failed,
      payload: error,
    })
  }
}

function* fetchSeekersFromPlacements(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company-seeker-journey/company-user/seekers?requestType=placement_request${
      action.payload ? `&requestStatus=${action.payload}` : ''
    }`
  )

  if (response) {
    yield put({
      type: type.employer.seekersFrom.placements.succeeded,
      payload: packDropdownSeekerItems(response.data.items),
    })
  } else {
    yield put({
      type: type.employer.seekersFrom.placements.failed,
      payload: error,
    })
  }
}

function* fetchSeekersFromOffers(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company-seeker-journey/company-user/seekers?requestType=offer_request${
      action.payload ? `&requestStatus=${action.payload}` : ''
    }`
  )

  if (response) {
    yield put({
      type: type.employer.seekersFrom.offers.succeeded,
      payload: packDropdownSeekerItems(response.data.items),
    })
  } else {
    yield put({
      type: type.employer.seekersFrom.offers.failed,
      payload: error,
    })
  }
}

function* interviewInvitationsReject(action: ActionType<{id: string}>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/interview-request/reject',
    {id: action.payload}
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsReject.succeeded,
      payload: {status: response.data.status, id: action.payload},
    })
  } else {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsReject.failed,
      payload: error,
    })
  }
}
function* interviewInvitationsAccept(action: ActionType<{id: string}>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/interview-request/approve',
    {id: action.payload}
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsAccept.succeeded,
      payload: {status: response.data.status, id: action.payload},
    })
  } else {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsAccept.failed,
      payload: error,
    })
  }
}

function* interviewInvitationsCancel(action: ActionType<CancelInterviewType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/interview-request/cancel',
    {id: action.payload?.id, reason: action.payload?.reason}
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsCancel.succeeded,
      payload: {
        status: response.data.status.toLowerCase(),
        id: response.data.id,
        companyUserReason: action.payload?.reason,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.interviewInvitations
        .interviewInvitationsCancel.failed,
      payload: error,
    })
  }
}

function* fetchEmployerPlacementConfirmedOffers() {
  const params = qs.stringify({
    statuses: [
      'company_user_pending_seeker_confirmed',
      'company_user_pending_seeker_pending',
    ],
  })
  const {response, error} = yield call(
    API.get,
    `/company-seeker-journey/company-user/placement-requests?${params}`
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.placementConfirmedOffers.succeeded,
      payload: response,
    })
  } else {
    yield put({
      type: type.employer.allRequests.placementConfirmedOffers.failed,
      payload: error,
    })
  }
}

function* placementsConfirmed(action: ActionType<{id: string}>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/placement-request',
    {requestId: action.payload, placementConfirmationStatus: 'confirmed'}
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.placements.confirmed.succeeded,
      payload: {status: response.data.status, id: action.payload},
    })
  } else {
    yield put({
      type: type.employer.allRequests.placements.confirmed.failed,
      payload: error,
    })
  }
}

function* placementsRejected(action: ActionType<{id: string}>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/placement-request',
    {requestId: action.payload, placementConfirmationStatus: 'rejected'}
  )
  if (response) {
    yield put({
      type: type.employer.allRequests.placements.rejected.succeeded,
      payload: {status: response.data.status, id: action.payload},
    })
  } else {
    yield put({
      type: type.employer.allRequests.placements.rejected.failed,
      payload: error,
    })
  }
}
// TODO: Check if this should be removed
function* sendMessage(action: ActionType<ModalSendMessageEmpType>) {
  const {response, error} = yield call(
    API.post,
    '/message-center/conversation',
    {
      messageTitle: action.payload?.messageTitle,
      messageBody: action.payload?.messageBody,
      otherParticipantsUserIds: [action.payload?.UserId],
    }
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.sendMessage.succeeded,
      payload: {response},
    })
  } else {
    yield put({
      type: type.employer.allRequests.sendMessage.failed,
      payload: error,
    })
  }
}

function* sendOffer(action: ActionType<sendOfferModalEmpType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/company-user/offer-request',
    action.payload
  )

  if (response) {
    yield put({
      type: type.employer.allRequests.offers.sendOffer.succeeded,
      payload: {status: response.data.status, id: action.payload?.requestId},
    })
  } else {
    yield put({
      type: type.employer.allRequests.offers.sendOffer.failed,
      payload: error,
    })
  }
}

function* getCandidateProfile(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/user/seeker-profile/${action.payload}`
  )

  if (response) {
    yield put({
      type: type.employer.getCandidateProfile.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.employer.getCandidateProfile.failed,
      payload: error,
    })
  }
}

function* contactCandidate(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    `/company-seeker-journey/company-user/contact-request`,
    {seekerUserId: action.payload}
  )

  if (response) {
    yield put({
      type: type.employer.contactCandidate.succeeded,
    })
    yield put({
      type: type.app.snackbar.show,
      payload: {
        snackbars: [
          {message: getTranslation(pt.contactSentCandidate), duration: 1500},
        ],
      },
    })
  } else {
    yield put({
      type: type.employer.contactCandidate.failed,
      payload: error,
    })
  }
}

function* getEmployerPersonalData() {
  const {response, error} = yield call(API.get, 'company-user/account-data')
  if (response) {
    yield put({
      type: type.employer.personalData.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.employer.personalData.get.failed, payload: error})
  }
}

function* saveEmployerPersonalData(action: ActionType<PersonalDataSaveType>) {
  const {response, error} = yield call(
    API.patch,
    'company-user/account-data',
    action.payload
  )
  if (response) {
    yield put({
      type: type.employer.personalData.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.employer.personalData.save.failed, payload: error})
  }
}

function* addDashboardSeekerEvaluation(
  action: ActionType<SeekerEvaluationType>
) {
  const {response, error} = yield call(API.post, `/user/seeker-evaluation`, {
    seekerUserId: action.payload?.seekerUserId,
    evaluation: action.payload?.evaluation,
  })
  if (response) {
    yield put({
      type: type.employer.allRequests.rateCandidate.add.succeeded,
      payload: {
        requestId: action.payload?.seekerUserId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.rateCandidate.add.failed,
      error,
    })
  }
}

function* authConnectingEmployer(action: ActionType<EmployerRegistrationType>) {
  const {response, error} = yield call(API.post, `/rjb/company/auth/register`, {
    ...action.payload,
  })

  if (response) {
    localStorage.setItem('userId', response.data.items[0].id)
    initAPIHeaders()
    yield put({
      type: type.USER_LOGIN_SUCCEEDED,
      payload: response.data.items,
    })
    yield put(push(getRoute(AppRoute.EmployerDashboard)))
  } else {
    yield put({type: type.USER_LOGIN_FAILED, payload: error})
  }
}

function* editDashboardSeekerEvaluation(
  action: ActionType<SeekerEvaluationType>
) {
  const {response, error} = yield call(API.patch, `/user/seeker-evaluation`, {
    seekerUserId: action.payload?.seekerUserId,
    evaluation: action.payload?.evaluation,
  })
  if (response) {
    yield put({
      type: type.employer.allRequests.rateCandidate.edit.succeeded,
      payload: {
        requestId: action.payload?.seekerUserId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.employer.allRequests.rateCandidate.edit.failed,
      error,
    })
  }
}

export default function* EmployerSaga(): Generator {
  yield all([
    takeLatest(
      type.employer.activeRequests.requested,
      fetchEmployerActiveRequests
    ),
    takeLatest(type.employer.reviewData.get.requested, getCanidateReviewData),
    takeLatest(
      type.employer.reviewCandidate.add.save.requested,
      addCandidateReview
    ),
    takeLatest(
      type.employer.reviewCandidate.edit.save.requested,
      editCandidateReview
    ),
    takeLatest(
      type.employer.allRequests.latest.requested,
      fetchLatestEmployerRequests
    ),
    // CONTACT REQUESTS
    takeLatest(
      type.employer.allRequests.contactRequests.requested,
      fetchEmployerContactRequests
    ),
    takeLatest(
      type.employer.allRequests.contactRequests.loadmore.requested,
      fetchMoreEmployerContactRequests
    ),
    takeLatest(
      type.employer.seekersFrom.contactRequests.requested,
      fetchSeekersFromContactRequests
    ),
    takeLatest(
      type.employer.allRequests.placements.requested,
      fetchEmployerPlacements
    ),
    takeLatest(
      type.employer.allRequests.placements.loadmore.requested,
      fetchMoreEmployerPlacements
    ),
    takeLatest(
      type.employer.seekersFrom.placements.requested,
      fetchSeekersFromPlacements
    ),
    takeLatest(type.employer.allRequests.offers.requested, fetchEmployerOffers),
    takeLatest(
      type.employer.allRequests.offers.loadmore.requested,
      fetchMoreEmployerOffers
    ),
    takeLatest(
      type.employer.seekersFrom.offers.requested,
      fetchSeekersFromOffers
    ),
    takeLatest(
      type.employer.allRequests.interviewInvitations.requested,
      fetchEmployerInterviews
    ),
    takeLatest(
      type.employer.allRequests.interviewInvitations.loadmore.requested,
      fetchMoreEmployerInterviews
    ),
    takeLatest(
      type.employer.seekersFrom.interviewInvitations.requested,
      fetchSeekersFromInterviews
    ),

    takeLatest(
      type.employer.allRequests.interviewInvitations.interviewInvitationsReject
        .requested,
      interviewInvitationsReject
    ),
    takeLatest(
      type.employer.allRequests.interviewInvitations.interviewInvitationsAccept
        .requested,
      interviewInvitationsAccept
    ),
    takeLatest(
      type.employer.allRequests.interviewInvitations.interviewInvitationsCancel
        .requested,
      interviewInvitationsCancel
    ),
    takeLatest(
      type.employer.allRequests.placementConfirmedOffers.requested,
      fetchEmployerPlacementConfirmedOffers
    ),
    takeLatest(
      type.employer.allRequests.placements.confirmed.requested,
      placementsConfirmed
    ),
    takeLatest(
      type.employer.allRequests.placements.rejected.requested,
      placementsRejected
    ),
    takeLatest(type.employer.allRequests.sendMessage.requested, sendMessage),
    takeLatest(type.employer.allRequests.offers.sendOffer.requested, sendOffer),
    takeLatest(
      type.employer.getCandidateProfile.requested,
      getCandidateProfile
    ),
    takeLatest(type.employer.contactCandidate.requested, contactCandidate),
    takeLatest(
      type.employer.personalData.get.requested,
      getEmployerPersonalData
    ),
    takeLatest(
      type.employer.personalData.save.requested,
      saveEmployerPersonalData
    ),
    takeLatest(
      type.employer.allRequests.rateCandidate.add.requested,
      addDashboardSeekerEvaluation
    ),
    takeLatest(
      type.employer.allRequests.rateCandidate.edit.requested,
      editDashboardSeekerEvaluation
    ),
    takeLatest(
      type.employer.authConnectingEmployer.requested,
      authConnectingEmployer
    ),
  ])
}
