import {LanguageStringsType} from './LanguageStringsType'
import routes from './Routes/en'
import {seeker} from './Seeker/en'
import {publicPages} from './PublicPages/en'
import {settings} from './Settings/en'
import validation from './Validation/en'
import {general} from './General/en'
import {layout} from './Layout/en'
import {employer} from './Employer/en'
import {assessments} from './Assessments/en'
import messageCenter from './MessageCenter/en'
import {company} from './Company/en'
import {unsubscribe} from './Unsubscribe/en'
import {aboutUs} from './AboutUs/en'
import {identityProvider} from './IdentityProvider/en'

export const en: LanguageStringsType = {
  ...routes,
  ...seeker,
  ...settings,
  ...validation,
  ...publicPages,
  ...general,
  ...layout,
  ...employer,
  ...assessments,
  ...messageCenter,
  ...company,
  ...unsubscribe,
  ...aboutUs,
  ...identityProvider,
  emailAddress: 'Email address',
  password: 'Password',
  next: 'Next',
  back: 'Back',
  skills: 'Skills',
  saveChanges: 'Save changes',
  cancel: 'Cancel',
  preview: 'Preview',
  add: 'Add',
  edit: 'Edit',
  employer: 'Employer',
  address: 'Address',
  'Postal code': 'Postal code',
  City: 'City',
  Country: 'Country',
  'Select your country': 'Select your country',
  'Phone number': 'Phone number',
  yes: 'Yes',
  no: 'No',
  'Save changes': 'Save changes',
  Cancel: 'Cancel',
  delete: 'Delete',
  warning: 'Warning!',
  undefinedInput: ' ',
  'Show fewer': 'Show fewer',
  'Show more': 'Show more',
  'Preferenca.com': 'Preferenca.si',
  'Preferenca.si': 'Preferenca.si',
  'Select from list': 'Select from list',
  'Select value': 'Select value',
  'No entry found': 'No entry found',
  Other: 'Other',
  /** Page content */
  'home.title': 'Home',
  'home.content': 'Home',
  'First name': 'First name',
  'Last name': 'Last name',

  'publicHome.title': 'Public home',

  'modal.modal': 'Modal',

  'assessments.title': 'Assessments',
  'assessmentssuccess.title': 'AssessmentsSuccess',

  'summary.title': 'Page',
  'summary.content': 'page',

  'error.title': 'Error',
  'error.content': 'Error',

  'alerts.title': 'Alert',

  'lazy.title': 'Lazy',

  'seekerlogin.title': 'Seeker Login',
  'employerlogin.title': 'Employer Login',

  'jobpreferences.title': 'Job Preferences',

  'formikexample.title': 'Formik Example',

  'onboarding.title': 'Onboarding',

  'profile.title': 'Edit CV',
  'publicprofile.title': 'Edit public profile',

  'homePage.title': 'HomePage',

  'homePageEmployer.title': 'HomePageEmployer',
  'homePageCandidate.title': 'HomePageCandidate',

  'employerdashboard.title': 'Employer Dashboard',

  'homepagecandidate.title': 'Home Page Candidate',

  'frequentlyaskedquestions.title': 'Frequently Asked Questions',
  /** Component content */
  'checkbox-error-img.alt': 'ErrorIcon-EN',

  'uploadtest.title': 'Upload Test',

  'workexperience.title': 'Work Experience',

  'consents.title': 'My Consents',

  'companyprofile.title': 'companyprofile',
  'termsndconditions.title': 'termsandconditions',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  'gallery.title': 'gallery title',
  'dragndrop.title': 'dragndrop example',

  'languagesettings.title': 'Language settings',
  'logout.title': 'Logout',
  'calendar.title': 'Calendar',
  'employercontactrequests.title': 'Employer Contact Requests',
  'employerinterviewinvitations.title': 'Employer Interview Invitations',
  'employeroffers.title': 'Employer Offers',
  'employerplacements.title': 'Employer Placements',
  'reviewcandidate.title': 'Review Candidate',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Daily: 'Daily',
  Monthly: 'Monthly',
  Disabled: 'Disabled',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  daily: 'Daily',
  monthly: 'Monthly',
  disabled: 'Disabled',
  or: 'or',
  here: 'here',
  'Log out warning': 'Log out warning',
  'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.':
    'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.',
  ' years old': ' years old',
  'You can find this candidate in following projects:':
    'You can find this candidate in following projects:',
  Occupation: 'Occupation',
  'Job category': 'Job category',
  Employer: 'Employer',
  Skill: 'Skill',
  town: 'town',
  region: 'region',
  country: 'country',
  'Preferenca is currently down for maintenance.':
    'Preferenca is currently down for maintenance.',
  'We expect to be back shortly. Thank you for your patience.':
    'We expect to be back shortly. Thank you for your patience.',
  'Refresh page and check again': 'Refresh page and check again',
  Keyword: 'Keyword',
  'Company user login forbidden, company owner action needed':
    'Company user login forbidden, company owner action needed',
  'Wrong identifier or password': 'Wrong identifier or password',
  'Company is inactive': 'Company is inactive',
  'Animal harm, Unlawful sale of animals':
    'Animal harm, Unlawful sale of animals',
  'Biometric data breach, Missing processing ground for data, Right to be forgotten, Data falsification':
    'Biometric data breach, Missing processing ground for data, Right to be forgotten, Data falsification',
  'Defamation, Discrimination, Hate speech':
    'Defamation, Discrimination, Hate speech',
  'Copyright infringement, Design infringement, Geographical indications infringements, Patent infringement, Trade secret infringement, Trademark infringement':
    'Copyright infringement, Design infringement, Geographical indications infringements, Patent infringement, Trade secret infringement, Trademark infringement',
  'Disinformation, Foreign information manipulation and interference, Misinformation':
    'Disinformation, Foreign information manipulation and interference, Misinformation',
  'Non-consensual image sharing, Non-consensual items containing deepfake or similar technology using a third party’s features':
    'Non-consensual image sharing, Non-consensual items containing deepfake or similar technology using a third party’s features',
  'Age-specific restrictions concerning minors, Child sexual abuse material, Grooming/sexual enticement of minors, Unsafe challenges':
    'Age-specific restrictions concerning minors, Child sexual abuse material, Grooming/sexual enticement of minors, Unsafe challenges',
  'Illegal organizations, Risk for environmental damage, Risk for public health, Terrorist content':
    'Illegal organizations, Risk for environmental damage, Risk for public health, Terrorist content',
  'Inauthentic accounts, Inauthentic listings, Inauthentic user reviews, Impersonation or account hijacking, Phishing, Pyramid schemes':
    'Inauthentic accounts, Inauthentic listings, Inauthentic user reviews, Impersonation or account hijacking, Phishing, Pyramid schemes',
  'Content promoting, eating disorders, Self-mutilation, Suicide':
    'Content promoting, eating disorders, Self-mutilation, Suicide',
  'Goods/services not permitted to be offered on the platform':
    'Goods/services not permitted to be offered on the platform',
  'Reason for reporting the content': 'Reason for reporting the content',
  'FULL NAME OR COMPANY NAME': 'FULL NAME OR COMPANY NAME',
  'Enter your full name or company name':
    'Enter your full name or company name',
  'Enter email': 'Enter email',
  'I confirm that I am making the report voluntarily and that the information and statements contained in the report are accurate and complete.':
    'I confirm that I am making the report voluntarily and that the information and statements contained in the report are accurate and complete.',
  EMAIL: 'EMAIL',
  'We received your report.': 'We received your report.',
  'The report is managed under task number #{reportId} and is already under review by our moderation team. If you have not provided your email address in your report, please make a note of the application reference number, as you will need it in case you wish to appeal against our decision on your application.':
    'The report is managed under task number #{reportId} and is already under review by our moderation team. If you have not provided your email address in your report, please make a note of the application reference number, as you will need it in case you wish to appeal against our decision on your application.',
  'The data you provide will be processed by {platformName} for the purpose of processing your application under Article 6 (1)c (Digital Services Act). You can read more about the processing of personal data':
    'The data you provide will be processed by {platformName} for the purpose of processing your application under Article 6 (1)c (Digital Services Act). You can read more about the processing of personal data',
  'We received your appeal.': 'We received your appeal.',
  'Enter reason for reporting the content.':
    'Enter reason for reporting the content.',
  'Report content': 'Report content',
  Report: 'Report',
  'I understand': 'I understand',
  'Appeal form': 'Appeal form',
  'REPORT ID': 'REPORT ID',
  'Please enter Report ID': 'Please enter Report ID',
  'APPEAL REASON': 'APPEAL REASON',
  'Send Appeal': 'Send Appeal',
}
