import {FC, useRef, useState} from 'react'
import styles from './SettingsMenu.module.scss'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {AvatarContainer} from 'Components/Avatar'
import {
  Language,
  Settings,
  Logout,
  NavigateNextOutlined,
  HistoryEdu,
  AccountCircleOutlined,
} from '@mui/icons-material'
// import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import {AuthStateType, SeekerProfileType} from 'Interfaces'
import {getLang, getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {SettingsDropdownTranslations as sdt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {getChannel, getIdentityProviderUrl} from 'Utils/CommonHelpers'

interface SettingsMenuProps {
  auth?: AuthStateType
  profile?: SeekerProfileType
}

export const SettingsMenu: FC<SettingsMenuProps> = ({auth, profile}) => {
  const channel = getChannel()
  const identityProviderUrls = channel?.identityProviderUrls
  const lang = getLang()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dropDown = useRef<HTMLDivElement>(null)
  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const toogleMenuOpen = () => {
    setIsMenuOpen((old) => !old)
  }

  return (
    <div
      className={classNames(styles.wrap, {[styles.active]: isMenuOpen})}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setIsMenuOpen(false)
        }
      }}
      tabIndex={0}
    >
      <div className={styles.triggerWrap} onClick={toogleMenuOpen}>
        <AvatarContainer className={styles.trigger} />
      </div>
      <div
        ref={dropDown}
        className={classNames(styles.applicationsMenu, [
          {[styles.menuOpen]: isMenuOpen},
        ])}
      >
        <div className={styles.dropdownHeader}>
          {auth?.data?.profilePicture ? (
            <div className={styles.avatarWrap}>
              <AvatarContainer className={styles.avatar} />
            </div>
          ) : (
            <div className={styles.initials}>
              {profile?.firstName?.charAt(0) || auth?.data?.firstName.charAt(0)}
              {profile?.lastName?.charAt(0) || auth?.data?.lastName.charAt(0)}
            </div>
          )}
          <div className={styles.userInfo}>
            <div className={styles.fullName}>
              {`${profile?.firstName || auth?.data?.firstName} ${
                profile?.lastName || auth?.data?.lastName
              }`}
            </div>
            {auth?.data && !companyUserRole && (
              <Link
                to={getRoute(AppRoute.PublicProfile)}
                className={styles.profileUrl}
              >
                <FormattedMessage id={sdt.seeYourProfile} />
              </Link>
            )}
          </div>
        </div>
        <a
          href={
            identityProviderUrls
              ? getIdentityProviderUrl(identityProviderUrls, lang)
              : ''
          }
          target="blank"
          className={styles.item}
        >
          <div className={styles.iconWrap}>
            <AccountCircleOutlined />
          </div>
          <div className={styles.text}>
            {channel.name}
            <FormattedMessage id={sdt.account} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlined />
          </div>
        </a>

        <Link
          to={getRoute(
            companyUserRole ? AppRoute.EmployerMyAccount : AppRoute.MyAccount
          )}
          className={styles.item}
        >
          <div className={styles.iconWrap}>
            <Settings />
          </div>
          <div className={styles.text}>
            <FormattedMessage id={sdt.myAccount} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlined />
          </div>
        </Link>

        <Link
          to={getRoute(
            companyUserRole ? AppRoute.EmployerConsents : AppRoute.Consents
          )}
          className={styles.item}
        >
          <div className={styles.iconWrap}>
            <HistoryEdu />
          </div>
          <div className={styles.text}>
            <FormattedMessage id={sdt.myConsents} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlined />
          </div>
        </Link>

        <Link
          to={getRoute(
            companyUserRole
              ? AppRoute.EmployerLanguageSettings
              : AppRoute.LanguageSettings
          )}
          className={styles.item}
        >
          <div className={styles.iconWrap}>
            <Language />
          </div>
          <div className={styles.text}>
            <FormattedMessage id={sdt.languageSettings} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlined />
          </div>
        </Link>
        {/* <Link to={getRoute(AppRoute.EmployerBlacklist)} className={styles.item}>
          <div className={styles.iconWrap}>
            <DoNotDisturbOnIcon />
          </div>
          <div className={styles.text}>
            <FormattedMessage id={sdt.employerBlacklist} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlinedIcon />
          </div>
        </Link> */}
        <Link className={styles.item} to={getRoute(AppRoute.Logout)}>
          <div className={styles.iconWrap}>
            <Logout />
          </div>
          <div className={styles.text}>
            <FormattedMessage id={sdt.logout} />
          </div>
          <div className={styles.navigateIcon}>
            <NavigateNextOutlined />
          </div>
        </Link>
      </div>
    </div>
  )
}
