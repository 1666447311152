import {ErrorType} from './BaseType'

export enum SeekerConsentId {
  TermsAndConditionsRjb = 'f33db603-8463-4052-8673-b21e8e9fb915',
  PrivacyPolicy = 'abe7181c-deca-4cb7-8962-3580b50e01b3',
  PsychometricTest = '07455046-0b38-438e-9165-67bf799b0fc1',
  CognitiveTest = '5a5a17f1-5d44-4085-8048-c7e35bc967c2',
  ThirdPartyMarketing = '7fad7ea2-44e1-4635-887b-1100c77b673f',
  GeneralTermsOfUse = '1efd6f34-10ec-47c1-b9f4-65ccbdf8fe0c',
  Newsletter = 'bda2aa8f-7b9b-4920-84dd-3229cb4ed6ad',
  BasicConsents = '0e61a579-f721-4fd3-b90d-97a9d3ce838f',
  PrivacyPolicyRjbAnex = '99d08969-7545-4d54-a273-32364a44fb41',
  //TODO Remove
  CompanyGeneralTermsOfUse = '6161395b-5c20-42c8-8ce6-ae301070aa29',
  PrivacyPolicyCompany = '959e7f12-be1b-4ed6-9108-e6e03ff2d89c',
  DataProtectionAgreement = 'a017d3c5-01f9-4fb2-8b6a-3d16d3f4e4ce',
}

export enum EmployerConsentId {
  GeneralTermsOfUse = '6161395b-5c20-42c8-8ce6-ae301070aa29',
  PrivacyPolicy = '959e7f12-be1b-4ed6-9108-e6e03ff2d89c',
  DataProtectionAgreement = 'a017d3c5-01f9-4fb2-8b6a-3d16d3f4e4ce',
}

export interface ChangeConsentStatusType {
  consentId: SeekerConsentId
  consentStatus: 'granted' | 'revoked'
  error?: ErrorType
  companyId?: string | null
}

export interface ChangeCognitiveConsentStatusType {
  consentId: SeekerConsentId
  consentStatus: 'granted' | 'revoked'
  testId: string
}

export interface ChangeSelbyMillsConsentStatusType {
  firstName: string
  lastName: string
  email: string
  initials: string
  title: string
  id: string
  error?: ErrorType
  testId: string
  consentId: string
}
export interface ConsentsArray {
  consents: ConsentType[]
}

export interface ConsentsStateType {
  data: ConsentsArray
  loading: boolean
  error?: ErrorType
  newsletterSubscription: UnsubscribeResponse | null
}

export interface ConsentCompanyType {
  coverImage: string | null
  id: string
  logotype: string | null
  name: string
}

interface ChangeLogType {
  id: string
  dateCreated: string
}

export interface UserConsentsVersionsType {
  id: string
  status: string
  dateCreated: string
  dateUpdated: string
  company: ConsentCompanyType
  companyContactPersonEmail: string
  validTill?: number
  contact?: string
  changeLog: ChangeLogType
}

export interface ConsentVersionsType {
  id: SeekerConsentId
  name: string
  versionNumber: string
  active: boolean
  userConsentsVersions: UserConsentsVersionsType[]
  companyConsentsVersions: UserConsentsVersionsType[]
}

export interface ConsentCategoryType {
  id: string
  name: string
}

export interface ConsentType {
  id: SeekerConsentId | EmployerConsentId
  name: string
  consentVersions: ConsentVersionsType[]
  consentCategory: ConsentCategoryType
}
export interface BasicConsentItemProps {
  type: string
  consent: UserConsentsVersionsType
  className: string
  theme?: string
  changeConsentStatus: (value: ChangeConsentStatusType) => void
  subcategory?: string | null | undefined
  switchConsent?: string | null
  consentName: string
  consentId: SeekerConsentId
  consentCategory: string
}

export interface ConsentItemContainerProps {
  type: string
  className: string
  consentsArray: ConsentType[]
  consentsWithSubcategory?: ConsentType[]
}

export interface ConsentToSend {
  id: SeekerConsentId | EmployerConsentId
  status: 'granted' | 'revoked'
}

export interface NewsletterParams {
  contactId: string
  consentId: string
  hash: string
}

export interface UnsubscribeNewsletterPayload {
  params: NewsletterParams
}

type ConsentGroup = {
  id: string
  code: string
  name: string
}

type Consent = {
  id: string
  code: string
  name: string
  description: string
  dateCreated: string
  dateUpdated: string
  revocable: boolean
  consentGroup: ConsentGroup
}

export type UnsubscribeResponse = {
  consent: Consent
  isSeeker: boolean
  isAllowed: boolean
}
