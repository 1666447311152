import {generateRedirectService} from 'Services/generateRedirectService'
import {useEffect} from 'react'

export const Registration = (): JSX.Element => {
  useEffect(() => {
    generateRedirectService({userType: 'seeker'})
  }, [])

  return <></>
}
